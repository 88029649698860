import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Typography from "../../../components/common/text/Typography";
import { default as Button } from "../../../components/common/button/Button";
import { getPaymentInfoBySessionId, getProductInfo } from "../api/controller";
import { tshirtFileId, discoverseFileId } from "../../../constants";

const CheckoutCancel = () => {
    const { roomName, productId } = useParams();
    const token = useSelector((state) => state?.user?.token);
    const displayName = useSelector((state) => state?.user?.user);
    const [productImage, setProductImage] = useState(null);
    const [checkoutUrl, setCheckoutUrl] = useState(null);
    const [stripeUrl, setStripeUrl] = useState(null);
    const [productName, setProductName] = useState("");
    const [hidePaymentButton, setHidePaymentButton] = useState(false);
    const checkoutSessionId = localStorage.getItem("checkoutSessionId");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (checkoutSessionId) {
                    const response = await getPaymentInfoBySessionId(token, checkoutSessionId, roomName, productId);
                    if (
                        response.status === 200 &&
                        response.data.status === "open" &&
                        response.data.payment_status === "unpaid"
                    ) {
                        setCheckoutUrl(response.data.url);
                        setProductImage(response.data.productImageUrl);
                        setProductName(response.data.productName);
                    } else if (
                        response.status === 200 &&
                        response.data.status === "complete" &&
                        response.data.payment_status === "paid"
                    ) {
                        setHidePaymentButton(true);
                        setStripeUrl(response.data.hostedInvoiceUrl);
                        setProductImage(response.data.productImageUrl);
                        setProductName(response.data.productName);
                    }
                }
            } catch (error) {
                console.error("Error fetching checkout session:", error);
            }
        };

        fetchData();
    }, []);

    const handleButtonClick = () => {
        window.location.href = checkoutUrl;
    };
    const handleDownloadClick = () => {
        window.location.href = stripeUrl;
    };

    return (
        <>
            <InputContainer>
                {!hidePaymentButton && (
                    <>
                        <Typography variant="PinkHeading">Payment Cancelled</Typography>
                        <Typography variant="pWhite" fontSize="16px" margin="2rem 0 0">
                            The order for {productName} has been successfully cancelled. Thank you for your attention{" "}
                            {displayName}.
                        </Typography>

                        <ProductImage src={productImage} alt="Product Image" />

                        <Typography variant="pWhite" fontSize="16px" margin="1.5rem 0 0 0rem">
                            If you wish to proceed with the payment, you can click the "Pay Now" button.
                        </Typography>
                        <Button
                            variant={"GuestPayNowButton"}
                            buttonWidth={"60%"}
                            backgroundColor={"rgba(255, 255, 255, 0.15)"}
                            margin={"2rem 0 0 0"}
                            label={"Pay Now"}
                            onClick={handleButtonClick}
                        />
                    </>
                )}

                {hidePaymentButton && (
                    <>
                        <Typography variant="PinkHeading">Payment Received</Typography>
                        <Typography variant="pWhite" fontSize="16px" margin="2rem 0 0">
                            The order for {productName} has been successfully purchased. Thank you for your purchase{" "}
                            {displayName}.
                        </Typography>

                        <ProductImage src={productImage} alt="Product Image" />

                        <Typography variant="pWhite" fontSize="16px" margin="1.5rem 0 0 0rem">
                            As per our record, the payment for the order has been successfully completed.
                        </Typography>
                        <Button
                            variant={"GuestPayNowButton"}
                            buttonWidth={"60%"}
                            backgroundColor={"rgba(255, 255, 255, 0.15)"}
                            margin={"2rem 0 0 0"}
                            label={"Download Invoice"}
                            onClick={handleDownloadClick}
                        />
                    </>
                )}
            </InputContainer>
        </>
    );
};

export default CheckoutCancel;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 50%;
    margin: 50px auto;
    @media (max-width: 768px) {
        width: 90%;
        height: auto;
        margin: 1rem auto;
    }
`;

const ProductImage = styled.img`
    width: 60%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 10px;
    margin: 2rem 0rem;
`;
