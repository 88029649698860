import React from "react";
import styled from "styled-components";
import MobileFrameFingers from "../../assets/UI_Website_Graphic_Phone.png";
import { setColor } from "../../../../assets/styles/styles";
import Typography from "../../../../components/common/text/Typography";
import LeftGraphics from "../assets/graphics/UI_Website_Graphic_Additions_Left.png";
import RighGraphics from "../assets/graphics/UI_Website_Graphic_Additions_Right.png";

const VideoSection = () => (
    <SectionBackground>
        <Typography variant="LandingPageTitle">
            Discover <Highlighted>Immersive</Highlighted> Experiences
        </Typography>
        <FingersFrame>
            <MobileFrameFingersContainer>
                <VideoWithOverlay>
                    <ImageOverlay />
                    <VideoContainer>
                        <video muted autoPlay loop>
                            <source type="video/mp4" src={"/videos/landingPages/Live_Mobile_Screen_medium.mp4"} />
                        </video>
                    </VideoContainer>
                    <ImageLeft src={LeftGraphics} />
                    <ImageRight src={RighGraphics} />
                </VideoWithOverlay>
            </MobileFrameFingersContainer>
        </FingersFrame>
    </SectionBackground>
);

export default VideoSection;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    background: ${setColor.darkPurpleBackground};
`;

const FingersFrame = styled.div`
    width: 100%;
    justify-content: center;
`;

const MobileFrameFingersContainer = styled.div`
    position: relative;
    justify-content: center;
    display: flex;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 89%;
    margin-top: 10px;
    margin-left: 23px;

    @media (max-width: 950px) {
        margin-left: 0rem;
    }

    @media (min-width: 950px) and (max-width: 1024px) {
        margin-left: 0rem;
    }
`;

const VideoWithOverlay = styled.div`
    position: relative;
    height: 443px;
    width: 1080px;
    justify-content: center;
    display: flex;

    @media (max-width: 768px) {
        height: 443px;
        width: 700px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        height: 443px;
        width: 800px;
    }
    @media (min-width: 1024px) and (max-width: 1250px) {
        height: 443px;
        width: 1024px;
    }
`;

const ImageOverlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(${MobileFrameFingers});
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 10;

    @media (max-width: 950px) {
        display: none;
    }

    @media (min-width: 950px) and (max-width: 1024px) {
        width: 100vw;
        margin-left: -4rem;
    }

    @media (min-width: 1024px) and (max-width: 1110px) {
        width: 100vw;
        margin-left: -2rem;
    }
    @media (min-width: 1100px) and (max-width: 1216px) {
        width: 100vw;
        margin-left: 4rem;
    }
    @media (min-width: 1216px) and (max-width: 1335px) {
        margin-left: -5rem;
    }
`;

const ImageLeft = styled.img`
    width: auto;
    height: 275px;
    bottom: 125px;
    position: absolute;
    z-index: 1;
    left: -185px;

    @media (max-width: 960px) {
        display: none;
    }
`;

const ImageRight = styled.img`
    width: auto;
    height: 275px;
    position: absolute;
    z-index: 1;
    right: -185px;
    top: -125px;

    @media (max-width: 960px) {
        display: none;
    }
`;
