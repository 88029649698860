import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { setColor } from "../../../../../../assets/styles/styles";
import { default as Button } from "../../../../../../components/common/button/Button";
import Typography from "../../../../../../components/common/text/Typography";
import Banner from "../../../../../../components/layout/ui/Banner";
import { addQuestionToSurvey } from "../../api/surveysController";
import { ReactComponent as DropdownIcon } from "../../../../../../assets/images/icons/Chevron.svg";
import AddSurveyQuestion from "./AddSurveyQuestion";
import AddSurveyOptions from "./AddSurveyOptions";

const AddQuestion = ({ surveyId, closeAddModal, name }) => {
    const token = useSelector((state) => state?.user?.token);
    const [successMessageBanner, setSuccessMessageBanner] = useState("");
    const [showQuestion, setShowQuestion] = useState(false);
    const [showOption, setShowOption] = useState(false);
    const [finalQuestionsSurveysData, setFinalQuestionsSurveysData] = useState([]);
    const [finalOptionsSurveysData, setFinalOptionsSurveysData] = useState([]);
    const [isAllFilled, setIsAllFilled] = useState(false);
    const [validationError, setValidationError] = useState("");

    const AddQuestionToSurveyClick = async () => {
        if (finalOptionsSurveysData.length !== 0 && finalQuestionsSurveysData.length !== 0) {
            setIsAllFilled(true);
            setValidationError("");
            try {
                const response = await addQuestionToSurvey(
                    token,
                    surveyId,
                    finalQuestionsSurveysData[0],
                    finalOptionsSurveysData
                );

                if (response.status === 200) {
                    setSuccessMessageBanner(
                        `${response.message}. Redirecting to the survey question page in 3 seconds...`
                    );
                }
            } catch (error) {
                console.error("Error adding survey:", error);
            }
        } else {
            setValidationError("Please fill all the fields");
        }
    };

    useEffect(() => {
        if (finalOptionsSurveysData.length !== 0 && finalQuestionsSurveysData.length !== 0) {
            setIsAllFilled(true);
        }
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeAddModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner, finalOptionsSurveysData, finalQuestionsSurveysData]);

    const addOptionClick = () => {
        setShowOption(true);
    };
    const handleFinalQuestionsData = (data) => {
        setFinalQuestionsSurveysData(data);
    };

    const handleFinalOptionsData = (data) => {
        setFinalOptionsSurveysData(data);
    };

    return (
        <>
            <Banner
                width={"100%"}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={"SUCCESS"}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={"0px"}
                left={"0"}
            />
            <GeneralContainer>
                <Typography variant="h2PurpleHeading" fontSize={"24px"} margin={`1rem 0 0 0`}>
                    Add Question to {name}
                </Typography>

                <Card>
                    <Table>
                        <AddSurveyQuestion
                            getQuestionsData={handleFinalQuestionsData}
                            addOptionClick={addOptionClick}
                        />

                        {showOption && <AddSurveyOptions getOptionsData={handleFinalOptionsData} />}
                        <TableRow>
                            <Button
                                variant={"PinkButton"}
                                buttonWidth={"100%"}
                                disabled={!isAllFilled || validationError !== "" || successMessageBanner !== ""}
                                label={"Add QUESTION & OPTION"}
                                onClick={AddQuestionToSurveyClick}
                            />
                        </TableRow>
                    </Table>
                </Card>
            </GeneralContainer>
        </>
    );
};

export default AddQuestion;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? "1rem" : "0rem")};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: "Barlow-Regular", Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const DropdownButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? "1rem" : "0rem")};
    position: relative;
    text-align: left;
    border: ${(props) => (props.isopen === "true" ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`)};
    &:disabled {
        background-color: ${setColor.grey1};
    }

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === "true" ? "rotate(180deg)" : "rotate(0deg)")};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : "transparent")};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;
