import UAParser from "ua-parser-js";
import axios from "axios";
import { DeleteUser } from "../redux/actions/UserActions";
import { DeleteMerchant } from "../redux/actions/MerchantActions";
import { COREAPI_WEB_USERS_URL } from "../constants";
import { API_POST, API_GET, API_PUT, API_DELETE } from "../config/axiosConfig";
import { generateRandomName } from "../utils/generalUtils";

const COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}/web`;

export const getTablesSchema = async () => {
    try {
        const response = await API_GET(`${COREAPIURL}/admin/tables_schema`);
        if (response.status === 200) {
            return response.data;
        }
        return [];
    } catch (err) {
        console.error("Error in request:", err);
        return err;
    }
};

export const getTableInfo = async (token, tableName) => {
    try {
        const url = `${COREAPIURL}/admin/table/${tableName}`;
        const response = await API_GET(url);
        if (response.status === 200) {
            return response.data;
        }
        return [];
    } catch (err) {
        console.error("Error in request:", err);
        return err;
    }
};

export const getRecordInfo = async (token, tableName, id, primaryKey) => {
    try {
        const response = await API_GET(
            `${COREAPIURL}/admin/record/table/${tableName}/primary_key/${primaryKey}/id/${id}`
        );
        return response;
    } catch (err) {
        console.error("Error in request:", err);
        return err;
    }
};

export const updateRecordDynamically = async (token, tableName, id, primaryKey, data) => {
    try {
        const response = await API_PUT(
            `${COREAPIURL}/admin/record/table/${tableName}/primary_key/${primaryKey}/id/${id}`,
            data
        );
        return response;
    } catch (err) {
        console.error("Error in request:", err);
        return err.response;
    }
};

export const getRoomNPCs = async (token, roomId) => {
    try {
        const response = await API_GET(`${COREAPIURL}/npc/${roomId}`);
        return response;
    } catch (err) {
        console.error("Error in request:", err);
        return err.response;
    }
};

export const updateNPC = async (token, npcData) => {
    try {
        const data = {
            name: npcData.name,
            dialogue: {
                text: npcData.dialogue.text,
            },
            key: npcData.key,
        };
        // USED fetch on this endpoint cause axios -> parses the json array incorrectlly.
        const response = await API_PUT(`${COREAPIURL}/npc/${npcData.npcId}`, data);
        const json = await response.json();
        return { data: json, status: response.status };
    } catch (err) {
        console.error("Error in request:", err);
        return err.response;
    }
};

export const createNPC = async (token, roomId) => {
    try {
        const data = {
            name: generateRandomName(),
            dialogue: {
                text: ["Test dialgue"],
            },
            key: generateRandomName(),
            roomId,
        };
        const response = await API_POST(`${COREAPIURL}/npc`, data);
        const json = await response.json();
        return { data: json, status: response.status };
    } catch (err) {
        console.error("Error in request:", err);
        return err.response;
    }
};

export const deleteNPC = async (token, npcId) => {
    try {
        const response = await API_DELETE(`${COREAPIURL}/npc/${npcId}`);
        return response;
    } catch (err) {
        console.error("Error in request:", err);
        return err.response;
    }
};
