import styled from "styled-components";
import { stripeCountries } from "../../../../../constants";
import Typography from "../../../../../components/common/text/Typography";
import { setColor } from "../../../../../assets/styles/styles";
import { Input } from "../../../../../components/common/input/Input";

const BillingDetailsFields = () => (
    <BillingDetailsContainer>
        <Typography variant="h2PurpleHeading" fontSize={"30px"}>
            Billing Address
        </Typography>
        <Input
            borderColor="rgb(80, 76, 92)"
            editIconHoverColor="none"
            hasLabel
            name="name"
            label="Name"
            placeholder="Jane Doe"
            readonly
            variant="LoginInput"
            required
        />

        <Input
            borderColor="rgb(80, 76, 92)"
            editIconHoverColor="none"
            hasLabel
            name="email"
            type="email"
            label="Email"
            placeholder="jane.doe@example.com"
            readonly
            variant="LoginInput"
            required
        />
        <Input
            borderColor="rgb(80, 76, 92)"
            editIconHoverColor="none"
            hasLabel
            name="phone"
            type="phone"
            label="Phone"
            placeholder="+14444444444"
            readonly
            variant="LoginInput"
            required
        />

        <Input
            borderColor="rgb(80, 76, 92)"
            editIconHoverColor="none"
            hasLabel
            name="address"
            type="address"
            label="Address"
            placeholder="185 Berry St. Suite 550"
            readonly
            variant="LoginInput"
            required
        />

        <Input
            borderColor="rgb(80, 76, 92)"
            editIconHoverColor="none"
            hasLabel
            name="city"
            type="text"
            label="City"
            placeholder="San Francisco"
            readonly
            variant="LoginInput"
            required
        />

        <Input
            borderColor="rgb(80, 76, 92)"
            editIconHoverColor="none"
            hasLabel
            name="state"
            type="text"
            label="State"
            placeholder="California"
            readonly
            variant="LoginInput"
            required
        />

        <Label>Country</Label>
        <Select name="country" label="Country" type="text" placeholder="USA" required>
            {stripeCountries.map((country) => (
                <option key={`${country.code}-${country.country}`} value={country.code}>
                    {country.country}
                </option>
            ))}
        </Select>

        <Input
            borderColor="rgb(80, 76, 92)"
            editIconHoverColor="none"
            hasLabel
            name="zip"
            type="text"
            label="Zip Code"
            placeholder="94103"
            readonly
            variant="LoginInput"
            required
        />
    </BillingDetailsContainer>
);

const BillingDetailsContainer = styled.div`
    max-width: 100vw;
    padding-bottom: 25px;
`;

const Select = styled.select`
    flex: 1;
    background: #1b1b23;
    border: 2px solid #504c5c;
    border-radius: 5px;
    outline: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0rem;
    font-size: 18px;
    font-style: italic;
    font-family: "Barlow", sans-serif;
    color: ${setColor.iconColor};
    width: 100%;
    height: 41px;

    ::placeholder {
        color: ${setColor.iconColor};
        opacity: 50%;
        @media (max-width: 1440px) {
            font-size: 14px;
        }
    }
`;

const Label = styled.label`
    flex: 1;
    display: block;
    background: #1b1b23;
    outline: none;
    padding-left: 0.5rem;
    margin: 0.5rem 0rem;
    font-size: 18px;
    font-family: "Barlow", sans-serif;
    color: ${setColor.iconColor};
    width: 90%;
    background-color: transparent;
`;

export default BillingDetailsFields;
