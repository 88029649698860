import React from "react";
import styled from "styled-components";
import { setColor } from "../../../../assets/styles/styles";
import Typography from "../../../../components/common/text/Typography";
import Background from "../assets/backgrounds/UI_Website_BG_edm_exterior.png";
import RicWilsonCard from "../assets/cardImages/RicWilson.png";
import DanCard from "../assets/cardImages/BigDigitalAssets.png";
import TeddySwimCard from "../assets/cardImages/TeddySwimCard.png";
import { device } from "../../../../assets/styles/mediaBreakpoints";

const NewsSection = () => (
    <SectionBackground>
        <SectionContainer>
            <div>
                <Typography variant="LandingPageTitle" style={{ marginBottom: "3rem", fontSize: "3.2rem" }}>
                    More About The <Highlighted>Intraverse</Highlighted>
                </Typography>
            </div>
            <GalleryDisplay>
                <a
                    href="https://venturebeat.com/gaming-business/napster-and-terrazero-will-create-metaverse-for-artists/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <CardContainer>
                        <TextOverlay>
                            <Typography
                                variant="pWhite"
                                align="left"
                                style={{ margin: "0", fontSize: "10px", width: "100%" }}
                            >
                                5 months ago
                            </Typography>
                            <Typography
                                variant="pWhite"
                                color="white"
                                align="left"
                                style={{ margin: "0", fontWeight: "bold", width: "100%" }}
                            >
                                Ric Wilson's Discoverse
                            </Typography>
                            <Typography
                                variant="pWhite"
                                align="left"
                                style={{ margin: "0", fontSize: "14px", width: "100%" }}
                            >
                                Napster and TerraZero will create metaverse for artists.
                            </Typography>
                        </TextOverlay>
                        <CardImage src={RicWilsonCard} alt="Ric Wilson" />
                    </CardContainer>
                </a>
                <a
                    href="https://finance.yahoo.com/news/bigg-digital-assets-subsidiary-terrazero-123000981.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <CardContainer>
                        <TextOverlay>
                            <Typography variant="pWhite" align="left" style={{ margin: "0", fontSize: "10px" }}>
                                4 months ago
                            </Typography>
                            <Typography
                                variant="pWhite"
                                color="white"
                                align="left"
                                style={{
                                    margin: "0",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                }}
                            >
                                TerraZero Deploys Stripe Integration for Intraverse Ecommerce
                            </Typography>
                            <Typography
                                variant="pWhite"
                                align="left"
                                style={{ margin: "0", fontSize: "14px", width: "100%" }}
                            >
                                BIGG Digital Assets Subsidiary TerraZero Deploys Stripe Integration for Intraverse
                                Ecommerce to Generate Revenue
                            </Typography>
                        </TextOverlay>
                        <CardImage src={DanCard} alt="Dan Reitzik" />
                    </CardContainer>
                </a>
                <a
                    href="https://www.linkedin.com/posts/terrazerotech_intraverse-teddyswims-musicindustry-activity-7110688254722506752-spjD/?utm_source=share&utm_medium=member_desktop"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <CardContainer>
                        <TextOverlay>
                            <Typography variant="pWhite" align="left" style={{ margin: "0", fontSize: "10px" }}>
                                10 months ago
                            </Typography>
                            <Typography
                                variant="pWhite"
                                color="white"
                                align="left"
                                style={{
                                    margin: "0",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                }}
                            >
                                Teddy’s Lounge Releases!
                            </Typography>
                            <Typography
                                variant="pWhite"
                                align="left"
                                style={{ margin: "0", fontSize: "14px", width: "100%" }}
                            >
                                Dive into this experience to engage with Teddy Swims and his awesome band.
                            </Typography>
                        </TextOverlay>
                        <CardImage src={TeddySwimCard} alt="Teddy Swim" />
                    </CardContainer>
                </a>
            </GalleryDisplay>
        </SectionContainer>
    </SectionBackground>
);

export default NewsSection;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background:
        linear-gradient(rgba(27, 27, 35, 0.95), rgba(27, 27, 35, 0.95)),
        linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, ${setColor.darkPurpleBackground} 100%),
        url(${Background}) no-repeat center/cover;

    @media (max-width: 960px) {
        height: auto;
    }
`;

const SectionContainer = styled.div`
    max-width: 1300px;
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media ${device.desktopWide} {
        padding: 75px 0;
        gap: 50px;
    }

    @media (max-width: 960px) {
        width: 90%;
    }
`;

const GalleryDisplay = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    @media (max-width: 960px) {
        flex-wrap: wrap;
        gap: 50px;
        justify-content: center;
    }
`;

const CardContainer = styled.div`
    cursor: pointer;
    width: 350px;
    height: 350px;
    border-radius: 10px;
    border: 2px solid rgba(190, 174, 255, 0.3);
    position: relative;

    @media ${device.desktopWide} {
        width: 400px;
        height: 400px;
    }

    @media (max-width: 960px) {
        width: 100%;
        height: auto;
    }
`;

const CardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
    border-radius: inherit;
`;

const TextOverlay = styled.div`
    height: 40%;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(15px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
`;
