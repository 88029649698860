import React from "react";
import styled from "styled-components";

const MusicPlayerContainer = styled.div`
    width: 100%;
    font-family: Arial, sans-serif;
`;

const ProgressBarContainer = styled.div`
    position: relative;
    width: 100%;
    height: 6px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
`;

const ProgressBar = styled.div`
    width: ${({ progress }) => progress}%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    transition: width 0.3s ease;
`;

const MusicPlayerProgressBar = ({ duration, currentTime }) => {
    const progress = (currentTime / duration) * 100;

    return (
        <MusicPlayerContainer>
            <ProgressBarContainer>
                <ProgressBar progress={progress} />
            </ProgressBarContainer>
        </MusicPlayerContainer>
    );
};

export default MusicPlayerProgressBar;
