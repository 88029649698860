import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Typography from "../components/common/text/Typography";
import Button from "../components/common/button/Button";

import BackgroundContainer from "../components/common/Backgrounds/BackgroundContainer";

export default function Page404() {
    const navigate = useNavigate();
    return (
        <BackgroundContainer
            height={"100%"}
            width={"100%"}
            backgroundColor="rgba(32, 23, 41, 1)"
            variant="DiamondBackground"
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <ErrorContainer>
                <Typography variant="h1" color={"white"} style={{ textAlign: "center" }}>
                    404, page not found!
                </Typography>
                <Typography variant="pWhite">
                    Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to
                    check your spelling.
                </Typography>
                <Button variant="PinkButton" onClick={() => navigate("/")} label={"Go to Home"} />
            </ErrorContainer>
        </BackgroundContainer>
    );
}

const ErrorContainer = styled.div`
    display: flex;
    width: 50%;
    height: 50%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
