export const startInterval = (reference) => {
    if (!reference.current) {
        reference.current = setInterval(() => {
            window.Vagon?.focusIframe();
        }, 1000);
    }
};

export const stopInterval = (reference) => {
    if (reference.current) {
        clearInterval(reference.current);
        reference.current = null;
    }
};

const handleButtonClick = () => {
    const escapeKeyEvent = new KeyboardEvent("keydown", {
        key: "Escape",
    });

    document.dispatchEvent(escapeKeyEvent);
};

export const alterState = (setState) => {
    handleButtonClick();
    setState((prev) => !prev);
    handleButtonClick();
};

export const sendVagonMessage = (message) => {
    window.Vagon.sendApplicationMessage(message);
};
