import { Director, Publish } from "@millicast/sdk";

export const dolbyTokenGenerator = async (broadcastId) => {
    try {
        const tokenGenerator = () =>
            Director.getPublisher({
                token: process.env.REACT_APP_DOLBY_TOKEN,
                streamName: broadcastId,
            });
        const publisher = await new Publish(broadcastId, tokenGenerator);
        return publisher;
    } catch (err) {
        console.error("error", err);
        return null;
    }
};
