import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { setFont, setColor } from "../../../../assets/styles/styles";
import Title from "../../components/Title";
import Body from "../../layout/Body";
import { getAllPermissionsByRoleId } from "./api/rolesPermissionsController";
import { ReactComponent as ChevronIcon } from "../../../../assets/images/icons/Chevron.svg";
import Typography from "../../../../components/common/text/Typography";
import RolesPermissionsTable from "./components/RolesPermissionsTable";
import { getPermissionsAndCheckAccess } from "../../../../api/RolesController";
import { CAN_MANAGE_PERMISSIONS, CAN_MANAGE_ROLES, DEFAULT_QUERY_OPTIONS } from "../../../../constants";
import { default as Button } from "../../../../components/common/button/Button";
import AddModal from "./components/AddModal";
import Modal from "../../components/Modal";

const PermissionsPage = () => {
    const navigate = useNavigate();
    const { roleId } = useParams();
    const user = useSelector((state) => state.user);
    const [openModal, setOpenModal] = useState(false);
    const [updatedDateTime, setUpdatedDateTime] = useState("");
    const [createdDateTime, setCreatedDateTime] = useState("");
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [roleName, setRoleName] = useState("");

    const {
        data: permissionsData,
        error,
        isLoading,
    } = useQuery(["permissions", roleId], () => getAllPermissionsByRoleId(roleId), {
        enabled: !!roleId,
        ...DEFAULT_QUERY_OPTIONS(),
    });

    const {
        data: canManageRoles,
        error: rolesError,
        isLoading: rolesLoading,
    } = useQuery(["rolePermissions", user.role], () => getPermissionsAndCheckAccess(user.role, CAN_MANAGE_ROLES), {
        enabled: !!user.role,
        ...DEFAULT_QUERY_OPTIONS(),
    });

    const {
        data: canManagePermissions,
        error: permissionsError,
        isLoading: permissionsLoading,
    } = useQuery(
        ["rolePermissions", user.role],
        () => getPermissionsAndCheckAccess(user.role, CAN_MANAGE_PERMISSIONS),
        {
            enabled: !!user.role,
            ...DEFAULT_QUERY_OPTIONS(),
        }
    );

    useEffect(() => {
        if (permissionsData && permissionsData.length > 0) {
            setRoleName(permissionsData[0].roleName);
        }
    }, [permissionsData]);

    const handleAddClick = () => {
        setIsModalAddOpen(true);
    };

    const handleBack = () => {
        navigate(`/console/roles`);
    };

    return (
        <>
            <CardTable>
                <TableRow>
                    {roleName !== "" && <Title title={`${roleName}'s PERMISSIONS`} description={``} />}

                    {canManageRoles && canManagePermissions && (
                        <TableColumn>
                            <Button
                                variant={"OutlinedButton"}
                                label={"Add Permission"}
                                margin={"2rem 0 0 0"}
                                onClick={() => handleAddClick()}
                            />
                        </TableColumn>
                    )}
                </TableRow>
            </CardTable>

            <UserConsoleText onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>

            <Body isLoading={isLoading}>
                <>
                    {isModalAddOpen && canManageRoles && canManagePermissions && (
                        <Modal
                            isOpen={isModalAddOpen}
                            setIsOpen={setIsModalAddOpen}
                            children={
                                <AddModal
                                    requestType="permissions"
                                    roleId={roleId}
                                    closeAddModal={() => setIsModalAddOpen(false)}
                                />
                            }
                        />
                    )}
                    {permissionsData && (
                        <>
                            <RolesPermissionsTable
                                data={permissionsData}
                                requestType={"permissions"}
                                totalNumber={permissionsData.length}
                            />
                        </>
                    )}
                </>
            </Body>
        </>
    );
};

export default PermissionsPage;

const CardTable = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const UserConsoleText = styled.h1`
    font-family: ${setFont.barlowCondensed};
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;
const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;
