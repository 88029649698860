import UAParser from "ua-parser-js";
import { API_GET, API_POST } from "../config/axiosConfig";
import { sendEventsLog } from "./UserController";
import { LOG_EVENTS_DEVICE_DATA, LOG_EVENTS_USER_LOGIN_DATA } from "../constants";
import { getDeviceInfo } from "../utils/generalUtils";

export async function createVanityLink(data) {
    const url = `${process.env.REACT_APP_CORE_API_URI}/web/vanity_links`;
    const respAxios = await API_POST(url, data);
    return respAxios.data;
}

export async function createVanityLinkData(data) {
    const url = `${process.env.REACT_APP_CORE_API_URI}/web/vanity_link_data`;
    const respAxios = await API_POST(url, data);
    return respAxios;
}

export async function fetchVanityLinkData(pathId) {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/vanity_link_data/${pathId}`);

        if (response) {
            return response;
        }
    } catch (error) {
        console.error("Error grabbing vanity link:", error);
        return null;
    }
}

export async function fetchVanityLinkByRoomId(roomId) {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/vanity_links/room/${roomId}`);

        if (response.status === 200 && response) {
            return response;
        }

        return null;
    } catch (error) {
        console.error("Error grabbing vanity link:", error);
        return null;
    }
}

export async function fetchVanityLinkAvatarPresets(vanityLink) {
    try {
        const response = await API_GET(
            `${process.env.REACT_APP_CORE_API_URI}/web/vanity_links/avatar_presets/${vanityLink}`
        );

        return response;
    } catch (error) {
        console.error("Error grabbing Avatar Presets:", error);
        return null;
    }
}

export async function sendAutoLoginLink(email, vanity) {
    try {
        const data = {
            email,
            vanity,
            url: process.env.REACT_APP_WEB_URL,
        };

        const response = await API_POST(
            `${process.env.REACT_APP_CORE_API_URI}/web/vanity_links/autologin`,
            data,
            "",
            "POST"
        );

        if (response.status === 200 && response) {
            return response;
        }

        return null;
    } catch (error) {
        console.error("Error sending auto login:", error);
        return null;
    }
}

export async function authenticateAutoLoginLink(userId, token, vanity) {
    try {
        const data = {
            userId,
            vanity,
        };

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await API_POST(
            `${process.env.REACT_APP_CORE_API_URI}/web/vanity_links/autologin/authenticate`,
            data,
            config
        );
        if (response.status === 200 && response) {
            const deviceInfo = await getDeviceInfo();
            const eventJson = {
                guestLogin: true,
                user: response.user,
                clientName: vanity,
            };
            await sendEventsLog(response.token, vanity, userId, LOG_EVENTS_USER_LOGIN_DATA, "login", eventJson, "", "");
            await sendEventsLog(response.token, vanity, userId, LOG_EVENTS_DEVICE_DATA, "info", deviceInfo, "", "");

            return response;
        }

        return null;
    } catch (error) {
        console.error("Error authenticating auto login:", error);
        return null;
    }
}
