import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import DataTable from "../../../../../components/common/dataTable/DataTable";
import Title from "../../../components/Title";
import Body from "../../../layout/Body";
import { ReactComponent as EditIcon } from "../../../../../assets/images/icons/editIcon.svg";
import { setColor, setFont } from "../../../../../assets/styles/styles";
import Button from "../../../../../components/common/button/Button";
import Modal from "../../../components/Modal";
import { getFormInfoById } from "../api/surveysController";
import Typography from "../../../../../components/common/text/Typography";
import { ReactComponent as ChevronIcon } from "../../../../../assets/images/icons/Chevron.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/icons/deleteIcon.svg";
import { ReactComponent as CheckmarkIcon } from "../../../../../assets/images/icons/Checkmark.svg";
import { ReactComponent as Cross } from "../../../../../assets/images/icons/Cross.svg";
import { ReactComponent as NullIcon } from "../../../../../assets/images/icons/System.svg";
import DeleteModal from "./DeleteModal";
import EditModal from "./Update/EditModal";
import AddModal from "./Add/AddModal";
import { DEFAULT_QUERY_OPTIONS } from "../../../../../constants";

const SurveyOptionsTable = () => {
    const navigate = useNavigate();
    const { surveyId, questionId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [optionsData, setOptionsData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionName, setQuestionName] = useState("");
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleRows, setVisibleRows] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalNumber, setTotalNumber] = useState(0);
    const ROWS_PER_PAGE = 5;

    const {
        data: formsInfo,
        error: formsErr,
        isLoading: formsLoading,
    } = useQuery(["forms", surveyId], () => getFormInfoById(surveyId), {
        enabled: !!surveyId,
        ...DEFAULT_QUERY_OPTIONS(),
    });

    useEffect(() => {
        if (formsInfo && formsInfo.questions) {
            const optionData = [];
            for (let i = 0; i < formsInfo.questions.length; i += 1) {
                if (formsInfo.questions[i].id === questionId) {
                    setTotalPages(Math.ceil(formsInfo.questions[i].answers.length / ROWS_PER_PAGE));
                    setVisibleRows(
                        formsInfo.questions[i].answers.slice(
                            (currentPage - 1) * ROWS_PER_PAGE,
                            currentPage * ROWS_PER_PAGE
                        )
                    );
                    setQuestionName(formsInfo.questions[i].body);
                    setTotalNumber(formsInfo.questions[i].answers.length);
                    optionData.push(...formsInfo.questions[i].answers);
                }
            }
            setOptionsData(optionData);
        }
        setIsLoading(false);
    }, [formsInfo, currentPage]);

    const handleBack = () => {
        navigate(`/console/surveys/${surveyId}/questions`);
    };

    const addOptionsClick = () => {
        setIsModalAddOpen(true);
    };

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const handleDeleteClick = (row) => {
        setSelectedRow(row);
        setIsModalDeleteOpen(true);
    };

    const handleEditClick = (row) => {
        setSelectedRow(row);
        setIsModalEditOpen(true);
    };

    const renderRow = (option) => {
        let isCorrectIcon;

        if (option.isCorrect === true) {
            isCorrectIcon = <StyledCheckmark />;
        } else if (option.isCorrect === false) {
            isCorrectIcon = <StyledCloseIcon />;
        } else {
            isCorrectIcon = <StyledNullIcon />;
        }

        const buttonDelete = <StyledDeleteIcon onClick={() => handleDeleteClick(option)} />;

        const buttonEdit = <StyledEditIcon onClick={() => handleEditClick(option)} />;

        return [
            option.id,
            option.name || "N/A",
            <>{isCorrectIcon}</>,
            option.points,
            <>{buttonEdit}</>,
            <>{buttonDelete}</>,
        ];
    };

    return (
        <>
            <CardMainTable>
                <TableRow>
                    <Title
                        title={"QUESTION OPTIONS"}
                        description={"Manage all question's options for surveys/forms in Intraverse."}
                    />

                    <TableColumn>
                        <Button
                            variant={"OutlinedButton"}
                            label={"Add Option"}
                            margin={"2rem 0 0 0"}
                            onClick={addOptionsClick}
                        />
                    </TableColumn>
                </TableRow>
            </CardMainTable>

            <UserConsoleText style={{ marginBottom: "1.5rem" }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO QUESTIONS TABLE
            </UserConsoleText>

            <Body>
                <>
                    {visibleRows && (
                        <>
                            <CardTable>
                                <TableRow>
                                    <TableColumn>
                                        <Typography variant="PinkText">{questionName}</Typography>
                                        {questionId}
                                    </TableColumn>
                                </TableRow>
                            </CardTable>

                            <DataTable
                                headers={["OPTION ID", "NAME", "IS CORRECT?", "POINTS"]}
                                data={visibleRows}
                                renderRow={renderRow}
                                openModal={openModal}
                                isModalOpen={isModalOpen}
                                selectedRow={selectedRow}
                                isHover={false}
                            />

                            {isModalDeleteOpen && (
                                <Modal
                                    isOpen={isModalDeleteOpen}
                                    setIsOpen={setIsModalDeleteOpen}
                                    children={
                                        <DeleteModal
                                            surveyId={surveyId}
                                            questionId={questionId}
                                            optionId={selectedRow.id}
                                            name={selectedRow.name}
                                            requestType="option"
                                            closeDeleteModal={() => setIsModalDeleteOpen(false)}
                                        />
                                    }
                                />
                            )}

                            {isModalEditOpen && (
                                <Modal
                                    isOpen={isModalEditOpen}
                                    setIsOpen={setIsModalEditOpen}
                                    children={
                                        <EditModal
                                            surveyId={surveyId}
                                            requestType="option"
                                            closeEditModal={() => setIsModalEditOpen(false)}
                                            data={selectedRow}
                                            questionId={questionId}
                                        />
                                    }
                                />
                            )}

                            {isModalAddOpen && (
                                <Modal
                                    isOpen={isModalAddOpen}
                                    setIsOpen={setIsModalAddOpen}
                                    children={
                                        <AddModal
                                            surveyId={surveyId}
                                            questionId={questionId}
                                            requestType="option"
                                            closeAddModal={() => setIsModalAddOpen(false)}
                                            name={questionName}
                                        />
                                    }
                                />
                            )}
                            <Footer>
                                <TableColumnLeft>Total Number of Options: {totalNumber}</TableColumnLeft>
                                <TableColumnRight>
                                    {visibleRows.length !== 0 && (
                                        <Pagination>
                                            <span>
                                                Page {currentPage} of {totalPages}
                                            </span>
                                            <PaginationButton
                                                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </PaginationButton>
                                            <PaginationButton
                                                onClick={() =>
                                                    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
                                                }
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </PaginationButton>
                                        </Pagination>
                                    )}
                                </TableColumnRight>
                            </Footer>
                        </>
                    )}
                </>
            </Body>
        </>
    );
};

export default SurveyOptionsTable;

const CardMainTable = styled.div`
    display: flex;
    flex-direction: column;
`;

const UserConsoleText = styled.h1`
    font-family: "Barlow Condensed", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;

const StyledEditIcon = styled(EditIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const StyledCheckmark = styled(CheckmarkIcon)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
    #Union {
        fill: #93ebc7;
    }
`;

const StyledCloseIcon = styled(Cross)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
    #Union {
        fill: ${setColor.spanTextColor};
    }
`;

const StyledNullIcon = styled(NullIcon)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText};
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    // font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled};
    }
`;
