import axiosInstance, { API_GET } from "../config/axiosConfig";
import * as constants from "../constants";

export async function putBannerImage(roomId, data, token) {
    try {
        const response = await axiosInstance.put(
            `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms/room_personalization_items/banner_image/${roomId}`,
            data,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json; charset=UTF-8",
                },
            }
        );
        return response;
    } catch (error) {
        return error;
    }
}
export async function getPersonalizationItems(roomId, token) {
    try {
        const response = await axiosInstance.get(
            `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms/room_personalization_items/${roomId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response;
    } catch (error) {
        return error;
    }
}
export async function putPersonalizationItem(roomPersonalizationItemId, data, token) {
    try {
        const response = await axiosInstance.put(
            `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms/room_personalization_items/${roomPersonalizationItemId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        return error;
    }
}

export const getUserRoomPermission = async (roomId, token) => {
    try {
        const response = await axiosInstance.get(
            `${process.env.REACT_APP_CORE_API_URI}/web/rooms/permission/${roomId}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.status === 200) {
            return true;
        }

        return false;
    } catch (error) {
        console.error("Error verifying token:", error);
        return false;
    }
};

export const getRoomSettingsById = async (id) => {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}/settings`);

        if (response) {
            return response;
        }
    } catch (error) {
        console.error("Error grabbing room settings:", error);
        return null;
    }
};

export const getArcadeRoomGames = async (roomId) => {
    try {
        const respAxios = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/clients/arcade-games/${roomId}`);
        if (respAxios?.status === 200) {
            return respAxios?.data?.arcadeGames;
        }
        const sendDefault = constants.DEFAULT_ARCADE_GAMES;
        return sendDefault;
    } catch (error) {
        console.error("Error fetching arcade games:", error);
        const sendDefault = constants.DEFAULT_ARCADE_GAMES;
        return sendDefault;
    }
};
