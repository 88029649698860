/* eslint-disable */
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCaretDown,
    faRightFromBracket,
    faHouse,
    faUser,
    faChartLine,
    faX,
    faBars,
    faEnvelope,
    faPhone,
    faLocationDot,
    faPen,
} from "@fortawesome/free-solid-svg-icons";
library.add(
    faCaretDown,
    faPen,
    faLocationDot,
    faRightFromBracket,
    faHouse,
    faUser,
    faChartLine,
    faX,
    faBars,
    faEnvelope,
    faPhone
);
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router from "./routes";
import { UserContext, InitializeFiltersContext } from "./context/UserContext";
import "@fontsource/barlow";

const queryClient = new QueryClient();

export default function App() {
    return (
        <UserContext.Provider value={InitializeFiltersContext()}>
            <QueryClientProvider client={queryClient}>
                <Router />
            </QueryClientProvider>
        </UserContext.Provider>
    );
}
