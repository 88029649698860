import React from "react";
import styled from "styled-components";
import HeroLogoImage from "../../../assets/images/logos/HeroLogoIntraverse.png";
import { setColor, setFont } from "../../../assets/styles/styles";
import stripeLogo from "../../../assets/images/logos/stripeLogo.png";
import { ReactComponent as Close } from "../../../assets/images/icons/Close.svg";
import Typography from "../../../components/common/text/Typography";

const FooterCheckout = () => {
    const textStyling = {
        color: setColor.darkGreyText,
        textAlign: "center",
        fontFamily: setFont.barlow,
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    };

    return (
        <FooterContainer>
            <ImagesContainer>
                <HeroLogo src={HeroLogoImage} alt="Hero Logo Intraverse" />
                <StyledCloseIcon />
                <LogoStripe src={stripeLogo} alt="Stripe Logo" />
            </ImagesContainer>
            <Typography variant="pWhite" style={textStyling} align="center">
                ©{new Date().getFullYear()} TerraZero Technologies, Intraverse, and any associated logos are
                trademarks, service marks, and/or registered trademarks of TerraZero Technologies.
            </Typography>
        </FooterContainer>
    );
};

export default FooterCheckout;

const FooterContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 25px 50px;
    background-color: ${setColor.black};
    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const HeroLogo = styled.img`
    height: 30px;
    @media (max-width: 768px) {
        height: 22px;
    }
`;
const LogoStripe = styled.img`
    height: 25px;
    @media (max-width: 768px) {
        height: 20px;
    }
`;

const ImagesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
`;

const StyledCloseIcon = styled(Close)`
    width: 20px;
    height: 20px;
    margin: 0 1rem;
    transition: transform 0.3s;
    transform: rotate(135deg);
`;
