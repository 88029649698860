import React, { useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { verifyToken } from "../../api/UserController";
import LoadingIcon from "../../components/common/loading/LoadingIcon";
import { decryptBase64 } from "../../utils/generalUtils";

const WrapperWithCookieAuth = ({ children }) => {
    const [isVerified, setIsVerified] = useState(null); // null indicates loading state
    const [searchParams] = useSearchParams();
    const encodedToken = searchParams.get("token");

    useEffect(() => {
        const verifyTokenAsync = async () => {
            if (encodedToken) {
                try {
                    const token = await decryptBase64(encodedToken);
                    const isValid = await verifyToken(token);
                    setIsVerified(isValid);
                } catch (error) {
                    console.error("Token verification failed:", error);
                    setIsVerified(false); // Treat verification failure as invalid token
                }
            } else {
                setIsVerified(false); // No token found, so set verification to false
            }
        };

        verifyTokenAsync();
    }, [encodedToken]);

    if (isVerified === null) {
        return (
            <LoadingContainer>
                <LoadingIcon hover={false} />
            </LoadingContainer>
        );
    }

    if (isVerified === false) {
        return <Navigate to="/404" replace />;
    }

    return children;
};

WrapperWithCookieAuth.propTypes = {
    children: PropTypes.node,
};

export default WrapperWithCookieAuth;

const LoadingContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
