import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { isAdminRole, isSuperAdminRole } from "../../../redux/slices/users";
import IntraverseMiniLogoIcon from "../user/profile/images/ColoredIntraverseLogo.png";
import { default as Button } from "../../../components/common/button/Button";
import { getWindowSize } from "../../../utils/generalUtils";
import { setColor, setFont } from "../../../assets/styles/styles";
import { ReactComponent as UserLogo } from "../user/wallet/images/user_mobile_nav.svg";
import { ReactComponent as CardLogo } from "../user/wallet/images/card_mobile_nav.svg";
import { default as Dropdown } from "../../../components/common/dropdown/Dropdown";

const NavigationMenu = () => {
    const user = useSelector((state) => state.user);
    const isAdmin = useSelector(isAdminRole);
    const isSuperAdmin = useSelector(isSuperAdminRole);
    const [isOpen, setIsOpen] = useState(false);
    const [activeMobileTab, setActiveMobileTab] = useState("Account");
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const navigate = useNavigate();

    const routeChange = (path) => {
        navigate(path);
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <>
            {windowSize.innerWidth > 769 ? (
                <>
                    <DropdownContainer>
                        <LogoAndDropDownContainer>
                            <PortalHeaderLogo
                                onClick={() => routeChange("/")}
                                src={IntraverseMiniLogoIcon}
                                alt="Intraverse Logo"
                            />
                            <Dropdowns>
                                <Dropdown
                                    title="Account"
                                    items={[
                                        {
                                            id: 0,
                                            value: "Profile",
                                            route: "/console/profile",
                                        },
                                        {
                                            id: 1,
                                            value: "My Rooms",
                                            route: `/console/${user.userId}/my-rooms`,
                                        },
                                    ]}
                                />
                                {(isAdmin || isSuperAdmin) && (
                                    <Dropdown
                                        title="Admin"
                                        items={[
                                            {
                                                id: 2,
                                                value: "Users",
                                                route: "/console/users",
                                            },
                                            {
                                                id: 3,
                                                value: "Rooms",
                                                route: "/console/rooms",
                                            },
                                            {
                                                id: 4,
                                                value: "Vanity Links",
                                                route: "/console/vanityLinks",
                                            },
                                            {
                                                id: 5,
                                                value: "Tables",
                                                route: "/console/tables",
                                            },
                                            {
                                                id: 6,
                                                value: "Files",
                                                route: "/console/files_dashboard",
                                            },
                                            {
                                                id: 7,
                                                value: "Merchants",
                                                route: "/console/merchants",
                                            },
                                            {
                                                id: 8,
                                                value: "Image Editor",
                                                route: "/console/image_editor",
                                            },
                                            {
                                                id: 9,
                                                value: "Surveys/Forms",
                                                route: "/console/surveys",
                                            },
                                            {
                                                id: 10,
                                                value: "Roles and Permissions",
                                                route: "/console/roles",
                                            },
                                        ]}
                                    />
                                )}
                            </Dropdowns>
                        </LogoAndDropDownContainer>
                        <PortalHeaderMenuButtons>
                            <Button onClick={() => routeChange("/")} label="Play Now" variant="DefaultButton" />
                        </PortalHeaderMenuButtons>
                    </DropdownContainer>
                </>
            ) : (
                <>
                    <MobileNavigation>
                        <MobileTopNav>
                            <PortalHeaderMenu isOpen={isOpen}>
                                <PortalHeaderLogo
                                    onClick={() => routeChange("/")}
                                    src={IntraverseMiniLogoIcon}
                                    alt="Intraverse Logo"
                                />
                            </PortalHeaderMenu>
                            <PortalHeaderMenuButtons>
                                <Button
                                    exact
                                    onClick={() => routeChange("/")}
                                    label="Play Now"
                                    variant="DefaultButton"
                                    margin={"0"}
                                />
                            </PortalHeaderMenuButtons>
                        </MobileTopNav>
                        <MobileBottomNav>
                            <PortalHeaderMenuButtons>
                                {activeMobileTab === "Account" ? (
                                    <>
                                        <Button
                                            to="/console/profile"
                                            label="Profile"
                                            variant="NavigationButton"
                                            margin={"0 30px 0 0"}
                                        />
                                        <Button to="/console/wallet" label="Integrations" variant="NavigationButton" />
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            to="/console/wallet"
                                            exact
                                            label="Wallet"
                                            variant="NavigationButton"
                                            isActivated
                                            margin={"0 30px 0 0"}
                                        />
                                        <Button to="/console/orders" label="Orders" variant="NavigationButton" />
                                    </>
                                )}
                            </PortalHeaderMenuButtons>
                        </MobileBottomNav>
                    </MobileNavigation>

                    <MobileFooterNav>
                        <Tab
                            to="/console/profile"
                            isactive={activeMobileTab === "Account"}
                            onClick={() => setActiveMobileTab("Account")}
                        >
                            <NavUserImage isactive={activeMobileTab === "Account"} />
                            Account
                        </Tab>
                        <Tab
                            to="/console/wallet"
                            isactive={activeMobileTab === "Billing"}
                            onClick={() => setActiveMobileTab("Billing")}
                        >
                            <NavCardImage isactive={activeMobileTab === "Billing"} />
                            Billing
                        </Tab>
                        <Tab
                            to="/console/room_personalization"
                            isactive={activeMobileTab === "Customize"}
                            onClick={() => setActiveMobileTab("Customize")}
                        >
                            <NavCardImage isactive={activeMobileTab === "Customize"} />
                            Customize
                        </Tab>
                        <Tab
                            to="/console/rooms"
                            isactive={activeMobileTab === "Rooms"}
                            onClick={() => setActiveMobileTab("Rooms")}
                        >
                            <NavCardImage isactive={activeMobileTab === "Rooms"} />
                            Rooms
                        </Tab>
                    </MobileFooterNav>
                </>
            )}
        </>
    );
};

export default NavigationMenu;

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    min-height: 78px;
    background-color: ${setColor.black};
    padding: 0px 40px;
`;

const LogoAndDropDownContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Dropdowns = styled.div`
    display: flex;
    height: 50px;
    align-self: flex-end;
`;

const NavUserImage = styled(UserLogo)`
    width: 22px;
    height: 22px;
    margin-bottom: 5px;
    fill: ${(props) => (props.isactive ? setColor.iconColor3 : setColor.iconColor)};
`;

const NavCardImage = styled(CardLogo)`
    width: 22px;
    height: 22px;
    margin-bottom: 5px;
    fill: ${(props) => (props.isactive ? setColor.iconColor3 : setColor.iconColor)};
`;

const Tab = styled(NavLink)`
    text-decoration: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    color: ${setColor.iconColor};
    font-family: ${setFont.barlow};
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    ${(props) => {
        if (props.isactive) {
            return `
        color: ${setColor.iconColor3};
      `;
        }
    }}
`;

const MobileFooterNav = styled.div`
    z-index: 1000;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    height: 60px;
    background: ${setColor.mobileFooterBackground};
`;

const MobileNavigation = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
`;

const MobileTopNav = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${setColor.black};
    z-index: 1000;
    padding: 0px 40px;

    @media (max-width: 768px) {
        padding: 12px 20px;
    }
`;

const MobileBottomNav = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${setColor.innerProfileContainer};
    z-index: 1000;
    padding: 0px 40px;

    @media (max-width: 768px) {
        padding: 12px 20px;
    }
`;

const PortalHeaderLogo = styled.img`
    cursor: pointer;
    width: 40px;
    height: 27px;
    margin-right: 3rem;
    margin-left: 1rem;

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 80px;
        margin-right: 2rem;
        margin-left: 0rem;
    }

    @media (max-width: 768px) {
        margin: 0;
    }
`;
const PortalHeaderMenu = styled.div`
    display: flex;
    align-items: center;
`;

const PortalHeaderMenuButtons = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 10px;

    @media (max-width: 768px) {
        margin: 0px;
    }
`;
