import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import Title from "../../components/Title";
import { setColor } from "../../../../assets/styles/styles";
import FileUploader from "../../../../components/common/fileUploader/FileUploader";
import Button from "../../../../components/common/button/Button";

const ImageCrop = () => {
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({
        unit: "%",
        x: 0,
        y: 0,
        width: 50,
        height: 50,
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const imageRef = useRef(null);
    const fileUrl = useRef(null);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setSrc(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onImageLoaded = (image) => {
        imageRef.current = image;
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imageRef.current, crop, "newFile.jpeg");
            setCroppedImageUrl(croppedImageUrl);
        }
    };

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl.current);
                fileUrl.current = window.URL.createObjectURL(blob);
                resolve(fileUrl.current);
            }, "image/png");
        });
    };

    return (
        <div className="App">
            <MainDiv>
                <StyledInput type="file" accept="image/*" onChange={onSelectFile} />
            </MainDiv>
            {src && (
                <div style={{ marginTop: "30px" }}>
                    <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                    />
                </div>
            )}

            {croppedImageUrl && (
                <>
                    <UserConsoleText style={{ marginTop: "2rem" }}>Cropped Image</UserConsoleText>
                    <img
                        alt="Crop"
                        style={{ maxWidth: "100%", margin: "auto", marginTop: "40px" }}
                        src={croppedImageUrl}
                    />
                    <div>
                        <a href={croppedImageUrl} download="cropped-image.jpeg">
                            <Button variant={"PinkButton"} label={"Download Image"} />
                        </a>
                    </div>
                </>
            )}
        </div>
    );
};

const UserConsoleText = styled.h1`
    color: ${setColor.iconColor3};
    font-family: "Barlow Condensed", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 1rem 0rem;
`;

const MainDiv = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
`;

const DownloadButton = styled.button`
    width: 250px;
    margin: auto;
    margin-top: 20px;
`;

const StyledInput = styled.input`
    display: inline-block;
    padding: 10px 20px;
    background: #252333;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 30%;
`;

export default ImageCrop;
