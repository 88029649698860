import React from "react";
import styled from "styled-components";
import { setColor } from "../../../assets/styles/styles";
import Typography from "../../../components/common/text/Typography";
import AppleIcon from "../assets/icons/UI_Logo_AppStore_Apple.png";
import AndroidIcon from "../assets/icons/UI_Logo_AppStore_Google.png";

const MobileIcon = () => (
    <MobileIconContainer>
        <StyledTypography variant="pWhite" color="rgba(190, 174, 255, 0.50)">
            Coming Soon
        </StyledTypography>
        <IconContainer>
            <IconImage src={AppleIcon} alt="Apple Icon" />
            <Typography
                variant="pWhite"
                color="rgba(190, 174, 255, 0.50)"
                style={{ margin: "0", fontWeight: "400", textTransform: "uppercase" }}
            >
                |
            </Typography>
            <IconImage src={AndroidIcon} alt="Android Icon" />
        </IconContainer>
    </MobileIconContainer>
);

export default MobileIcon;

const StyledTypography = styled(Typography)`
    text-transform: uppercase;
    margin: 0;
    font-weight: 600;

    @media (max-width: 960px) {
        font-size: 10px;
    }
`;

const MobileIconContainer = styled.div`
    border-radius: 0px 0px 15px 15px;
    width: 165px;
    height: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    z-index: 9999;
    right: 50px;
    background: ${setColor.solidDarkPurpleBackground};
    gap: 15px;

    @media (max-width: 960px) {
        width: 110px;
        gap: 10px;
        right: 15px;
        padding: 10px;
    }
`;

const IconContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 15px;

    @media (max-width: 960px) {
        gap: 5px;
    }
`;
const IconImage = styled.img`
    height: 30px;
    @media (max-width: 960px) {
        height: 20px;
    }
`;
