import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ReactComponent as ChevronIcon } from "../../../../../assets/images/icons/Chevron.svg";
import { setColor, setFont } from "../../../../../assets/styles/styles";

const Pagination = ({ totalNumber, currentPage, onPageChange, totalPages }) => {
    const pageRange = Array.from({ length: totalPages }, (_, index) => index + 1);
    const [isDropdownOpenPage, setIsDropdownOpenPage] = useState(false);
    const [selectedOptionPage, setSelectedOptionPage] = useState(currentPage.toString());

    const toggleDropdownPage = () => {
        setIsDropdownOpenPage(!isDropdownOpenPage);
    };

    const handleOptionClickPage = (page) => {
        setIsDropdownOpenPage(false);
        setSelectedOptionPage(page);
        onPageChange(page);
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };
    const handleNextClick = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <StyledPagination>
            <div className="back" onClick={handlePrevClick}>
                <StyledIconPrev />
            </div>
            <div className="next" onClick={handleNextClick}>
                <StyledIconNext />
            </div>
            <div className="of">OF {totalNumber}</div>
            <div className="page-input">
                <DropdownButton onClick={toggleDropdownPage} isopen={isDropdownOpenPage.toString()}>
                    {currentPage}
                    <StyledDropdownIcon isopen={isDropdownOpenPage.toString()} />
                    {isDropdownOpenPage && (
                        <DropdownContent>
                            {pageRange.map((page) => (
                                <DropdownItem
                                    key={page}
                                    onClick={() => handleOptionClickPage(page.toString())}
                                    isSelected={selectedOptionPage === page.toString()}
                                >
                                    {page}
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    )}
                </DropdownButton>
            </div>
        </StyledPagination>
    );
};

export default Pagination;

const StyledPagination = styled.div`
    display: flex;
    align-items: center;
    font-family: "Barlow-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.8px;
    position: relative;
    height: 43px;
    width: 303px;

    @media (max-width: 768px) {
        width: 200px;
    }

    .back {
        border-radius: 5px;
        border: 1px solid ${setColor.modalOutline};
        height: 40px;
        left: 40px;
        position: absolute;
        width: 40px;
        cursor: pointer;
        @media (max-width: 768px) {
            left: 0px;
        }
    }

    .next {
        border: 1px solid ${setColor.modalOutline};
        border-radius: 5px;
        height: 40px;
        left: 260px;
        position: absolute;
        width: 40px;
        cursor: pointer;
        @media (max-width: 768px) {
            left: 160px;
        }
    }

    .of {
        color: ${setColor.iconColor};
        right: 4rem;
        position: absolute;
        @media (max-width: 768px) {
            right: 3rem;
            font-size: 12px;
        }
    }

    .page-input {
        left: 90px;
        position: absolute;
        @media (max-width: 768px) {
            left: 45px;
        }
    }

    .text-wrapper {
        color: #fbfcff;
    }
`;

const StyledIconNext = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin: 12px 0 0 12px;
    transform: rotate(270deg) !important;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin: 12px 0 0 12px;
    transform: rotate(90deg) !important;
`;
const StyledDropdownIcon = styled(ChevronIcon)`
    width: 15px;
    height: 15px;
    margin: 0px 0px -2px 5px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === "true" ? "rotate(180deg)" : "rotate(0deg)")};
`;

const DropdownButton = styled.button`
    position: relative;
    padding: 10px;
    background-color: TRANSPARENT;
    color: ${setColor.inputText};
    font-family: ${setFont.barlow};
    font-size: 16px;
    font-weight: 700;
    border-radius: 3px;
    margin: 0rem 1rem 0rem 0rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    width: 70px;
    cursor: pointer;
    border: ${(props) =>
        props.isopen === "true" ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.modalOutline}`};

    @media (max-width: 768px) {
        font-size: 13px;
        width: 50px;
    }
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : "transparent")};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 15px;
    top: 3rem;
    left: 0rem;
    width: fit-content;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    text-align: left;
    z-index: 1;
    @media (max-width: 768px) {
        padding: 5px;
    }
`;
