import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchSpotify } from "../../redux/slices/spotify";
import { fetchUserId, fetchUserName, fetchUserToken, fetchUserEmail, isGuestRole } from "../../redux/slices/users";
import { getFromSessionStorage } from "../../hooks/useSessionStorage";
import IntraverseLogoIcon from "../../assets/images/logos/Intraverse_Logo_Icon.png";
import AvatarPresetPage from "../presets/AvatarPresetPage";
import VanityURLRedirects from "../../features/vagon/VanityURLRedirects";
import GenericTCModal from "../../components/GenericTCModal";
import DiamondTexture from "../../assets/images/backgrounds/Diamond_Checkered_Background.png";
import StartBG from "../../assets/images/backgrounds/StartBG.png";
import Banner from "../../components/layout/ui/Banner";
import BackgroundContainer from "../../components/common/Backgrounds/BackgroundContainer";
import NavigationMenu from "./NavigationMenu";
import { setColor } from "../../assets/styles/styles";
import { TZ_FREE_FORM_JSON, DEFAULT_QUERY_OPTIONS } from "../../constants";
import { getDeviceOrientation, isTokenExpired } from "../../utils/generalUtils";
import Typography from "../../components/common/text/Typography";
import Iconify from "../../components/common/icon/Iconify";
import { checkAvatarExistsByUserId, verifyToken } from "../../api/UserController";

const StartingPage = () => {
    const [renderComponent, setRenderComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [orientation, setOrientation] = useState(getDeviceOrientation());

    // User States
    const spotify = useSelector(fetchSpotify);
    const user = useSelector((state) => state?.user);
    const userId = useSelector(fetchUserId);
    const userToken = useSelector(fetchUserToken);
    const tknExpr = useSelector((state) => state?.user?.expiration);
    const [spotifyPlayer, setSpotifyPlayer] = useState(null);
    const displayName = useSelector(fetchUserName);
    const email = useSelector(fetchUserEmail);
    const checkGuestRole = useSelector(isGuestRole);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [checkboxStatus, setCheckboxStatus] = useState(true);
    const [selectedAvatar, setSelectedAvatar] = useState(getFromSessionStorage("selectedAvatar"));
    const [selectorScreenPage, setSelectorScreenPage] = useState("home");
    const [spotifyNotPremiumAccount, setSpotifyNotPremiumAccount] = useState(false);

    const {
        data: avtrExistsOrNotInDB,
        error: avtrExistsOrNotInDBErr,
        isLoading: avtrExistsOrNotInDBLsding,
    } = useQuery(["avtrExistsOrNotInDB", userId], () => checkAvatarExistsByUserId(userId), {
        enabled: !!userId,
        ...DEFAULT_QUERY_OPTIONS(),
    });

    const {
        data: checkTkn,
        refetch: refetchVerifyToken,
        isLoading: tknldn,
    } = useQuery(["checkTkn", userToken], () => verifyToken(userToken), {
        enabled: !!userToken,
        ...DEFAULT_QUERY_OPTIONS(),
    });

    const handleAcceptTerms = () => {
        if (isTokenExpired(tknExpr) || !checkTkn) {
            setSelectorScreenPage("home");
        }
        setAcceptedTerms(true);

        // The below code has to be initialized when a button click happens
        if (spotify.token) {
            const player = new window.Spotify.Player({
                name: "Web Playback SDK",
                getOAuthToken: (cb) => {
                    cb(spotify.token);
                },
                volume: 0.3,
            });
            setSpotifyPlayer(player);
        }
    };

    useEffect(() => {
        const handleResize = async () => {
            const orientation = await getDeviceOrientation();
            setOrientation(orientation);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const attributes = {
            room: "Intraverse",
            email: email || null,
            name: displayName || null,
        };

        window.pushHotJarAttributes(userId || null, attributes);
        if (avtrExistsOrNotInDB) {
            if (avtrExistsOrNotInDB.totalAvatarsCount !== 0 && !selectedAvatar) {
                setSelectedAvatar(avtrExistsOrNotInDB.avatars[0]);
            }
        }
        if (selectorScreenPage === "play") {
            if (avtrExistsOrNotInDB) {
                if (
                    !isTokenExpired(tknExpr) &&
                    checkTkn &&
                    avtrExistsOrNotInDB.totalAvatarsCount === 0 &&
                    !selectedAvatar
                ) {
                    setRenderComponent(
                        <AvatarPresetPage
                            setSelectedAvatar={setSelectedAvatar}
                            forceLandScape
                            userToken={userToken}
                            userId={userId}
                        />
                    );
                }
            }
            if (
                !isTokenExpired(tknExpr) &&
                checkTkn &&
                userToken &&
                userId &&
                acceptedTerms &&
                selectedAvatar &&
                !checkGuestRole
            ) {
                setRenderComponent(
                    <VanityURLRedirects userId={userId} token={userToken} spotifyPlayer={spotifyPlayer} />
                );
            }
            if (checkGuestRole && userToken && userId) {
                setRenderComponent(
                    <>
                        <Typography variant="h2" style={{ color: "white" }}>
                            You are not authorized to log in to the Intraverse yet
                        </Typography>
                        <Typography
                            variant="h2"
                            style={{ color: setColor.iconColor3, padding: "2rem 0", cursor: "pointer" }}
                            alt="Intraverse Logo"
                            onClick={() => setSelectorScreenPage("home")}
                        >
                            <Iconify icon="ic:baseline-arrow-back" Color="#beaeff" style={{ height: "25px" }} /> Go Back
                            To Main Page
                        </Typography>
                    </>
                );
            }
        }
        setTimeout(() => {
            if (isLoading) {
                setIsLoading(false);
            }
        }, 3000);
    }, [
        checkTkn,
        userToken,
        userId,
        avtrExistsOrNotInDB,
        acceptedTerms,
        selectorScreenPage,
        selectedAvatar,
        checkGuestRole,
        spotifyPlayer,
        tknExpr,
        email,
        displayName,
    ]);

    return (
        <BackgroundContainer
            variant="CustomImage"
            backgroundSize={selectorScreenPage === "home" ? "cover" : "250px auto"}
            selectorScreenPage={selectorScreenPage}
            backgroundImage={selectorScreenPage === "home" ? StartBG : DiamondTexture}
            backgroundColor={selectorScreenPage === "play" ? setColor.inputBgColor : null}
        >
            <Banner
                width={"auto"}
                zIndex="3000"
                message={"We failed to connect. Please connect with a Premium Spotify account."}
                type={"ERROR"}
                openBanner={spotifyNotPremiumAccount}
                setOpenBanner={setSpotifyNotPremiumAccount}
                topDisplay
                showCloseButton
                gameScreen={false}
                timer={12000}
            />
            <>
                {selectorScreenPage === "play" ? (
                    <>
                        <IntraverseLogo
                            src={IntraverseLogoIcon}
                            alt="Intraverse Logo"
                            onClick={() => setSelectorScreenPage("home")}
                        />
                        <div>
                            {!isTokenExpired(tknExpr) &&
                                checkTkn &&
                                !checkGuestRole &&
                                selectedAvatar &&
                                !acceptedTerms && (
                                    <GenericTCModal
                                        setAcceptedTerms={handleAcceptTerms}
                                        vanityLinkData={TZ_FREE_FORM_JSON}
                                        setCheckboxStatus={setCheckboxStatus}
                                        checkboxStatus={checkboxStatus}
                                    />
                                )}
                        </div>
                        {renderComponent}
                    </>
                ) : (
                    <NavigationMenu
                        orientation={orientation}
                        setSelectorScreenPage={setSelectorScreenPage}
                        setSpotifyNotPremiumAccount={setSpotifyNotPremiumAccount}
                    />
                )}
            </>
        </BackgroundContainer>
    );
};

export default StartingPage;

const IntraverseLogo = styled.img`
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 20px;
    left: 20px;
    z-index: 5;

    @media (max-width: 960px) {
        width: 25px;
        height: 25px;
        top: 5px;
        left: 5px;
    }
    @media (min-width: 960px) and (max-width: 1440px) {
        width: 30px;
        height: 30px;
        top: 20px;
        left: 20px;
    }
`;
