import { store } from "../Store";

export const UpdateSpotify = (token, refreshToken, player) => ({
    type: "UPDATE_SPOTIFY",
    payload: {
        token,
        refreshToken,
        player,
    },
});

export const SaveSpotify = (token, refreshToken) => ({
    type: "SPOTIFY_SAVE",
    payload: {
        token,
        refreshToken,
    },
});

export const SaveSpotifyPlayer = (player) => ({
    type: "SPOTIFY_SAVE_PLAYER",
    payload: {
        player,
    },
});

export const DeleteSpotify = () => {
    store.dispatch({ type: "SPOTIFY_DELETE" });
};
