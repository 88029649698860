import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LoadingIcon from "../../../components/common/loading/LoadingIcon";

const Body = ({ isLoading, children }) => (
    <ContentContainer>
        {isLoading ? (
            <LoadingDiv>
                <LoadingIcon />
            </LoadingDiv>
        ) : (
            children
        )}
    </ContentContainer>
);

Body.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node,
};

export default Body;

const ContentContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - 292px); // 292px is the height of the nav bar + title component combined
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
`;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 292px); // 292px is the height of the nav bar + title component combined
    height: 100%;
    margin: 0;
`;
