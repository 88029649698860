import { API_GET, API_DELETE, API_PUT, API_POST } from "../../../../../config/axiosConfig";

export async function getAllUsers() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/users`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.data;
        }
        return [];
    } catch (err) {
        console.error("Error fetching data:", err);
        return [];
    }
}

export async function getRooms() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms/all`;
        const respAxios = await API_GET(url);

        if (respAxios?.status === 200) {
            return respAxios?.data?.rooms;
        }
        return [];
    } catch (err) {
        console.error("Error fetching data:", err);
        return [];
    }
}

export async function getRoomSettings(roomId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${roomId}/settings`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.roomSettingsId;
        }
        return null;
    } catch (err) {
        console.error("Error fetching data:", err);
        return null;
    }
}

export async function AddSubroomRelation(roomId, subroomRoomId, subroomRoomSettingsId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${roomId}/relations`;
        const respAxios = await API_POST(url, {
            subroomRoomId,
            subroomRoomSettingsId,
        });
        return respAxios;
    } catch (err) {
        console.error("Error creating subroom:", err);
        return null;
    }
}

export async function EditSubroomRelation(relationshipId, subroomRoomId, subroomRoomSettingsId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${relationshipId}/relations`;
        const respAxios = await API_PUT(url, {
            subroomRoomId,
            subroomRoomSettingsId,
        });
        return respAxios;
    } catch (err) {
        console.error("Error creating subroom:", err);
        return null;
    }
}

export async function getAllRoles() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/roles`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.roles;
        }
        return [];
    } catch (err) {
        console.error("Error fetching data:", err);
        return [];
    }
}

export async function getRoomSettingsById(id) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}/settings`;
        const respAxios = await API_GET(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getRoomUsers(roomId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.roomUsers;
        }
        return [];
    } catch (err) {
        return err;
    }
}

export async function addUserToRoom(roomId, userId, roleId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}`;
        const respAxios = await API_PUT(url, {
            roomUserId: userId,
            roleId,
        });
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function removeUserFromRoom(roomId, userId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}/${userId}`;
        const respAxios = await API_DELETE(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getRoomById(id) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.room;
        }
        return null;
    } catch (err) {
        console.error("Error fetching data:", err);
        return null;
    }
}

export async function createRoomNew(data) {
    const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms`;
    const respAxios = await API_POST(url, data);
    return respAxios;
}

export async function createRoom(
    name,
    code,
    type,
    freeFormJson,
    expiry,
    subtitle,
    description,
    selectedUserId,
    tags,
    groupId
) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms`;
        const respAxios = await API_POST(url, {
            name,
            code,
            roomType: type,
            json: freeFormJson,
            expiry,
            subtitle,
            description,
            userIdSelection: selectedUserId,
            tags,
            groupId,
        });
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function updateRoom(id, updateBody) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`;
        const respAxios = await API_PUT(url, updateBody);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getRoomTags() {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/tags`);
        return response.data.tags;
    } catch (err) {
        console.error("Error fetching roomTags:", err);
        return null;
    }
}

export async function getUserGroups() {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/groups`);

        return response.data.groups;
    } catch (err) {
        console.error("Error fetching userGroups:", err);
        return null;
    }
}

export async function getRoomRelations(id) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}/relations`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.roomRelationsData;
        }
        return null;
    } catch (err) {
        console.error("Error fetching data:", err);
        return null;
    }
}

export const getRoomPrefabs = async () => {
    try {
        const respAxios = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/prefabs`);
        if (respAxios?.status === 200) {
            return respAxios?.data;
        }
        return null;
    } catch (error) {
        console.error("Error fetching room prefabs:", error);
        return null;
    }
};

export const getRoomGames = async (roomId) => {
    try {
        const respAxios = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/clients/casino-games/${roomId}`);
        if (respAxios?.status === 200) {
            return respAxios?.data?.games;
        }
        return [];
    } catch (error) {
        console.error("Error fetching room games:", error);
        return [];
    }
};

export const addGameToRoom = async (roomId, data) => {
    try {
        return await API_POST(`${process.env.REACT_APP_CORE_API_URI}/web/clients/casino-games/${roomId}`, data);
    } catch (error) {
        console.error("Error adding game to room:", error);
        return error;
    }
};

export const updateGameToRoom = async (gameId, data) => {
    try {
        return await API_PUT(`${process.env.REACT_APP_CORE_API_URI}/web/clients/casino-games/${gameId}`, data);
    } catch (error) {
        console.error("Error adding game to room:", error);
        return error;
    }
};

export const getAllGroups = async () => {
    try {
        const respAxios = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/groups`);
        if (respAxios?.status === 200) {
            return respAxios?.data?.groups;
        }
        return [];
    } catch (error) {
        console.error("Error fetching room prefabs:", error);
        return null;
    }
};
