/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { SUPERADMIN_ROLE, ADMIN_ROLE, LOG_EVENTS_AVATAR_SELECTION } from "../../constants";
import { SaveVanityLink } from "../../redux/actions/VanityLinkActions";
import { fetchVanityLinkData, sendAutoLoginLink } from "../../api/VanityLinkController";
import { fetchUserId, fetchUserToken } from "../../redux/slices/users";
import { ReactComponent as PinkIntraverseLogoIcon } from "../../assets/images/logos/pink_intraverse_logo.svg";
import XIconImg from "../../assets/images/icons/x.svg";
import DividerDotted from "../../assets/images/icons/DividerDotted.png";
import BackgroundContainer from "../../components/common/Backgrounds/BackgroundContainer";
import { setColor, setFont } from "../../assets/styles/styles";
import Typography from "../../components/common/text/Typography";
import { Input } from "../../components/common/input/Input";
import PasswordChecker from "../login/PasswordChecker";
import AuthSocial from "../../api/AuthSocial";
import {
    LogInWithEmail,
    sendEventsLog,
    emailExistsChecker,
    RegisterUser,
    RegisterGuestUser,
} from "../../api/UserController";
import { SaveUser } from "../../redux/actions/UserActions";
import { SaveMerchant } from "../../redux/actions/MerchantActions";
import Iconify from "../../components/common/icon/Iconify";
import { CheckBoxMain } from "../../components/common/checkBox/CheckBoxMain";

import {
    isValidEmail,
    nameValidation,
    passwordValidator,
    updateUrlWithVanity,
    getFileType,
} from "../../utils/generalUtils";
import Button from "../../components/common/button/Button";
import VanityAvatarPage from "./VanityAvatarPage";
import VanityURLRedirects from "../../features/vagon/VanityURLRedirects";
import { AuthenticateAutoLogin } from "./helpers/utils";
import IntraverseLetteringLogo from "../../assets/images/logos/Intraverse_Logo_Lettering.svg";
import VanitySpotify from "../../features/spotify/VanitySpotify";
import { getFromSessionStorage, setToSessionStorage } from "../../hooks/useSessionStorage";

const VanityStartingPage = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    let pathId = code
        ? window.location.pathname.split("/").pop().split("?")[0]
        : window.location.pathname.split("/").pop();
    const [vanityLinkData, setVanityLinkData] = useState({});

    const spotifyToken = getFromSessionStorage("spotifyAccessToken");

    const [email, setEmail] = useState("");
    const [backgroundFileType, setBackgroundFileType] = useState("");
    const [password, setPassword] = useState("");
    const [emailExists, setEmailExists] = useState(null);
    const [noUsername, setNoUsername] = useState(false);

    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [isEmailError, setIsEmailError] = useState(false);
    const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
    const [nameError, setNameError] = useState("");
    const [isNameError, setIsNameError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [checkboxErrorMsg, setCheckboxErrorMsg] = useState("");
    const [isCheckboxError, setIsCheckboxError] = useState(false);
    const [spotifyError, setSpotifyError] = useState("");

    const [spotifyPlayer, setSpotifyPlayer] = useState(null);

    const [loginError, setLoginError] = useState("");
    const [avatarExistsTrue, setAvatarExistsTrue] = useState(false);
    const [finalAvatar, setFinalAvatar] = useState(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);

    const [registerForm, setRegisterForm] = useState(false);
    const [currentScreen, setCurrentScreen] = useState("vanityStartingPage");

    // Form data states
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [checkboxStatus, setCheckboxStatus] = useState(false);
    const [specialOffers, setSpecialOffers] = useState(false);

    const token = useSelector(fetchUserToken);
    const userId = useSelector(fetchUserId);

    const [isLoading, setIsLoading] = useState(true);

    // Adds error messaging after form submit to prevent next page
    const handleEmailData = () => {
        if (!checkboxStatus) {
            setIsCheckboxError(true);
        } else {
            setIsCheckboxError(false);
        }
        if (!passwordValidator(password) && password.length > 0) {
            setIsPasswordError(true);
        } else {
            setIsPasswordError(false);
        }
        if (email !== "" && !isValidEmail(email)) {
            setIsEmailError(true);
        } else {
            setIsEmailError(false);
        }
        if (checkboxStatus && passwordErrorMsg === "" && emailErrorMsg === "") {
            setRegisterForm(true);
        } else {
            setRegisterForm(false);
        }
    };

    const handleRegistrationDataSubmit = async () => {
        if (nameError !== "") {
            setIsNameError(true);
        } else {
            setIsNameError(false);
            setCurrentScreen("vanityAvatarScreen");
        }
    };

    const handleGuestUserData = () => {
        if (!checkboxStatus) {
            setIsCheckboxError(true);
        } else {
            setIsCheckboxError(false);
        }
        if (email !== "" && !isValidEmail(email)) {
            setIsEmailError(true);
        } else {
            setIsEmailError(false);
        }
        if (checkboxStatus && isValidEmail(email)) {
            if (avatarExistsTrue) {
                setCurrentScreen("vanityRedirect");
            } else {
                setCurrentScreen("vanityAvatarScreen");
            }
        }
    };

    const handleEmailCheck = async (e) => {
        e.preventDefault();
        const emailValue = e.target.value;
        setEmail(emailValue);

        if (emailValue.trim() !== "") {
            setEmail(emailValue);
            const exists = await emailExistsChecker(emailValue.toLowerCase());
            setEmailExists(exists);
        }
    };

    // Hotjar logging, we don't due HotJar attributes on free account currently
    // useEffect(() => {
    //     const attributes = {
    //         room: 'Intraverse',
    //         email: email || null,
    //         name: username || null,
    //     };
    //     window.pushHotJarAttributes(userId || null, attributes);
    // }, [username, email, token, userId]);

    // Handles error messaging and form validation
    useEffect(() => {
        if (firstName.length === 0 || lastName.length === 0) {
            setNameError("Please provide your first and last name");
        } else if (!nameValidation(firstName, lastName)) {
            setNameError("Your name should only contain letters and/or spaces");
        } else {
            setNameError("");
        }
        if (!checkboxStatus) {
            setCheckboxErrorMsg("Please verify that you are over 18 and agree to our terms before proceeding");
        } else {
            setCheckboxErrorMsg("");
        }
        if (!passwordValidator(password) && password.length > 0) {
            setPasswordErrorMsg("Please select a valid password");
        } else {
            setPasswordErrorMsg("");
        }
        if (email !== "" && !isValidEmail(email)) {
            setEmailErrorMsg("Please enter a valid email address");
        } else {
            setEmailErrorMsg("");
        }
    }, [firstName, lastName, email, password, checkboxStatus]);

    useEffect(() => {
        if (getFromSessionStorage("email")) {
            setCurrentScreen("vanityAvatarScreen");
        }

        if (code) {
            setCheckboxStatus(true);
            setCurrentScreen("vanityEmailPage");
        }
    }, [email, code]);

    // Retrieves vanity link data and information on url load
    useEffect(() => {
        const authenticateAutoLogin = async () => {
            try {
                const id = queryParams.get("id");
                const access = queryParams.get("access");
                const vanity = queryParams.get("vanity");
                const result = await AuthenticateAutoLogin(id, access, vanity);

                if (!result) {
                    return window.location.replace("/404");
                }

                dispatch(
                    SaveUser(
                        result.data.user.email,
                        result.data.user.displayName,
                        result.data.token,
                        result.data.userId,
                        "",
                        false,
                        result.data.expiration,
                        ""
                    )
                );

                updateUrlWithVanity(result.vanity);

                return result.vanity;
            } catch (error) {
                console.error("Error fetching the vanity link data:", error);
            }

            return null;
        };

        if (props?.autoLogin && pathId.includes("autologin")) {
            const authenticateAutoLoginResult = async () => {
                try {
                    const result = await authenticateAutoLogin();
                    // eslint-disable-next-line
                    pathId = result;
                    setNoUsername(true);

                    if (!avatarExistsTrue) {
                        setCurrentScreen("vanityAvatarScreen");
                    } else {
                        setCurrentScreen("vanityRedirect");
                    }
                } catch (error) {
                    console.error("Error during auto-login authentication:", error);
                }
            };
            authenticateAutoLoginResult();
        }

        const getVanityLinkData = async (pathId) => {
            try {
                const { data, status } = await fetchVanityLinkData(pathId);

                if (status !== 200) {
                    window.location.replace(process.env.REACT_APP_HOME_PAGE_URL);
                }

                setVanityLinkData(data);
                dispatch(
                    SaveVanityLink(
                        data.clientLogo,
                        data.backgroundImage,
                        data.freeformJson,
                        data.clientName,
                        data.emailLogin,
                        data.spotifyLogin,
                        data.broadcast,
                        data.ambientTrack,
                        data.guestOnlyMode,
                        data.vanityLinkId
                    )
                );

                if (data.backgroundImage) {
                    const fileType = await getFileType(data.backgroundImage);
                    setBackgroundFileType(fileType);
                }

                setIsLoading(false);
            } catch (error) {
                window.location.replace("/"); // will reload page if no vanity link found
                console.error("Error fetching the vanity link data:", error);
            }
        };

        if (pathId === "[object%20Object]") {
            return;
        }

        if (pathId.includes("autologin") === false) {
            getVanityLinkData(pathId);
        }
    }, [pathId]);

    // Handles login if user exists
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const res = await LogInWithEmail(email, password);

                if (res.status === 200) {
                    const role = res.userRoles[0];
                    if (role === SUPERADMIN_ROLE || role === ADMIN_ROLE || res.isMerchant) {
                        if (res.isMerchant) {
                            const {
                                merchantName,
                                merchantId,
                                street,
                                city,
                                country,
                                postalCode,
                                merchantBusinessType,
                                stripeClientId,
                            } = res.merchant;
                            const address = { street, city, country, postalCode };

                            dispatch(
                                SaveMerchant(merchantName, merchantId, address, merchantBusinessType, stripeClientId)
                            );
                        }

                        if (res.userRoles[0] === SUPERADMIN_ROLE || res.userRoles[0] === ADMIN_ROLE || res.isMerchant) {
                            dispatch(
                                SaveUser(
                                    res.user.email,
                                    res.user.displayName,
                                    res.token,
                                    res.refreshToken,
                                    res.userId,
                                    res.userRoles[0],
                                    res.isMerchant,
                                    res.expiration,
                                    res.profileIconBase64
                                )
                            );
                        }
                    } else {
                        dispatch(
                            SaveUser(
                                res.user.email,
                                res.user.displayName,
                                res.token,
                                res.refreshToken,
                                res.userId,
                                "",
                                res.isMerchant,
                                res.expiration,
                                res.profileIconBase64
                            )
                        );
                    }
                    setNoUsername(true);
                    setCurrentScreen("vanityAvatarScreen");
                } else {
                    setLoginError("Failed to login, please ensure your information is correct");
                    setIsFormSubmitted(false);
                    setTimeout(() => {
                        setLoginError("");
                        // Clear error message after 10 seconds
                        // window.location.reload(); Not sure if we want to reload the page here
                    }, 10000);
                }
            } catch (error) {
                console.error("API call error:", error);
                setLoginError("Failed to login, please try again.");
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            } finally {
                setIsLoading(false);
            }
        };

        if (isFormSubmitted && !isEmailError && !isPasswordError && emailErrorMsg === "" && passwordErrorMsg === "") {
            fetchData();
        }
    }, [isFormSubmitted, isEmailError, isPasswordError, emailErrorMsg, passwordErrorMsg, email, password]);

    useEffect(() => {
        if (spotifyToken && !window.Spotify) {
            const script = document.createElement("script");
            script.src = "https://sdk.scdn.co/spotify-player.js";
            script.async = true;
            document.body.appendChild(script);
            window.onSpotifyWebPlaybackSDKReady = () => {};
        }
    }, [spotifyToken]);

    const handleAvatarSelect = (avatar) => {
        setFinalAvatar(avatar);
    };
    const handleRegisterSubmit = useCallback(async () => {
        setIsLoading(true);

        const spotifyToken = getFromSessionStorage("spotifyAccessToken");
        if (spotifyToken) {
            const player = new window.Spotify.Player({
                name: "Web Playback SDK",
                getOAuthToken: (cb) => cb(spotifyToken),
                volume: 0.3,
            });
            setSpotifyPlayer(player);
        }

        // Not implemented properly, causing issues with network requests
        // if (!code) {
        //     try {
        //         await checkAvatarExistsByUserId(userId);
        //     } catch (error) {
        //         setAvatarExistsTrue(error !== 'Avatar not found');
        //     }
        // }
        if (!vanityLinkData.guestOnlyMode) {
            const res = await RegisterUser(firstName, lastName, email, password, username, specialOffers);
            if (res !== null) {
                dispatch(
                    SaveUser(
                        res.user.email,
                        res.user.displayName,
                        res.token,
                        res.refreshToken,
                        res.userId,
                        "",
                        res.isMerchant,
                        res.expiration,
                        res.profileIconBase64
                    )
                );
            }
            setIsLoading(false);
            setCurrentScreen("vanityRedirect");
        } else {
            const res = await RegisterGuestUser({
                email: email || getFromSessionStorage("email"),
                displayName: username,
                vanityLinkId: vanityLinkData.vanityLinkId,
                optInMarketing: specialOffers,
                clientName: vanityLinkData.clientName,
                guestOnlyMode: vanityLinkData.guestOnlyMode,
            });
            if (res !== null) {
                dispatch(
                    SaveUser(
                        res.user.email,
                        res.user.displayName,
                        res.token,
                        res.refreshToken,
                        res.userId,
                        res.userRoles[0],
                        res.isMerchant,
                        res.expiration,
                        res.profileIconBase64
                    )
                );
                setToSessionStorage("token", res.token);
                setToSessionStorage("userId", res.userId);
                setLoginError("");
                sendEventsLog(
                    res.token,
                    pathId,
                    res.userId,
                    LOG_EVENTS_AVATAR_SELECTION,
                    "info",
                    {
                        avatarpresetid: getFromSessionStorage("selectedAvatar"),
                    },
                    "",
                    ""
                );
            } else {
                setLoginError("Failed to login, please try again.");
            }
            setIsLoading(false);
            setCurrentScreen("vanityRedirect");
        }
    }, [dispatch, email, firstName, lastName, password, specialOffers, username, vanityLinkData]);

    // Handles sending an email to the user for auto-login link
    const sendAutoLoginEmail = async () => {
        setIsEmailError(false);

        // Validate inputs
        if (!email || !pathId) {
            setIsEmailError(true);
            setEmailErrorMsg("There is no email or vanity path to send the email to. Please try again.");
            return;
        }

        try {
            const emailSent = await sendAutoLoginLink(email, pathId);

            if (!emailSent) {
                setIsEmailError(true);
                setEmailErrorMsg("Failed to send email, please try again or try another email.");
                return;
            }

            setSendEmail(true);
        } catch (error) {
            console.error("Error sending auto login email:", error);
            setIsEmailError(true);
            setEmailErrorMsg("An error occurred while trying to send the email. Please try again.");
        }
    };

    return (
        <>
            {isLoading && Object.keys(vanityLinkData).length === 0 && (
                <Box
                    sx={{
                        width: "100%",
                        color: setColor.boxContainerBg,
                    }}
                >
                    <LinearProgress color="inherit" size="lg" />
                </Box>
            )}

            {currentScreen === "vanityRedirect" && !isLoading && (
                <VanityURLRedirects
                    token={token || getFromSessionStorage("token")}
                    userId={userId || getFromSessionStorage("userId")}
                    spotifyPlayer={spotifyPlayer}
                    finalAvatar={finalAvatar}
                />
            )}

            {!isLoading && currentScreen !== "vanityRedirect" && (
                <BackgroundContainer
                    variant={backgroundFileType === "mp4" ? "CustomVideo" : "CustomImage"}
                    backgroundSize={"cover"}
                    backgroundImage={vanityLinkData.backgroundImage}
                    videoSource={vanityLinkData.backgroundImage}
                    backgroundColor={null}
                >
                    {currentScreen !== "vanityStartingPage" && <Overlay />}
                    {currentScreen === "vanityStartingPage" && (
                        <>
                            <PartnershipBannerContainer>
                                <div>
                                    <IntraverseLogoBanner src={IntraverseLetteringLogo} alt="Intraverse Logo Icon" />
                                </div>
                                {vanityLinkData.clientLogo && (
                                    <>
                                        <div>
                                            <XIcon src={XIconImg} alt="Close" />
                                        </div>
                                        <div>
                                            <PartnerLogo src={vanityLinkData.clientLogo} alt="Client Logo Icon" />
                                        </div>
                                    </>
                                )}
                            </PartnershipBannerContainer>

                            <GetStartedContainer onClick={() => setCurrentScreen("vanityEmailPage")}>
                                <StyledPinkIntraverseLogoIcon />
                                <StartingText>Get Started</StartingText>
                            </GetStartedContainer>
                        </>
                    )}

                    {currentScreen === "vanityEmailPage" && (
                        <EmailFormContainer guestOnlyMode={vanityLinkData.guestOnlyMode}>
                            {registerForm && (
                                <Iconify
                                    style={{ position: "absolute", top: "20px", left: "20px", height: "50px" }}
                                    Color={setColor.accent4}
                                    onClick={() => setRegisterForm(false)}
                                    icon="material-symbols:arrow-back-ios"
                                />
                            )}
                            <Iconify
                                style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "20px",
                                    height: "50px",
                                    cursor: "pointer",
                                }}
                                Color={setColor.accent4}
                                onClick={() => setCurrentScreen("vanityStartingPage")}
                                icon="material-symbols:close"
                            />
                            {vanityLinkData.freeformJson?.emailTitle ? (
                                <>
                                    <IntraverseLogo src={vanityLinkData.clientLogo} alt="Client Logo Icon" />
                                    <Typography
                                        style={{ fontWeight: 500, fontSize: "22px", marginBottom: "10px" }}
                                        variant={"pWhite"}
                                    >
                                        {vanityLinkData.freeformJson?.emailTitle}
                                    </Typography>
                                    {vanityLinkData.freeformJson?.emailSubtitle && (
                                        <Typography
                                            style={{ fontWeight: 200, fontSize: "18px", marginTop: 0 }}
                                            variant={"pWhite"}
                                        >
                                            {vanityLinkData.freeformJson?.emailSubtitle}
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <>
                                    <IntraverseLogo src={IntraverseLetteringLogo} alt="Intraverse Logo Icon" />
                                    <Typography style={{ fontWeight: 500, fontSize: "22px" }} variant={"pWhite"}>
                                        {emailExists
                                            ? "Welcome back! Follow the instructions below to play intraverse!"
                                            : vanityLinkData.guestOnlyMode && !emailExists
                                              ? "Ready to play Intraverse? Start by entering your email."
                                              : "Ready to play Intraverse? Start by entering your email & select your password."}
                                    </Typography>
                                </>
                            )}
                            {!registerForm && (
                                <InputContainer>
                                    <Input
                                        variant="LoginInput"
                                        type="email"
                                        value={email.toLowerCase()}
                                        borderRadius="0px"
                                        onChangeHandler={handleEmailCheck}
                                        placeholder="Email"
                                        inputIcon={"ic:baseline-email"}
                                        inputIconColor={emailExists ? setColor.inputGreen : setColor.iconColor3}
                                        checkMarkIcon={emailExists && "carbon:checkmark-filled"}
                                    />
                                    {isEmailError && <p style={{ color: setColor.errorColor }}>{emailErrorMsg}</p>}
                                    {!vanityLinkData.guestOnlyMode && (
                                        <>
                                            <Input
                                                variant="LoginInput"
                                                borderRadius="0px"
                                                type="password"
                                                value={password}
                                                inputIcon={"ri:key-fill"}
                                                inputIconColor={setColor.iconColor3}
                                                onChangeHandler={(event) => {
                                                    setPassword(event.target.value);
                                                }}
                                                placeholder="Password"
                                            />
                                            {!emailExists && (
                                                <PasswordChecker
                                                    backgroundColor={"#0000007f"}
                                                    borderRadius={"2px"}
                                                    password={password}
                                                />
                                            )}
                                            {isPasswordError !== null && (
                                                <p style={{ color: setColor.errorColor }}>{passwordErrorMsg}</p>
                                            )}
                                        </>
                                    )}
                                    {/* EMAIL EXISTS and NORMAL LOGIN MODE */}
                                    {emailExists && !vanityLinkData.guestOnlyMode && (
                                        <>
                                            <p style={{ color: setColor.errorColor }}>{loginError}</p>
                                            <Button
                                                onClick={() => setIsFormSubmitted(true)}
                                                disabled={!(isValidEmail(email) && password.length > 0)}
                                                label="Jump In"
                                                variant="VanityLinkCustomizable"
                                            />
                                        </>
                                    )}
                                    {/* EMAIL DOESNT EXIST and NORMAL LOGIN MODE to avatar selection */}
                                    {!emailExists && !vanityLinkData.guestOnlyMode && (
                                        <>
                                            <CheckboxContainer style={{ margin: "10px 0" }}>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    <CheckBoxMain
                                                        checked={checkboxStatus}
                                                        onChange={() =>
                                                            setCheckboxStatus((checkboxStatus) => !checkboxStatus)
                                                        }
                                                    />
                                                    <Typography
                                                        style={{ fontSize: "16px", margin: "0.3rem 10px 0.3rem 16px" }}
                                                        variant={"pWhite"}
                                                        align={"left"}
                                                    >
                                                        I am at least 18 years old and I agree to Intraverse's{" "}
                                                        <TermsLink
                                                            href="https://terrazero.com/privacy-policy"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {" "}
                                                            Privacy Policy
                                                        </TermsLink>{" "}
                                                        and{" "}
                                                        <TermsLink
                                                            href="https://terrazero.com/privacy-policy"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {" "}
                                                            Terms & Conditions.
                                                        </TermsLink>
                                                    </Typography>
                                                </div>
                                            </CheckboxContainer>
                                            {vanityLinkData.marketingOptIn && (
                                                <CheckboxContainer>
                                                    <CheckBoxMain
                                                        checked={specialOffers}
                                                        onChange={() =>
                                                            setSpecialOffers((specialOffers) => !specialOffers)
                                                        }
                                                        labelTextRight="Recieve news, special offers, event information and feedback surveys by email from intraverse"
                                                    />
                                                </CheckboxContainer>
                                            )}
                                            {isCheckboxError && (
                                                <p style={{ color: setColor.errorColor }}>{checkboxErrorMsg}</p>
                                            )}
                                            <Button
                                                onClick={handleEmailData}
                                                variant={
                                                    passwordErrorMsg === "" && emailErrorMsg === "" && checkboxStatus
                                                        ? "VanityLinkCustomizable"
                                                        : "DisabledButton"
                                                }
                                                label="Continue to Register"
                                            />
                                        </>
                                    )}

                                    {/* EMAIL DOESNT EXIST and GUEST MODE ON to avatar selection */}
                                    {!emailExists && vanityLinkData.guestOnlyMode && (
                                        <>
                                            {vanityLinkData.marketingOptIn && (
                                                <CheckboxContainer>
                                                    <CheckBoxMain
                                                        checked={specialOffers}
                                                        onChange={() =>
                                                            setSpecialOffers((specialOffers) => !specialOffers)
                                                        }
                                                        labelTextRight="Recieve news, special offers, event information and feedback surveys by email from intraverse"
                                                    />
                                                </CheckboxContainer>
                                            )}
                                            <CheckboxContainer style={{ width: "100%", color: "white" }}>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    <CheckBoxMain
                                                        checked={checkboxStatus}
                                                        style={{ margin: "1rem" }}
                                                        onChange={() =>
                                                            setCheckboxStatus((checkboxStatus) => !checkboxStatus)
                                                        }
                                                    />
                                                    <Typography
                                                        style={{ fontSize: "16px", margin: "0.3rem 10px 0.3rem 16px" }}
                                                        variant={"pWhite"}
                                                        align={"left"}
                                                    >
                                                        I am at least 18 years old and I agree to Intraverse's{" "}
                                                        <TermsLink
                                                            href="https://terrazero.com/privacy-policy"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {" "}
                                                            Privacy Policy
                                                        </TermsLink>{" "}
                                                        and{" "}
                                                        <TermsLink
                                                            href="https://terrazero.com/privacy-policy"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {" "}
                                                            Terms & Conditions.
                                                        </TermsLink>
                                                    </Typography>
                                                </div>
                                            </CheckboxContainer>
                                            {isCheckboxError && (
                                                <p style={{ color: setColor.errorColor }}>{checkboxErrorMsg}</p>
                                            )}
                                            {loginError && <p style={{ color: setColor.errorColor }}>{loginError}</p>}
                                            {spotifyError && (
                                                <p style={{ color: setColor.errorColor, margin: "1rem 0" }}>
                                                    {spotifyError}
                                                </p>
                                            )}
                                            <Button
                                                id="avatar-selection-button"
                                                variant={
                                                    !checkboxStatus || !isValidEmail(email)
                                                        ? "DisabledButton"
                                                        : "VanityLinkCustomizable"
                                                }
                                                label="Continue to Avatar Selection"
                                                onClick={handleGuestUserData}
                                            />
                                            {/* NEED TO IMPLEMENT IN NEW TICKET:
                                            <Typography style={{ color: setColor.accent4 }} variant="UsernameSubtitle">
                                                I'm under 18 years old{' '}
                                            </Typography> */}
                                        </>
                                    )}
                                    {emailExists && vanityLinkData.guestOnlyMode && (
                                        <>
                                            <Button
                                                variant="VanityLinkCustomizable"
                                                disabled={!isValidEmail(email)}
                                                label="Get Login Link"
                                                onClick={() => sendAutoLoginEmail()}
                                            />
                                            {sendEmail && (
                                                <CheckboxContainer style={{ width: "100%", color: setColor.white }}>
                                                    <Typography
                                                        variant={"h2PurpleHeading"}
                                                        margin={"0.2rem"}
                                                        style={{ textAlign: "left", fontSize: "18px" }}
                                                    >
                                                        Check Your Inbox!
                                                    </Typography>
                                                    <br /> Click on the link we sent to this email to continue your
                                                    session in intraverse
                                                </CheckboxContainer>
                                            )}
                                        </>
                                    )}
                                    {vanityLinkData.googleLogin && (
                                        <>
                                            <img
                                                style={{ width: "100%", margin: "1rem 0" }}
                                                src={DividerDotted}
                                                alt="Divider Dotted"
                                            />
                                            <AuthSocial />
                                        </>
                                    )}
                                    {vanityLinkData.spotifyLogin && (
                                        <>
                                            <img
                                                style={{ width: "100%", margin: "1rem 0" }}
                                                src={DividerDotted}
                                                alt="Divider Dotted"
                                            />

                                            <VanitySpotify
                                                setSpotifyError={setSpotifyError}
                                                clientName={vanityLinkData.clientName}
                                                setEmail={setEmail}
                                                vanityLinkId={vanityLinkData.vanityLinkId}
                                                redirectUrl={process.env.REACT_APP_WEB_URL_LITTLE_BIG}
                                                setSpotifyNotPremiumAccount={props.setSpotifyNotPremiumAccount}
                                            />
                                        </>
                                    )}
                                </InputContainer>
                            )}

                            {registerForm && (
                                <InputContainer>
                                    <Input
                                        variant="LoginInput"
                                        type="name"
                                        borderRadius="0px"
                                        value={firstName}
                                        onChangeHandler={(e) => setFirstName(e.target.value)}
                                        placeholder="First Name"
                                    />
                                    <Input
                                        variant="LoginInput"
                                        borderRadius="0px"
                                        type="name"
                                        value={lastName}
                                        onChangeHandler={(e) => setLastName(e.target.value)}
                                        placeholder="Last Name"
                                    />
                                    {/* DO NOT REMOVE COMMENTED CODE - New ticket will add these inputs later
                                    <Input
                                        variant="LoginInput"
                                        borderRadius="0px"
                                        type="date"
                                        value={birthday}
                                        onChangeHandler={(e) => setBirthday(e.target.value)}
                                        placeholder="Birthday"
                                    /> */}
                                    {/* <GenderSelect value={gender} onChange={(e) => setGender(e.currentTarget.value)}>
                                        <option value="" disabled hidden>
                                            How do you identify?
                                        </option>
                                        {genderOptions.map((gender) => (
                                            <option key={gender} value={gender}>
                                                <p style={{ margin: '5px', width: '100px' }}> {gender}</p>
                                            </option>
                                        ))}
                                    </GenderSelect>
                                    <GenderSelect value={country} onChange={(e) => setCountry(e.target.value)}>
                                        <option value="" disabled hidden>
                                            Where do you live?
                                        </option>
                                        {stripeCountries.map((countryObj) => (
                                            <option key={countryObj.code} value={countryObj.country}>
                                                {countryObj.country}
                                            </option>
                                        ))}
                                    </GenderSelect> */}
                                    {isNameError && (
                                        <p style={{ color: setColor.errorColor, alignSelf: "flex-start" }}>
                                            {nameError}
                                        </p>
                                    )}
                                    <Button
                                        variant={
                                            !nameValidation(firstName, lastName) ||
                                            !firstName.length > 0 ||
                                            !lastName.length > 0
                                                ? "DisabledButton"
                                                : "VanityLinkCustomizable"
                                        }
                                        label="Continue to final step"
                                        onClick={handleRegistrationDataSubmit}
                                    />
                                    <Button
                                        onClick={() => {
                                            if (avatarExistsTrue) {
                                                setCurrentScreen("vanityRedirect");
                                            } else {
                                                setCurrentScreen("vanityAvatarScreen");
                                            }
                                            setRegisterForm(false);
                                        }}
                                        variant="VanityLinkCustomizable"
                                        label="Skip for now"
                                    />
                                </InputContainer>
                            )}
                        </EmailFormContainer>
                    )}
                    {!isLoading && currentScreen === "vanityAvatarScreen" && (
                        <VanityAvatarPage
                            setUsername={setUsername}
                            username={username}
                            setCurrentScreen={setCurrentScreen}
                            noUsername={noUsername}
                            vanityLink={pathId}
                            handleRegisterSubmit={handleRegisterSubmit}
                            onAvatarSelect={handleAvatarSelect}
                        />
                    )}
                </BackgroundContainer>
            )}
        </>
    );
};

VanityStartingPage.propTypes = {
    autoLogin: PropTypes.bool,
};

VanityStartingPage.defaultProps = {
    autoLogin: false,
};

export default VanityStartingPage;

const StyledPinkIntraverseLogoIcon = styled(PinkIntraverseLogoIcon)`
    height: 100px;
    @media (max-width: 960px) {
        height: 75px;
    }
`;

const Overlay = styled.div`
    background: ${setColor.containerGradient};
    backdrop-filter: blur(100px);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    @media (max-width: 960px) {
        height: 100vh;
        width: 100vw;
    }
`;
const CheckboxContainer = styled.div`
    background: ${setColor.purpleDark};
    padding: 1rem;
    margin: 0.3rem 0;
`;

const IntraverseLogo = styled.img`
    max-width: 400px;
    width: 70%;
    z-index: 1000;
`;

const EmailFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.guestOnlyMode ? "center" : "flex-start")};
    align-items: center;
    height: 80%;
    z-index: 1;

    @media (max-width: 960px) {
        width: 100%;
        justify-content: flex-start;
    }
`;

const StartingText = styled.h1`
    color: ${setColor.hightlight};
    text-align: center;
    /* -webkit-text-stroke: 3px rgba(27, 27, 35, 0.5); */
    font-family: ${setFont.barlow};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.6px;
    text-transform: uppercase;
    margin-top: 45px;

    @media (max-width: 960px) {
        font-size: 20px;
        margin-top: 25px;
    }
`;

const GetStartedContainer = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    flex-direction: column;
    width: 310px;
    height: 315px;
    flex-shrink: 0;

    border-radius: 10px;
    background: ${setColor.startedContainer};

    box-shadow: 0px 0px 60px 0px ${setColor.boxShadowContainer};
    backdrop-filter: blur(30px);

    @media (max-width: 960px) {
        margin-top: 55px;
        width: 220px;
        height: 205px;
    }
`;

const PartnershipBannerContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    width: auto;
    height: 75px;
    padding: 0 80px;
    background: ${setColor.startedContainer};
    box-shadow: 0px 0px 60px 0px ${setColor.boxShadowContainer};
    backdrop-filter: blur(30px);
    clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);

    @media (max-width: 960px) {
        height: 55px;
    }

    @media (max-width: 425px) {
        height: fit-content;
        width: 260px;
        top: 0px;
        padding: 0.5rem 0rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;
const IntraverseLogoBanner = styled.img`
    width: auto;
    height: 35px;

    @media (max-width: 960px) {
        height: 22px;
    }
`;
const PartnerLogo = styled.img`
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 35px;
    object-fit: fill;

    @media (max-width: 960px) {
        height: 20px;
    }

    @media (max-width: 425px) {
        margin: 0;
        height: 20px;
        width: auto;
    }
`;

const XIcon = styled.img`
    width: 15px;
    height: 15px;
    margin: 0 30px;

    @media (max-width: 960px) {
        margin: 0 20px;
    }

    @media (max-width: 425px) {
        margin: 10px 0;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 550px;

    @media (max-width: 960px) {
        width: 70vw;
        padding-bottom: 25px;
    }

    @media (max-width: 425px) {
        width: 85vw;
        padding-bottom: 25px;
    }
`;

const TermsLink = styled.a`
    color: ${setColor.spanTextColor};
    font-weight: 700;
    text-decoration: none;
`;

// UNCOMENT TO IMPLEMENT ADDED FIELDS IN NEW TICKET:
// const GenderSelect = styled.select`
//     flex: 1;
//     background: ${setColor.inputBgColor};
//     border: 2px solid ${setColor.loadingButtonBg};
//     border-radius: 0px;
//     outline: none;
//     padding: 0.5rem 1rem;
//     margin: 0.5rem 0rem;
//     font-size: 18px;
//     font-style: italic;
//     font-family: 'Barlow', sans-serif;
//     color: ${setColor.iconColor};
//     width: 100%;
//     height: 70px;
// `;
