import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Title from "../../components/Title";
import Body from "../../layout/Body";
import { getTablesSchema } from "../../../../api/AdminController";
import { setColor } from "../../../../assets/styles/styles";
import { DEFAULT_QUERY_OPTIONS } from "../../../../constants";

const Tables = () => {
    const {
        data: tablesData,
        error,
        isLoading,
    } = useQuery(["tables"], () => getTablesSchema(), {
        ...DEFAULT_QUERY_OPTIONS(),
    });

    // List of tables showed in admin
    const filteredTables = [
        "action_defitions",
        "avatar",
        "avatar_presets",
        "orders",
        "room_detail_images",
        "action_log",
        "npc_dialogue",
        "form_options",
        "users",
        "user_roles",
        "roles",
        "events_log",
        "room_settings",
        "vanity_link_data",
        "files",
        "form_questions",
        "forms",
        "rooms",
        "vanity_links",
    ];

    return (
        <>
            <Title
                title={"TABLES OVERVIEW"}
                description={
                    "Overview of tables schema Intraverse. Click on a table name to get more detailed information on the records."
                }
            />
            <Body isLoading={isLoading}>
                <GridTableContainer>
                    {tablesData?.data?.map((table, i) => {
                        if (!filteredTables.includes(table.table_name)) {
                            return <></>;
                        }
                        return (
                            <Link style={{ textDecoration: "none" }} to={`/console/table/${table.table_name}`}>
                                <Card key={`card-${i}-${table.table_name}`}>
                                    <p key={`table-${i}-${table.table_name}`}>{table.table_name}</p>
                                </Card>
                            </Link>
                        );
                    })}
                </GridTableContainer>
            </Body>
        </>
    );
};

const Card = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 6px;
    background-color: ${setColor.onHoverTextcolor2};
    cursor: pointer;
    color: ${setColor.inputText};
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
    &:hover {
        background-color: ${setColor.accent4};
        color: ${setColor.buttonTextBlack};
    }
`;

const GridTableContainer = styled.div`
    display: grid;
    align-self: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 22px;
    grid-gap: 10px;
    color: ${setColor.textPrimary};
`;

export default Tables;
