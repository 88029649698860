import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "@emotion/styled";
import { setColor } from "../../../../../assets/styles/styles";
import Typography from "../../../../../components/common/text/Typography";
import { getRooms, getRoomSettings, AddSubroomRelation, EditSubroomRelation } from "../api/roomController";
import { ReactComponent as DropdownIcon } from "../../../../../assets/images/icons/Chevron.svg";
import LoadingIcon from "../../../../../components/common/loading/LoadingIcon";
import { default as Button } from "../../../../../components/common/button/Button";
import Banner from "../../../../../components/layout/ui/Banner";
import { DEFAULT_QUERY_OPTIONS } from "../../../../../constants";

const AddEditSubroom = ({ roomId, type, selectedObject, closeModal, roomRelations }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredRooms, setFilteredRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState("");
    const [isDropdownOpenRoom, setIsDropdownOpenRoom] = useState(false);
    const [selectedRoomId, setSelectedRoomId] = useState("");
    const [roomSettingId, setRoomSettingId] = useState("");
    const [hideShowToggle, setHideShowToggle] = useState(false);
    const [successMessageBanner, setSuccessMessageBanner] = useState("");
    const [roomName, setRoomName] = useState(selectedObject.subroomRoomName);
    const [subroomRoomId, setSubroomRoomId] = useState(selectedObject.subroomRoomId);
    const [editSearchTerm, setEditSearchTerm] = useState("");
    const [filteredEditRooms, setFilteredEditRooms] = useState([]);
    const [selectedEditRoom, setSelectedEditRoom] = useState(selectedObject.subroomRoomName);
    const [isDropdownOpenEdit, setIsDropdownOpenEdit] = useState(false);
    const [editSelectedRoomId, setEditSelectedRoomId] = useState(selectedObject.subroomRoomId);
    const [editRoomSettingId, setEditRoomSettingId] = useState(selectedObject.subroomRoomSettingsId);
    const [editDisabled, setEditDisabled] = useState(true);
    const [editSuccessMessageBanner, setEditSuccessMessageBanner] = useState("");

    const AddSubroomToRoomClick = async () => {
        try {
            const response = await AddSubroomRelation(roomId, selectedRoomId, roomSettingId);
            if (response?.status === 201) {
                setIsLoading(false);
                setSuccessMessageBanner(`Subroom ${selectedRoomId} added to room successfully`);
                setSelectedRoomId("");
                setSelectedRoom("");
                setSearchTerm("");
                setRoomSettingId("");
                setHideShowToggle(false);
                setTimeout(() => {
                    setSuccessMessageBanner("");
                    closeModal();
                }, 5000);
            }
        } catch (error) {
            console.error("Error adding user to room:", error);
            setIsLoading(false);
        }
    };

    const EditSubroomToRoomClick = async () => {
        try {
            const response = await EditSubroomRelation(
                selectedObject.relationshipId,
                editSelectedRoomId,
                editRoomSettingId
            );
            if (response?.status === 200) {
                setEditSuccessMessageBanner(`Subroom ${editSelectedRoomId} edited to room successfully`);
                setEditDisabled(true);
                setTimeout(() => {
                    setEditSuccessMessageBanner("");
                    closeModal();
                }, 5000);
            }
        } catch (error) {
            console.error("Error adding user to room:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (successMessageBanner || editSuccessMessageBanner) {
            const timer = setTimeout(() => {
                closeModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner, editSuccessMessageBanner]);

    const {
        data: allRooms,
        error: allRoomsError,
        isLoading: allRoomsLoading,
        refetch: refetchRooms,
    } = useQuery(["allRooms"], () => getRooms(), {
        ...DEFAULT_QUERY_OPTIONS(500000),
    });

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        if (allRooms) {
            roomRelations.forEach((relation) => {
                allRooms.forEach((room) => {
                    if (relation.subroomRoomId === room.id) {
                        allRooms.splice(allRooms.indexOf(room), 1);
                    }
                });
            });
            const filteredRooms = allRooms.filter((room) =>
                room?.name?.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setFilteredRooms(filteredRooms);
            setIsDropdownOpenRoom(true);
        }
    };

    const handleRoomSelectClick = async (option) => {
        setSelectedRoomId(option.id);
        setIsDropdownOpenRoom(false);
        setSelectedRoom(option.name);
        setSearchTerm(option.name);
        const respSettingsData = await getRoomSettings(option.id);
        if (respSettingsData) {
            setRoomSettingId(respSettingsData);
        }
        setHideShowToggle(true);
    };

    const handleEditSearchTermChange = (e) => {
        setEditSearchTerm(e.target.value);
        if (allRooms) {
            roomRelations.forEach((relation) => {
                allRooms.forEach((room) => {
                    if (relation.subroomRoomId === room.id) {
                        allRooms.splice(allRooms.indexOf(room), 1);
                    }
                });
            });
            const filteredRooms = allRooms.filter((room) =>
                room?.name?.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setFilteredEditRooms(filteredRooms);
            setIsDropdownOpenEdit(true);
        }
    };

    const handleRoomEditSelectClick = async (option) => {
        setEditSelectedRoomId(option.id);
        setIsDropdownOpenEdit(false);
        setSelectedEditRoom(option.name);
        setEditSearchTerm(option.name);
        const respSettingsData = await getRoomSettings(option.id);
        if (respSettingsData) {
            setEditRoomSettingId(respSettingsData);
        }
        setEditDisabled(false);
    };

    return (
        <>
            <Banner
                width={"100%"}
                zIndex="1000"
                message={`${successMessageBanner || editSuccessMessageBanner}`}
                type={"SUCCESS"}
                openBanner={!!successMessageBanner || !!editSuccessMessageBanner}
                setOpenBanner={setSuccessMessageBanner || setEditSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={"0px"}
                left={"0"}
            />
            <ModalContent>
                <GeneralContainer>
                    <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
                        {type === "edit" ? "EDIT SUBROOM" : "ADD SUBROOM"}
                    </Typography>

                    {isLoading && (
                        <LoadingDiv>
                            <LoadingIcon />
                        </LoadingDiv>
                    )}

                    {!isLoading && (
                        <>
                            <Card>
                                <Table>
                                    <TableRow>
                                        <TableColumn>
                                            <Label>Parent Room Id</Label>
                                            <InputBox type="text" value={roomId} disabled />
                                        </TableColumn>
                                    </TableRow>

                                    {type === "add" && (
                                        <>
                                            <TableRow>
                                                <TableColumn>
                                                    <Label>Subroom Room Name</Label>
                                                    <InputBox
                                                        type="text"
                                                        placeholder="Type to search room"
                                                        value={searchTerm}
                                                        onChange={handleSearchTermChange}
                                                    />
                                                    {isDropdownOpenRoom && (
                                                        <DropdownContentUsers>
                                                            {filteredRooms.length > 0 ? (
                                                                filteredRooms.map((option) => (
                                                                    <DropdownItem
                                                                        key={option.id}
                                                                        onClick={() => handleRoomSelectClick(option)}
                                                                        isSelected={selectedRoom === option.name}
                                                                    >
                                                                        {option.name}
                                                                    </DropdownItem>
                                                                ))
                                                            ) : (
                                                                <MessageNote>No data match</MessageNote>
                                                            )}
                                                        </DropdownContentUsers>
                                                    )}
                                                </TableColumn>
                                            </TableRow>

                                            {hideShowToggle && (
                                                <>
                                                    <TableRow>
                                                        <TableColumn>
                                                            <Label>Subroom Room Id</Label>
                                                            <InputBox type="text" value={selectedRoomId} disabled />
                                                        </TableColumn>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableColumn>
                                                            <Label>Subroom Setting Id</Label>
                                                            <InputBox type="text" value={roomSettingId} disabled />
                                                        </TableColumn>
                                                    </TableRow>
                                                </>
                                            )}

                                            <TableRow>
                                                <Button
                                                    variant={"PinkButton"}
                                                    buttonWidth={"100%"}
                                                    margin={"20px 0 0 0"}
                                                    disabled={!selectedRoomId}
                                                    label={"Add Relation"}
                                                    onClick={AddSubroomToRoomClick}
                                                />
                                            </TableRow>
                                        </>
                                    )}

                                    {type === "edit" && (
                                        <>
                                            <TableRow>
                                                <TableColumn>
                                                    <Label>Search Room to Update Subroom and Subroom Setting</Label>
                                                    <InputBox
                                                        type="text"
                                                        placeholder="Type to search room"
                                                        value={editSearchTerm}
                                                        onChange={handleEditSearchTermChange}
                                                    />
                                                    {isDropdownOpenEdit && (
                                                        <DropdownContentUsers>
                                                            {filteredEditRooms.length > 0 ? (
                                                                filteredEditRooms.map((option) => (
                                                                    <DropdownItem
                                                                        key={option.id}
                                                                        onClick={() =>
                                                                            handleRoomEditSelectClick(option)
                                                                        }
                                                                        isSelected={selectedEditRoom === option.name}
                                                                    >
                                                                        {option.name}
                                                                    </DropdownItem>
                                                                ))
                                                            ) : (
                                                                <MessageNote>No data match</MessageNote>
                                                            )}
                                                        </DropdownContentUsers>
                                                    )}
                                                </TableColumn>
                                            </TableRow>

                                            <TableRow>
                                                <TableColumn>
                                                    <Label>Relationship Id</Label>
                                                    <InputBox
                                                        type="text"
                                                        value={selectedObject.relationshipId}
                                                        disabled
                                                    />
                                                </TableColumn>
                                            </TableRow>

                                            <TableRow>
                                                <TableColumn>
                                                    <Label>Subroom Room Id</Label>
                                                    <InputBox
                                                        type="text"
                                                        value={editSelectedRoomId}
                                                        disabled={editDisabled}
                                                    />
                                                </TableColumn>
                                            </TableRow>

                                            <TableRow>
                                                <TableColumn>
                                                    <Label>Subroom Room Name</Label>
                                                    <InputBox
                                                        type="text"
                                                        value={selectedEditRoom}
                                                        disabled={editDisabled}
                                                    />
                                                </TableColumn>
                                            </TableRow>

                                            <TableRow>
                                                <TableColumn>
                                                    <Label>Subroom Setting Id</Label>
                                                    <InputBox
                                                        type="text"
                                                        value={editRoomSettingId}
                                                        disabled={editDisabled}
                                                    />
                                                </TableColumn>
                                            </TableRow>

                                            <TableRow>
                                                <Button
                                                    variant={"PinkButton"}
                                                    buttonWidth={"100%"}
                                                    margin={"20px 0 0 0"}
                                                    disabled={editDisabled}
                                                    label={"Edit Relation"}
                                                    onClick={EditSubroomToRoomClick}
                                                />
                                            </TableRow>
                                        </>
                                    )}
                                </Table>
                            </Card>
                        </>
                    )}
                </GeneralContainer>
            </ModalContent>
        </>
    );
};

export default AddEditSubroom;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: "Barlow-Regular", Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const MessageNote = styled.div`
    font-size: 14px;
    color: ${setColor.inputText};
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const InputBox = styled.input`
    padding: 10px;
    border: ${(props) => (props.errorMessage ? `1px solid ${setColor.errorColor}` : `1px solid ${setColor.grey1}`)};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage ? "0.5rem" : "0rem")};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: "Barlow-Regular", Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : "transparent")};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContentUsers = styled.div`
    position: realtive;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: "Barlow-Regular", Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: "Barlow-Regular", Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;
