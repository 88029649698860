// NoteCreationStep1.js
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import NotesBackground from "./images/NotesBackground2.png";
import Crown from "./images/Crown.png";
import NextStepIcon from "./images/NextStepIcon.png";
import GreyNextIcon from "./images/GreyNextIcon.png";
import purpleX from "../../assets/images/buttonIcons/purpleX.png";
import Typography from "../../components/common/text/Typography";
import { setFont } from "../../assets/styles/styles";
import ToggleSwitchPink from "../../components/common/switchToggle/ToggleSwitchPink";
import { checkProfanityMessage } from "./api/notesController";

const NoteCreationStep1 = ({
    onNextStep,
    onCharacterCountChange,
    setCurrentStep,
    setIsCreatingNote,
    useEmailCreator,
    EmailArtist,
    initialMessage,
    updateMessage,
    isAnonymous,
    setIsAnonymous,
    roomAdminName,
    closeButton,
}) => {
    const [error, setError] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [message, setMessage] = useState(initialMessage);
    const [characterCount, setCharacterCount] = useState(initialMessage.length);
    const messageInputRef = useRef(null);
    const [focused, setFocused] = useState(false);

    const handleMessageChange = (e) => {
        const newMessage = e.target.value;
        const messageWithSingleSpaces = newMessage.replace(/\s+/g, " ").trim();
        setCharacterCount(messageWithSingleSpaces.length);
        if (newMessage.length <= 300) {
            setMessage(newMessage);
            updateMessage(newMessage);
            onCharacterCountChange(newMessage.length);
            setError(false);
            setSubmitError("");
        } else {
            setError(true);
        }
    };
    const handleMessageSubmit = async () => {
        let isProfane = false;
        try {
            const checkProfanityResponse = await checkProfanityMessage(message);
            if (checkProfanityResponse.status === 200) {
                isProfane = checkProfanityResponse?.data?.isProfane?.censored || false;
            }
        } catch (error) {
            console.error("Profanity check failed, defaulting to clean:", error);
            isProfane = false;
        }
        if (message.trim().length === 0) {
            setSubmitError("Please add a message! It cannot be empty.");
        } else if (isProfane) {
            setSubmitError("Inappropriate language used! Please change.");
        } else if (!useEmailCreator) {
            onNextStep(message);
        } else {
            EmailArtist(message);
        }
    };

    useEffect(() => {
        if (message.length !== 0) {
            setSubmitError("");
        }
        updateMessage(message);
    }, [message, updateMessage]);

    useEffect(() => {
        if (messageInputRef.current && !focused) {
            setTimeout(() => {
                messageInputRef.current.focus();
            }, 0);
        }
    }, [focused, message]);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    return (
        <NoteCreationContainer>
            <Banner>
                <Typography variant="guestbookHeader">Leave a Note</Typography>
                <NotesBox>
                    <Typography style={{ padding: "1rem" }} variant="guestbookHeader" color="#E4DEFF">
                        Step 1 of 2
                    </Typography>
                    <Button
                        onClick={() => {
                            closeButton();
                        }}
                    >
                        <StyledImage style={{ height: "35px" }} src={purpleX} alt="Close" />
                    </Button>
                </NotesBox>
            </Banner>
            {error && <ErrorBanner>Maximum character limit of 300 reached!</ErrorBanner>}
            <MessageBoxContainer>
                {!useEmailCreator && (
                    <Typography variant={"guestbookP"}>
                        Write a note in
                        <span style={{ color: "#58F5FF", fontWeight: "700" }}>
                            <img
                                src={Crown}
                                alt="crown"
                                style={{ margin: "-3px -2px -3px 12px", display: "inline", verticalAlign: "bottom" }}
                            />
                            {roomAdminName !== "" ? roomAdminName : "Owner"}
                        </span>
                        's guestbook for all visitors to read!
                    </Typography>
                )}
                {useEmailCreator && (
                    <Typography variant={"guestbookP"}>
                        Email your note directly to
                        <span style={{ color: "#58F5FF", fontWeight: "700" }}>
                            <img
                                src={Crown}
                                alt="crown"
                                style={{ margin: "-3px -2px -3px 12px", display: "inline", verticalAlign: "bottom" }}
                            />
                            {roomAdminName !== "" ? roomAdminName : "Owner"}
                        </span>
                    </Typography>
                )}
                <BannerLine />
                <Typography
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                    margin="0 0 5px 0"
                    FontWeight="700"
                    color="#FBFCFF"
                    variant={"guestbookP"}
                >
                    Message
                    <WordCount>{characterCount}/300</WordCount>
                </Typography>
                <MessageBox
                    ref={messageInputRef}
                    style={{
                        border: submitError !== "" ? "3px solid #FF8484" : "3px solid rgba(190, 174, 255, 0.2)",
                    }}
                    type="text"
                    placeholder="Start typing..."
                    value={message}
                    onChange={handleMessageChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {submitError !== "" && <ErrorMessage>{submitError}</ErrorMessage>}
                <AnonymousBox>
                    <ToggleSwitchPink value={isAnonymous} onChange={(e) => setIsAnonymous(!isAnonymous)} />
                    <Typography variant={"guestbookP"} style={{ margin: "20px 0px 25px 25px" }}>
                        Write my note <span style={{ color: "#FFE664", fontWeight: "600" }}> anonymously</span>
                    </Typography>
                </AnonymousBox>
                <NextButton onClick={handleMessageSubmit}>
                    <img
                        style={{ margin: "0 10px", height: "100px" }}
                        alt="NextStepIcon"
                        src={
                            submitError !== "" || characterCount === 0 || characterCount > 300
                                ? GreyNextIcon
                                : NextStepIcon
                        }
                    />
                </NextButton>
            </MessageBoxContainer>
        </NoteCreationContainer>
    );
};

export default NoteCreationStep1;

const NoteCreationContainer = styled.div`
    position: relative;
    padding: 0 80px 0 80px;
    background: linear-gradient(73deg, #11113f 1.32%, #210d35 96.92%);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    margin-bottom: 2rem;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.85);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(190, 174, 255, 0.5);
        border-radius: 6px;
    }
`;
const MessageBox = styled.textarea`
    width: 100%;
    height: 150px;
    margin: 10px 0;
    padding: 15px;
    font-size: 24px;
    background: rgba(27, 27, 35, 0.5);
    color: #fbfcff;
    font-family: ${setFont.barlow};
    resize: none;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-y: auto;

    &::placeholder {
        color: #9da0a7;
        font-family: ${setFont.barlow};
        font-size: 18px;
    }

    @media (max-width: 960px) {
        height: 120px;
        font-size: 18px;
    }
`;

const StyledImage = styled.img`
    @media (max-width: 960px) {
        width: 30px;
        height: auto;
    }
`;

const NotesBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const AnonymousBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(
        to right,
        rgba(190, 174, 255, 0) 0%,
        rgba(190, 174, 255, 0.2) 50%,
        rgba(190, 174, 255, 0) 100%
    );
    @media (max-width: 425px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const BannerLine = styled.div`
    width: 100%;
    height: 4px;
    background: linear-gradient(
        90deg,
        rgba(190, 174, 255, 0) 0%,
        rgba(190, 174, 255, 0.2) 50.78%,
        rgba(190, 174, 255, 0) 100%
    );
    margin: 2rem 0;
`;
const MessageBoxContainer = styled.div`
    margin-top: 100px;
    width: 85%;
`;

const WordCount = styled.div`
    color: #9da0a7;
    font-size: 20px;
`;

const Button = styled.button`
    padding: 1rem 2rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: white;
    border-left: 4px solid rgba(190, 174, 255, 0.2);
`;

const NextButton = styled.button`
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;

    @media (max-width: 960px) {
        bottom: 5px;
        right: 5px;
    }
`;

const ErrorMessage = styled.div`
    color: #ff8484;
    font-size: 14px;
    margin: 5px 0 10px 0;
`;
const ErrorBanner = styled.div`
    position: fixed;
    background-color: rgba(115, 106, 34, 0.9);
    width: 100%;
    height: fit-content;
    padding: 0.8rem 0px;
    text-align: center;
    font-size: 20px;
    margin-top: 70px;
    margin-right: 7px;
    z-index: 1000;
    color: white;
`;

const Banner = styled.div`
    width: 100%;
    height: fit-content;
    background: url(${NotesBackground}), linear-gradient(73deg, #11113f 1.32%, #210d35 96.92%);
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0rem 0rem 0rem 2rem;
`;
