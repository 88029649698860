import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { setColor } from "../../../../../assets/styles/styles";
import Typography from "../../../../../components/common/text/Typography";
import { ReactComponent as CopyIcon } from "../../../../../assets/images/icons/Copy.svg";

const DeleteMerchantModal = ({ url, closeModal }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <>
            <Typography variant="pWhite">
                <strong>{url}</strong>
                <CopyToClipboard text={url} onCopy={handleCopy}>
                    <StyledCopyIcon />
                </CopyToClipboard>
                {copied && <span style={{ color: "#beaeff", marginRight: "1rem" }}>Copied!</span>}
            </Typography>
        </>
    );
};

export default DeleteMerchantModal;

const StyledCopyIcon = styled(CopyIcon)`
    width: 15px;
    height: 15px;
    margin: 0.2rem 1rem 0 0.5rem;
    cursor: pointer;
    position: absolute;
`;
