import React, { useState } from "react";
import styled from "styled-components";
import { setColor, setFont } from "../../../assets/styles/styles";
import Typography from "../../../components/common/text/Typography";
import SquibblyRight from "../assets/graphics/UI_Website_Graphic_Squibbly_Like.png";
import SquibblyLeft from "../assets/graphics/UI_Website_Graphic_Squibbly_Poof.png";
import IntraverseLogo from "../assets/logos/IntraverseGrey.png";
import TZLogo from "../assets/logos/TZLogo.png";
// import Discord from '../assets/socialLogos/UI_Btn_Web_Discord.png';
// import Tiktok from '../assets/socialLogos/UI_Btn_Web_TikTok.png';
import Instagram from "../assets/socialLogos/UI_Btn_Web_Instagram.png";
import Share from "../assets/socialLogos/UI_Btn_Web_Share.png";
import LinkedIn from "../assets/socialLogos/UI_Btn_Web_Linkedin.png";
import Modal from "../../console/components/Modal";
import SocialShareModal from "./SocialShareModal";
import FB from "../assets/socialLogos/UI_Btn_Web_Facebook.png";
import SmallTZIcon from "../assets/logos/smallTZLogo.svg";

const FooterSection = ({ title, pro }) => {
    const [openShareModal, setOpenShareModal] = useState(false);

    return (
        <>
            <Modal isOpen={openShareModal} setIsOpen={setOpenShareModal} children={<SocialShareModal />} />
            <SectionBackground pro={pro}>
                <ImageLeft src={SquibblyLeft} />
                <ImageRight src={SquibblyRight} />
                <SectionContainer>
                    <Typography variant="LandingPageTitle" style={{ margin: "0", fontSize: "3.2rem" }}>
                        {title}
                    </Typography>
                    <FooterSocialButtons>
                        <a
                            style={{ textDecoration: "none" }}
                            href="https://www.linkedin.com/company/terrazerotech/mycompany/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <SocialLogoBlock>
                                <SocialLogo src={LinkedIn} alt="LinkedIn" />
                            </SocialLogoBlock>
                        </a>
                        <a
                            style={{ textDecoration: "none" }}
                            href="https://www.facebook.com/terrazerotech/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <SocialLogoBlock>
                                <SocialLogo src={FB} alt="Facebook" />
                            </SocialLogoBlock>
                        </a>
                        {/* <a
                            style={{ textDecoration: 'none' }}
                            href="https://www.instagram.com/terrazerotech/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <SocialLogoBlock>
                                <SocialLogo src={Discord} alt="Discord" />
                            </SocialLogoBlock>
                        </a> */}
                        <a
                            style={{ textDecoration: "none" }}
                            href="https://www.instagram.com/terrazerotech/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <SocialLogoBlock>
                                <SocialLogo src={Instagram} alt="Instagram" />
                            </SocialLogoBlock>
                        </a>
                        {/* <a
                            style={{ textDecoration: 'none' }}
                            href="https://twitter.com/terrazerotech/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <SocialLogoBlock>
                                <SocialLogo src={Tiktok} alt="TikTok" />
                            </SocialLogoBlock>
                        </a> */}
                        <SocialLogoBlock onClick={() => setOpenShareModal(true)}>
                            <SocialLogo src={Share} alt="Share" />
                        </SocialLogoBlock>
                    </FooterSocialButtons>
                    {pro && (
                        <BlogTextContainer>
                            <Typography variant="pWhite" color={"white"} style={{ margin: "0" }}>
                                Want more?{" "}
                                <a
                                    href={"https://terrazero.com/media"}
                                    style={{ textDecoration: "none" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Highlighted>Visit our blog</Highlighted>
                                </a>
                            </Typography>
                            <a
                                href={"https://terrazero.com/media"}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <img src={SmallTZIcon} alt="tz logo" style={{ width: "auto", height: "20px" }} />
                            </a>
                        </BlogTextContainer>
                    )}
                    <LogoTrademarkContainer>
                        <FooterLogoButtons>
                            <FooterLogo src={IntraverseLogo} alt="Intraverse Logo" />
                            <FooterLogo src={TZLogo} alt="TZ Logo" />
                        </FooterLogoButtons>
                        <PrivacyLine>
                            © {new Date().getFullYear()} TerraZero Technologies, Intraverse, and any associated logos
                            are trademarks, service marks, and/or registered trademarks of TerraZero Technologies.
                        </PrivacyLine>
                    </LogoTrademarkContainer>
                </SectionContainer>
            </SectionBackground>
        </>
    );
};

export default FooterSection;

const BlogTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Highlighted = styled.span`
    color: ${setColor.accent4};
    font-weight: 800;
    pointer: cursor;
`;

const SectionContainer = styled.div`
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @media (max-width: 960px) {
        width: 90%;
        height: auto;
        gap: 50px;
    }
`;

const SectionBackground = styled.div`
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    background: ${setColor.darkPurpleBackground};
    position: relative;

    @media (max-width: 960px) {
        height: 100vh;
    }
`;

const ImageLeft = styled.img`
    width: auto;
    height: 375px;
    bottom: 125px;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;

    @media (max-width: 960px) {
        display: none;
    }
`;

const ImageRight = styled.img`
    width: auto;
    height: 375px;
    position: absolute;
    z-index: 1;
    right: 50px;
    top: 0;

    @media (max-width: 960px) {
        height: 225px;
        right: 0;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const FooterSocialButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;

    @media (max-width: 960px) {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
`;
const FooterLogoButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 25px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const PrivacyLine = styled.p`
    color: ${setColor.circularProgress};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 10px;
    font-weight: 400;

    @media (max-width: 768px) {
        font-size: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
        margin: 0rem 2rem;
        width: 80%;
    }
`;

const FooterLogo = styled.img`
    height: 25px;
    width: auto;
`;

const SocialLogo = styled.img`
    height: 70px;
    cursor: pointer;

    @media (min-width: 768px) and (max-width: 1024px) {
        height: 60px;
        margin: 1rem;
        margin: 1rem 0.5rem;
    }
`;

const LogoTrademarkContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const SocialLogoBlock = styled.div`
    background: ${setColor.accent4}1a;
    border-radius: 20px;
    border: 2px solid rgba(190, 174, 255, 0.3);
`;
