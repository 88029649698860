import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CornerIconButton from "../../../components/common/button/CornerIconButton";
import BroadCast from "../../broadcasting/BroadCast";
import AmbientSound from "../../ambientSound/AmbientSound";
import { isIOSDevice } from "../../../utils/generalUtils";

const VanityFeatures = (props) => {
    const pathId = window.location.pathname.split("/").pop();
    const isIOSMobile = isIOSDevice();
    const [participant, setParticipant] = useState("");
    const [pauseMusic, setPauseMusic] = useState(false);
    const [broadCaster, setBroadCaster] = useState(false);
    return (
        <>
            {props.vanityLinkInfo?.ambientTrack && (
                <AmbientSound
                    audio={"AmbientSong"}
                    play={!(participant || broadCaster || pauseMusic)}
                    source={props.vanityLinkInfo?.ambientTrack}
                />
            )}
            {props.vanityLinkInfo?.broadcast && (
                <BroadCast
                    openBroadcastModal={props.openBroadcastModal}
                    setOpenBroadcastModal={props.setOpenBroadcastModal}
                    participant={participant}
                    setParticipant={setParticipant}
                    broadCaster={broadCaster}
                    setBroadCaster={setBroadCaster}
                    roomName={props.vanityLinkInfo?.clientName}
                />
            )}
            {props.showCornerButtons && !props.openBroadcastModal && (
                <CornerIconContainer landscape={props.landscape}>
                    {!isIOSMobile && pathId && (
                        <CornerIconButton
                            iconSize={"250%"}
                            mobileSize={"150%"}
                            showActiveIcon={props.fullScreen}
                            iconColor="white"
                            activeIcon="iconamoon:screen-normal"
                            unActiveIcon="iconamoon:screen-full-bold"
                            func={() => {
                                props.setFullScreen((value) => !value);
                            }}
                        />
                    )}
                    {!broadCaster && !participant && props.vanityLinkInfo?.ambientTrack && (
                        <CornerIconButton
                            iconSize={"250%"}
                            mobileSize={"150%"}
                            iconColor="white"
                            activeIcon="clarity:volume-up-solid"
                            unActiveIcon="clarity:volume-mute-solid"
                            func={() => {
                                setPauseMusic((value) => !value);
                            }}
                        />
                    )}
                </CornerIconContainer>
            )}
        </>
    );
};

VanityFeatures.propTypes = {
    vanityLinkInfo: PropTypes.object.isRequired,
    showCornerButtons: PropTypes.bool.isRequired,
    openBroadcastModal: PropTypes.bool.isRequired,
    setOpenBroadcastModal: PropTypes.func.isRequired,
    landscape: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    setFullScreen: PropTypes.func.isRequired,
};

export default VanityFeatures;

const CornerIconContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: ${(props) => (props.landscape ? "row" : "column")};
    position: fixed;
    z-index: 1000;
    transform: ${(props) => (props.landscape ? "rotate(-180deg)" : "none")};
    ${(props) => (props.landscape ? "top: 0; left: 0;" : "top: 0; right: 0;")}
`;
