import React, { useState } from "react";
import styled from "@emotion/styled";
import { setColor } from "../../../../../../assets/styles/styles";
import LoadingIcon from "../../../../../../components/common/loading/LoadingIcon";
import EditQuestion from "./EditQuestion";
import EditOption from "./EditOption";

const EditModal = ({ surveyId, requestType, closeEditModal, data, questionId, formQuestionTypes }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            {isLoading && (
                <LoadingDiv>
                    <LoadingIcon />
                </LoadingDiv>
            )}

            {!isLoading && requestType === "question" && (
                <ModalContent>
                    <EditQuestion
                        data={data}
                        surveyId={surveyId}
                        closeEditModal={closeEditModal}
                        formQuestionTypes={formQuestionTypes}
                    />
                </ModalContent>
            )}

            {!isLoading && requestType === "option" && (
                <ModalContent>
                    <EditOption
                        data={data}
                        questionId={questionId}
                        surveyId={surveyId}
                        closeEditModal={closeEditModal}
                    />
                </ModalContent>
            )}
        </>
    );
};

export default EditModal;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 292px); // 292px is the height of the nav bar + title component combined
    height: 100%;
    margin: 0;
`;
