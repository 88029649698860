import React, { useState } from "react";
import styled from "styled-components";
import CreateIcon from "@mui/icons-material/Create";
import { setColor } from "../../../../../assets/styles/styles";
import Modal from "../../../components/Modal";
import MediaUploader from "./MediaUploader";
import defaultImage from "../images/default_main.png";

const PreviewSection = (props) => {
    const { selectedFrame, setSuccessBannerMessage } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!selectedFrame) {
        return <></>;
    }

    return (
        <Section>
            <Modal
                isOpen={isOpen}
                setIsOpen={handleClose}
                children={
                    <MediaUploader
                        setSuccessBannerMessage={setSuccessBannerMessage}
                        setIsOpen={setIsOpen}
                        frame={selectedFrame}
                        token={props.token}
                        userId={props.userId}
                    />
                }
            />
            <UserConsoleText style={{ marginTop: "2rem" }}>{selectedFrame.displayName}</UserConsoleText>
            <SmallSubtitle>
                {selectedFrame.frameType !== "video"
                    ? `Click the card below to update how your space is presented in the
                Intraport.`
                    : "Click the card below to update the video frame located on the second floor of your space."}
            </SmallSubtitle>
            <InputContainer>
                {selectedFrame.frameType !== "video" && (
                    <InputImageDivHolder
                        imageUrl={(() => {
                            if (selectedFrame.isMain) {
                                if (selectedFrame.externalUrl) {
                                    return selectedFrame.externalUrl;
                                }
                                if (selectedFrame.s3Url) {
                                    return selectedFrame.s3Url;
                                }
                            }
                            if (selectedFrame.frameType === "image") {
                                if (selectedFrame.fileUrl) {
                                    return selectedFrame.fileUrl;
                                }
                                if (selectedFrame.externalUrl) {
                                    return selectedFrame.externalUrl;
                                }
                            }
                        })()}
                    />
                )}

                <Input
                    disabled
                    onClick={() => {}}
                    type="text"
                    placeholder={(() => {
                        if (selectedFrame.externalUrl) {
                            return selectedFrame.externalUrl;
                        }
                        if (selectedFrame.fileName) {
                            return selectedFrame.fileName;
                        }
                        return "";
                    })()}
                />
                <CreateIcon
                    onClick={() => {
                        setIsOpen(true);
                    }}
                    sx={{ "&:hover": { color: setColor.iconColor3 }, margin: "5px", color: "grey" }}
                />
            </InputContainer>
            <ImageContainer
                imageUrl={(() => {
                    if (selectedFrame.isMain) {
                        return defaultImage;
                    }
                    return selectedFrame.exampleImageUrl;
                })()}
            />
        </Section>
    );
};

const Section = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 60%;
    border-left: 1px solid rgb(80, 76, 92);
    padding: 50px;

    @media (max-width: 960px) {
        width: 100%;
        border-left: none;
        padding: 15px;
    }
`;

const InputImageDivHolder = styled.div`
    width: 60px;
    height: 100%;
    border-radius: 5px 0px 0px 5px;
    background-image: url(${(props) => props.imageUrl});
    background-size: cover;
    background-position: center;
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 450px;
    margin-top: 25px;
    background-image: url(${(props) => props.imageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 960px) {
        background-size: contain;
        background-position: top;
    }
`;

const UserConsoleText = styled.h1`
    text-align: left;
    color: ${setColor.iconColor3};
    font-family: "Barlow Condensed", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 1rem 0rem;

    @media (max-width: 960px) {
        text-align: center;
        font-align: right;
        font-size: 30px;
    }
`;

const SmallSubtitle = styled.p`
    color: ${setColor.circularProgress};
    font-family: "Barlow", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;

    & > span {
        color: ${setColor.iconColor3};
        font-weight: 700;
    }

    @media (max-width: 960px) {
        text-align: center;
        font-size: 12px;
        margin-top: 0.5rem;
    }
`;

const InputContainer = styled.div`
    display: flex;
    border-radius: 10px;
    justify-content: center;
    background: ${setColor.lightPurpleInput};
    cursor: pointer;
    height: 64px;
    align-items: center;

    @media (max-width: 768px) {
        margin: 5px;
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin: 5px;
        width: 100%;
    }
`;

const Input = styled.input`
    flex: 1;
    border: none;
    background: none;
    width: 90%;
    height: 64px;
    color: white;
    margin-left: 8px;
    font-size: 20px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px rgb(35, 34, 48) inset !important;
        -webkit-text-fill-color: white !important;
    }

    &::placeholder {
        color: #c4c4c4;
    }

    @media (max-width: 960px) {
        font-size: 12px;
    }
`;

export default PreviewSection;
