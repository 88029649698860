import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { setColor } from "../../../../../assets/styles/styles";
import Typography from "../../../../../components/common/text/Typography";
import { getAllUsers, getAllRoles, addUserToRoom } from "../api/myRoomsController";
import { ReactComponent as DropdownIcon } from "../../../../../assets/images/icons/Chevron.svg";
import LoadingIcon from "../../../../../components/common/loading/LoadingIcon";
import { default as Button } from "../../../../../components/common/button/Button";
import Banner from "../../../../../components/layout/ui/Banner";

const AddUsersToMyRooms = ({ selectedObject, closeModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [isDropdownOpenUser, setIsDropdownOpenUser] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [displayRole, setDisplayRole] = useState("Select Role");
    const [selectedUserId, setSelectedUserId] = useState("");
    const [successMessageBanner, setSuccessMessageBanner] = useState("");
    const [userAlreadyInRoomMessage, setUserAlreadyInRoomMessage] = useState("");
    const [roomUsers, setRoomUsers] = useState([]);

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await getAllUsers();
            if (response.status === 200) {
                const data = response.data.data;
                setAllUsers(data);
            }
            if (response.status !== 200) {
                setAllUsers([]);
            }

            const responseRoles = await getAllRoles();
            if (responseRoles.status === 200) {
                const data = responseRoles.data.roles;
                setAllRoles(data);
            }
            if (responseRoles.status !== 200) {
                setAllRoles([]);
            }

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUserClick = (option) => {
        setSelectedUserId(option.userId);
        setIsDropdownOpenUser(false);
        setSelectedUser(option.email);
        setSearchTerm(option.email);
        // match user already in room check with roomUsers
        const userAlreadyInRoom = roomUsers.find((user) => user.userId === option.userId);
        if (userAlreadyInRoom) {
            setUserAlreadyInRoomMessage(
                `User already in room with role "${userAlreadyInRoom.roleName}".  Please select another user to continue.`
            );
        } else {
            setUserAlreadyInRoomMessage("");
        }
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        const filtered = allUsers.filter((user) => user.email.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredUsers(filtered);
        setIsDropdownOpenUser(true);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleRoleClick = (option) => {
        setIsDropdownOpen(false);
        setSelectedRole(option.id);
        setDisplayRole(option.name);
    };

    const AddUserToRoomClick = async () => {
        try {
            setIsLoading(true);
            const response = await addUserToRoom(selectedObject.roomId, selectedUserId, selectedRole);
            if (response.status === 200) {
                setSuccessMessageBanner("User added to room successfully");
                setSelectedUser("");
                setSelectedRole("");
                setUserAlreadyInRoomMessage("");
            }
            if (response.status !== 200) {
                console.error("Error adding user to room");
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error adding user to room:", error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <Banner
                width={"100%"}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={"SUCCESS"}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={"0px"}
                left={"0"}
            />
            <ModalContent>
                <GeneralContainer>
                    <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
                        {selectedObject.roomName
                            ? `Add Users to Room: ${selectedObject.roomName}`
                            : "Add Users to Room"}
                    </Typography>

                    {isLoading && (
                        <LoadingDiv>
                            <LoadingIcon />
                        </LoadingDiv>
                    )}

                    {!isLoading && (
                        <>
                            <Card>
                                <Table>
                                    <TableRow>
                                        <TableColumn>
                                            <Label>User</Label>
                                            <InputBox
                                                type="text"
                                                placeholder="Type to search user email"
                                                value={searchTerm}
                                                onChange={handleSearchTermChange}
                                                errorMessage={userAlreadyInRoomMessage}
                                            />
                                            {isDropdownOpenUser && (
                                                <DropdownContentUsers>
                                                    {filteredUsers.map((option) => (
                                                        <DropdownItem
                                                            key={option.userId}
                                                            onClick={() => handleUserClick(option)}
                                                            isSelected={selectedUser === option.email}
                                                        >
                                                            {option.email}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownContentUsers>
                                            )}
                                            {userAlreadyInRoomMessage && (
                                                <MessageDesc>{userAlreadyInRoomMessage}</MessageDesc>
                                            )}
                                        </TableColumn>
                                    </TableRow>

                                    <TableRow>
                                        <TableColumn>
                                            <Label>Role</Label>
                                            <DropdownButton onClick={toggleDropdown} isopen={isDropdownOpen.toString()}>
                                                <TitleText>{displayRole}</TitleText>

                                                <StyledDropdownIcon isopen={isDropdownOpen.toString()} />
                                                {isDropdownOpen && (
                                                    <DropdownContent>
                                                        {allRoles.map((option) => (
                                                            <DropdownItem
                                                                key={option.id}
                                                                onClick={() => handleRoleClick(option)}
                                                                isSelected={selectedRole === option.id}
                                                            >
                                                                {option.name}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownContent>
                                                )}
                                            </DropdownButton>
                                        </TableColumn>
                                    </TableRow>

                                    <TableRow>
                                        <Button
                                            variant={"PinkButton"}
                                            buttonWidth={"100%"}
                                            disabled={
                                                selectedUser === "" || selectedRole === "" || userAlreadyInRoomMessage
                                            }
                                            label={"Add User to Room"}
                                            onClick={AddUserToRoomClick}
                                        />
                                    </TableRow>
                                </Table>
                            </Card>
                        </>
                    )}
                </GeneralContainer>
            </ModalContent>
        </>
    );
};

export default AddUsersToMyRooms;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: "Barlow-Regular", Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const InputBox = styled.input`
    padding: 10px;
    border: ${(props) => (props.errorMessage ? `1px solid ${setColor.errorColor}` : `1px solid ${setColor.grey1}`)};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage ? "0.5rem" : "0rem")};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const DropdownButton = styled.button`
    display: flex;
    font-family: "Barlow-Regular", Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? "1rem" : "0rem")};
    position: relative;
    text-align: left;
    border: ${(props) => (props.isopen === "true" ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`)};
    &:disabled {
        background-color: ${setColor.grey1};
    }

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === "true" ? "rotate(180deg)" : "rotate(0deg)")};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: "Barlow-Regular", Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : "transparent")};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContentUsers = styled.div`
    position: realtive;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: "Barlow-Regular", Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: "Barlow-Regular", Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;

const TitleText = styled.p`
    font-family: "Barlow-Regular", Helvetica;
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => (props.isSelected ? `#767676` : `${setColor.inputText}`)};
`;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
`;
