import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { API_GET } from "../../../../config/axiosConfig";
import { setColor } from "../../../../assets/styles/styles";
import Title from "../../components/Title";
import Body from "../../layout/Body";
import { formatDateTimeWithTimeZone } from "../../../../utils/generalUtils";
import { ReactComponent as DropdownIcon } from "../../../../assets/images/icons/Chevron.svg";
import Pagination from "./components/Pagination";
import MerchantsTable from "./components/MerchantsTable";
import Banner from "../../../../components/layout/ui/Banner";

const MerchantsPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [totalNumber, setTotalNumber] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");

    const fetchData = async (url, page) => {
        try {
            setIsLoading(true);
            const response = await API_GET(url);

            const merchantsCardsData = [];

            if (response.data.merchants) {
                for (let i = 0; i < response.data.merchants.length; i += 1) {
                    const createdDateTime = formatDateTimeWithTimeZone(response.data.merchants[i].createdAt);
                    const updatedDateTime = formatDateTimeWithTimeZone(response.data.merchants[i].updatedAt);
                    const status =
                        response.data.merchants[i].status !== undefined ? response.data.merchants[i].status : null;
                    const street = response.data.merchants[i].street || "";
                    const unitNumber = response.data.merchants[i].unitNumber || "";
                    const city = response.data.merchants[i].city || "";
                    const state = response.data.merchants[i].state || "";
                    const postalCode = response.data.merchants[i].postalCode || "";

                    const addressParts = [street, unitNumber, city, state, postalCode].filter((part) => !!part.trim());
                    const formattedAddress = addressParts.join(", ");

                    merchantsCardsData.push({
                        merchantName: response.data.merchants[i].merchantName,
                        merchantId: response.data.merchants[i].merchantId,
                        merchantEmail: response.data.merchants[i].merchantContactEmail,
                        merchantAddress: formattedAddress,
                        stripeClientId: response.data.merchants[i].stripeClientId,
                        businessType: response.data.merchants[i].merchantBusinessType,
                        createdDateTime,
                        updatedDateTime,
                        status,
                    });
                }
                merchantsCardsData.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
                setTotalNumber(response.data.totalMerchantsCount);
                setTableData(merchantsCardsData);
                setFilteredTableData(merchantsCardsData);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setTotalNumber(0);
            setTableData([]);
            setFilteredTableData([]);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/merchant`);
    }, []);

    return (
        <>
            <Title
                title={"MERCHANTS"}
                description={"Manage all merchants: View, edit details, and toggle account status. "}
            />

            <Body isLoading={isLoading}>
                <>
                    <MerchantsTable merchantsData={filteredTableData} />
                </>
            </Body>
            <Banner
                width={"100%"}
                zIndex="1000"
                message={`${successMessage}`}
                type={"SUCCESS"}
                openBanner={!!successMessage}
                setOpenBanner={setSuccessMessage}
                topDisplay
                showCloseButton
                gameScreen={false}
                timer={8000}
                top={"78px"}
                left={"0"}
            />
        </>
    );
};

export default MerchantsPage;

const DropdownContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: TRANSPARENT;
    color: ${setColor.inputText};
    margin-left: auto;
    margin-top: 1rem;
`;
