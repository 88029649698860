import { API_POST } from "../../../config/axiosConfig";

export async function getBroadcastingPermissions(roomName, password) {
    try {
        const response = await API_POST("/web/admin/broadcast-permissions", { roomName, password });
        if (response.status === 200) {
            return response.data;
        }

        return null;
    } catch (error) {
        console.error("Error during broadcast permissions:", error);
        return null;
    }
}

export async function getBroadcastingToken(roomName) {
    try {
        const response = await API_POST("/web/admin/broadcast-permissions", { roomName });

        if (response.status === 200) {
            return response.data.token;
        }

        return null;
    } catch (error) {
        console.error("Error during fetching broadcast permissions token:", error);
        return null;
    }
}
