import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import styled from "styled-components";
import { setColor } from "../../../assets/styles/styles";
import { ReactComponent as DropdownIcon } from "../../../assets/images/icons/Chevron.svg";

const Dropdown = ({ label, items, selectedValue, setSelectedValue, placeholder, tags, removeTags }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleClick = (e, option) => {
        e.preventDefault();
        setSelectedValue(tags ? option : option.value);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <DropdownContainer ref={dropdownRef}>
            {label && <Label>{label}</Label>}
            <DropdownButton type="button" onClick={() => setIsDropdownOpen((prev) => !prev)} isopen={isDropdownOpen}>
                {tags ? (
                    <TitleText isSelected={selectedValue.length === 0}>
                        {selectedValue.length === 0 ? placeholder : ""}
                    </TitleText>
                ) : (
                    <TitleText isSelected={selectedValue === ""}>
                        {selectedValue === "" ? placeholder : selectedValue}
                    </TitleText>
                )}
                {tags && selectedValue.length !== 0 && (
                    <SelectedTags>
                        {selectedValue?.map((tag) => (
                            <TagCard key={tag.id}>
                                {tag.value}
                                <RemoveTagButton onClick={() => removeTags(tag)}>X</RemoveTagButton>
                            </TagCard>
                        ))}
                    </SelectedTags>
                )}
                <StyledDropdownIcon isopen={isDropdownOpen} />
            </DropdownButton>
            {isDropdownOpen && (
                <DropdownContent>
                    {items?.map((option) => (
                        <DropdownItem
                            key={option?.id ?? uniqueId()}
                            onClick={(e) => handleClick(e, option)}
                            isSelected={
                                tags
                                    ? selectedValue.some((tag) => tag.id === option.id)
                                    : selectedValue === option.value
                            }
                        >
                            {option.value}
                        </DropdownItem>
                    ))}
                </DropdownContent>
            )}
        </DropdownContainer>
    );
};

Dropdown.defaultProps = {
    tags: false,
};

Dropdown.propTypes = {
    label: PropTypes.string,
    items: PropTypes.node,
    selectedValue: PropTypes.func,
    setSelectedValue: PropTypes.func,
    placeholder: PropTypes.string,
    tags: PropTypes.bool,
    removeTags: PropTypes.func,
};

export default Dropdown;

const DropdownContainer = styled.div`
    position: relative;
    width: 100%;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const DropdownButton = styled.button`
    cursor: pointer;
    display: flex;
    font-family: "Barlow-Regular", Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? "1rem" : "0rem")};
    position: relative;
    text-align: left;
    border: ${(props) => (props.isopen ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`)};
    &:disabled {
        background-color: ${setColor.grey1};
    }

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const TitleText = styled.p`
    font-family: "Barlow-Regular", Helvetica;
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => (props.isSelected ? `#767676` : `${setColor.inputText}`)};
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const DropdownContent = styled.div`
    position: absolute;
    height: auto;
    max-height: 175px;
    overflow-y: auto;
    right: 0;
    padding: 5px;
    font-family: "Barlow-Regular", Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: "Barlow-Regular", Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : "transparent")};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const SelectedTags = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
    color: white;
`;

const TagCard = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: ${setColor.accent4};
    border-radius: 4px;
`;

const RemoveTagButton = styled.button`
    background: none;
    border: none;
    margin-left: 2px;
    cursor: pointer;
    color: black;
`;
