import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { fetchUserToken } from "../../../../redux/slices/users";
import { DeleteUser } from "../../../../redux/actions/UserActions";
import Title from "../../components/Title";
import ImageCrop from "./ImageCrop";
import FileUploader from "../../../../components/common/fileUploader/FileUploader";
import Button from "../../../../components/common/button/Button";

const ImageEditor = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [processedImage, setProcessedImage] = useState(null);

    const token = useSelector(fetchUserToken);

    const handleFileChange = (event) => {
        setSelectedFile(null);
        setProcessedImage(null);
        setSelectedFile(event.target.files[0]);
        setBtnDisabled(false);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append("image", selectedFile, selectedFile.name);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_CORE_API_URI}/web/image_editor/compress_image`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "blob",
                }
            );

            if (response.status !== 200) {
                DeleteUser();
            }

            if (response.status === 200) {
                const imageBlob = await new Blob([response.data], { type: "image/jpeg" });
                const imageUrl = await URL.createObjectURL(imageBlob);
                setProcessedImage(imageUrl);
            }
        } catch (error) {
            return error;
        }
    };

    return (
        <ImageCompressorContainer>
            <Title
                title={"Image Compressor"}
                description={"Select image and click upload to compress it"}
                margin={"0 0 1rem 0"}
            />

            <FileUploader handleFileChange={handleFileChange} handleUpload={handleUpload} btnDisabled={btnDisabled} />

            {processedImage && (
                <div>
                    {!processedImage ? (
                        <></>
                    ) : (
                        <PreviewImageContainer>
                            <img src={processedImage} alt="Processed" style={{ maxWidth: "50%", height: "auto" }} />
                            <a href={processedImage} download="compressed_image.jpeg">
                                <Button variant={"PinkButton"} label={"Download Image"} />
                            </a>
                        </PreviewImageContainer>
                    )}
                </div>
            )}
            <Title
                title={"Image Cropper"}
                description={"Select an image and use our cropping tool to scale it correctly."}
            />
            <ImageCrop />
        </ImageCompressorContainer>
    );
};

const ImageCompressorContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 70px;
`;

const PreviewImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
`;

export default ImageEditor;
