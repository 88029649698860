import React, { useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { getUserRoomPermission } from "../../api/RoomsController";
import LoadingIcon from "../../components/common/loading/LoadingIcon";
import { decryptBase64 } from "../../utils/generalUtils";

const WrapperCheckUserRoomPermission = ({ children }) => {
    const [isVerified, setIsVerified] = useState(null); // null indicates loading state
    const [token, setToken] = useState(null);
    const [searchParams] = useSearchParams();
    const encodedToken = searchParams.get("token");
    const roomId = searchParams.get("roomId");

    useEffect(() => {
        const CheckRoomPermissionAsync = async () => {
            if (encodedToken) {
                try {
                    const decodedToken = await decryptBase64(encodedToken);
                    setToken(decodedToken);
                    const isValid = await getUserRoomPermission(roomId, decodedToken);
                    setIsVerified(isValid);
                } catch (error) {
                    console.error("Token verification failed:", error);
                    setIsVerified(false); // Treat verification failure as invalid token
                }
            } else {
                setIsVerified(false); // No token found, so set verification to false
            }
        };

        CheckRoomPermissionAsync();
    }, [encodedToken]);

    if (isVerified === null) {
        return (
            <LoadingContainer>
                <LoadingIcon hover={false} />
            </LoadingContainer>
        );
    }

    if (isVerified === false) {
        return <Navigate to="/404" replace />;
    }

    return children({ token });
};

WrapperCheckUserRoomPermission.propTypes = {
    children: PropTypes.node,
};

export default WrapperCheckUserRoomPermission;

const LoadingContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
