import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { setColor } from "../../../../../assets/styles/styles";
import { default as Button } from "../../../../../components/common/button/Button";
import Typography from "../../../../../components/common/text/Typography";
import Banner from "../../../../../components/layout/ui/Banner";
import { deleteMerchant } from "../api/merchantController";
import LoadingIcon from "../../../../../components/common/loading/LoadingIcon";

const DeleteMerchantModal = ({ id, closeDeleteModal, merchantName }) => {
    const navigate = useNavigate();
    const [deleteText, setDeleteText] = useState("");
    const [deleteError, setDeleteError] = useState("");
    const [successMessageBanner, setSuccessMessageBanner] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteClick = async () => {
        if (deleteText.toLowerCase() === "delete") {
            try {
                setIsLoading(true);
                const response = await deleteMerchant(id);
                if (response.status === 204) {
                    setSuccessMessageBanner(
                        `Successfully deleted merchant ${id}, ${merchantName}. Redirecting to Merchants Table.`
                    );
                    setTimeout(() => {
                        navigate("/console/merchants");
                    }, 3000);
                }
            } catch (error) {
                setIsLoading(false);
                console.error("error", error);
                setDeleteError(
                    `Error deleting merchant ${id}, ${merchantName}. Please try again. Error: ${error.response.data.message}`
                );
            }
        } else {
            setDeleteError("Write DELETE to proceed.");
        }
        setDeleteText("");
    };

    useEffect(() => {
        if (deleteError) {
            const timer = setTimeout(() => {
                setDeleteError("");
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [deleteError]);

    return (
        <>
            {isLoading && (
                <LoadingDiv>
                    <LoadingIcon />
                    <Typography variant="pWhite" margin={"1rem"}>
                        Redirecting to Merchants Table...
                    </Typography>
                </LoadingDiv>
            )}

            <Banner
                width={"100%"}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={"SUCCESS"}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                timer={8000}
                top={"78px"}
                left={"0"}
            />
            {!isLoading && (
                <>
                    <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
                        Permanently Delete?
                    </Typography>

                    <Card>
                        <Typography variant="pWhite" margin={"0"}>
                            This action is <YellowText>permanent </YellowText> and{" "}
                            <YellowText> irreversible.</YellowText> Please type DELETE below to proceed.
                        </Typography>

                        {deleteError && <MessageDesc>{deleteError}</MessageDesc>}

                        <InputBox type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)} />

                        <Button
                            variant={"PinkButton"}
                            buttonWidth={"100%"}
                            disabled={deleteText.toLowerCase() !== "delete"}
                            label={"PERMANENTLY DELETE"}
                            margin={" 0  0 2rem 0"}
                            onClick={handleDeleteClick}
                        />
                        <Typography variant="GreyText" onClick={closeDeleteModal} margin={"0"}>
                            GO BACK
                        </Typography>
                    </Card>
                </>
            )}
        </>
    );
};

export default DeleteMerchantModal;

const Card = styled.div`
    height: auto;
    position: relative;
    padding: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const YellowText = styled.span`
    color: ${setColor.yellowText};
    font-weight: 700;
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 50px 0;
    height: 50%;
    width: 100%;
    background-color: transparent;
`;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
    margin: 0rem 0rem 1rem 2rem;
`;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 292px); // 292px is the height of the nav bar + title component combined
    height: 100%;
    margin: 0;
`;
