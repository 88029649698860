import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as CheckMarkIcon } from "../../../../../assets/images/icons/checkmark-icon.svg";
import { ReactComponent as WarningIcon } from "../../../../../assets/images/icons/WarningRed.svg";
import { ReactComponent as CopyIcon } from "../../../../../assets/images/icons/Copy.svg";
import { ReactComponent as NotFoundIcon } from "../../../../../assets/images/icons/NotFound.svg";
import { capitalizeString } from "../../../../../utils/generalUtils";
import Typography from "../../../../../components/common/text/Typography";
import { setColor, setFont } from "../../../../../assets/styles/styles";

const OrderDetailsModal = ({ row }) => {
    const [copied, setCopied] = useState(false);

    const capitalizedStringName = capitalizeString(row.billingName);
    const capitalizedStringVendor = capitalizeString(row.vendor);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const shortOrderId = row.orderId.substring(0, 8);

    return (
        <ModalContent>
            <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
                Order Details
            </Typography>

            {row.status === "succeeded" ? (
                <SuccessContainer>
                    <StyledCheckMarkIcon />
                    Order Successfully Processed
                </SuccessContainer>
            ) : (
                <FailedContainer>
                    <StyledWarningIcon />
                    Order Processing Failed - {row.reason}
                </FailedContainer>
            )}

            <GeneralContainer>
                <Card>
                    <OrderDetails>General Details</OrderDetails>
                    <Table>
                        <TableRow>
                            <TableColumn>
                                <strong>Order ID:</strong> {shortOrderId}{" "}
                                <CopyToClipboard text={row.orderId} onCopy={handleCopy}>
                                    <StyledCopyIcon />
                                </CopyToClipboard>
                                {copied && <span style={{ color: "#beaeff" }}>Copied!</span>}
                            </TableColumn>
                            <TableColumn>
                                <strong>Date:</strong> {row.displayOrderDate}
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>
                                <strong>Vendor:</strong> {capitalizedStringVendor}
                            </TableColumn>
                            <TableColumn>
                                <strong>Paid With:</strong>{" "}
                                {row.cardBrand
                                    ? row.cardBrand.charAt(0).toUpperCase() + row.cardBrand.slice(1)
                                    : "Card"}
                            </TableColumn>
                        </TableRow>
                    </Table>
                </Card>
            </GeneralContainer>

            {row.cardAddress && capitalizedStringName && (
                <GeneralContainer>
                    <Card>
                        <OrderDetails>Shipping Address</OrderDetails>
                        <Table>
                            <TableRow>
                                <TableColumn>
                                    <strong>{capitalizedStringName}</strong>
                                </TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableColumn>
                                    <Address>{row.cardAddress}</Address>
                                </TableColumn>
                            </TableRow>
                        </Table>
                    </Card>
                </GeneralContainer>
            )}

            <GeneralContainer>
                <Card>
                    <OrderDetails>Order Summary </OrderDetails>
                    <Table>
                        {row.items.map((item, index) => (
                            <TableRow key={index}>
                                <TableColumnProduct>
                                    {item.imageUrl ? (
                                        <ProductImage src={item.imageUrl} alt="Product Thumbnail" />
                                    ) : (
                                        <NotFoundImage alt="Not Found" />
                                    )}
                                </TableColumnProduct>
                                <TableColumn>
                                    <ProductInfo>
                                        <ProductName>{item.productName}</ProductName>
                                        <ItemAmount>${item.itemAmount}</ItemAmount>
                                        <Quantity>Quantity: {item.quantity}</Quantity>
                                    </ProductInfo>
                                </TableColumn>
                            </TableRow>
                        ))}
                    </Table>
                </Card>
            </GeneralContainer>

            <GeneralContainer>
                <Card>
                    <OrderDetails>Payment Breakdown</OrderDetails>
                    <Table>
                        <TableRowBreakdown>
                            <TableColumnProduct>Subtotal:</TableColumnProduct>
                            <TableColumnAmounts>${row.totalAmount}</TableColumnAmounts>
                        </TableRowBreakdown>

                        <TableRowBreakdown>
                            <TableColumnProduct>Shipping:</TableColumnProduct>
                            <TableColumnAmounts>$0.00</TableColumnAmounts>
                        </TableRowBreakdown>

                        <TableRowBreakdown>
                            <TableColumnProduct>Discount:</TableColumnProduct>
                            <TableColumnAmounts>N/A</TableColumnAmounts>
                        </TableRowBreakdown>

                        <TableRowBreakdown>
                            <TableColumnProduct>Taxes:</TableColumnProduct>
                            <TableColumnAmounts>$0.00</TableColumnAmounts>
                        </TableRowBreakdown>

                        <TableRowBreakdownTotal>
                            <TableColumnProduct>
                                <strong> Total: </strong>
                            </TableColumnProduct>

                            <TableColumnAmounts>
                                <strong>${row.totalAmount}</strong>
                            </TableColumnAmounts>
                        </TableRowBreakdownTotal>
                    </Table>
                </Card>
            </GeneralContainer>
        </ModalContent>
    );
};

export default OrderDetailsModal;

OrderDetailsModal.propTypes = {
    row: PropTypes.shape({
        billingName: PropTypes.string,
        vendor: PropTypes.string,
        orderId: PropTypes.string,
        status: PropTypes.string,
        reason: PropTypes.string,
        displayOrderDate: PropTypes.string,
        cardBrand: PropTypes.string,
        cardAddress: PropTypes.string,
        items: PropTypes.array,
        totalAmount: PropTypes.number,
    }).isRequired,
};

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const Address = styled.p`
    font-size: 16px;
`;

const SuccessContainer = styled.div`
    background: ${setColor.greenSuccess};
    color: ${setColor.successColor};
    font-family: ${setFont.barlowBold}, Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    padding: 20px;
    border-radius: 5px;
    margin: 0 15px 10px 15px;

    @media (max-width: 768px) {
        margin: 0 0 10px 0;
    }
`;

const FailedContainer = styled.div`
    background: ${setFont.errorMessageOne};
    color: ${setColor.failedRed};
    font-family: ${setFont.barlowBold}, Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    padding: 20px;
    border-radius: 5px;
    margin: 0 15px 10px 15px;

    @media (max-width: 768px) {
        margin: 0 0 10px 0;
    }
`;

const StyledCheckMarkIcon = styled(CheckMarkIcon)`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-bottom: -2px;
`;

const StyledWarningIcon = styled(WarningIcon)`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-bottom: -2px;
`;

const StyledCopyIcon = styled(CopyIcon)`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-bottom: -2px;
    cursor: pointer;
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: "Barlow-Regular", Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 15px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const OrderDetails = styled.div`
    font-size: 16px;
    font-family: ${setFont.barlowBold}, Helvetica;
    font-weight: 700;
    word-wrap: break-word;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-top: 10px;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableRowBreakdown = styled.div`
    display: flex;
`;

const TableRowBreakdownTotal = styled.div`
    margin-top: 10px;
    border-top: ${() => `1px solid ${setColor.tablePurple}`};
    display: flex;
    margin-bottom: 15px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const TableColumnProduct = styled.div`
    padding: 5px;
    margin-right: 20px;
    flex: 0 0 20%;

    @media (max-width: 768px) {
        padding: 0px;
        margin-right: 0px;
    }
`;
const TableColumnAmounts = styled.div`
    padding: 5px;
    margin-right: 20px;
    flex: 0 0 20%;
    text-align: right;
`;

const ProductImage = styled.img`
    width: 100px;
    height: 100px;
`;

const ProductInfo = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;

    @media (max-width: 768px) {
    }
`;

const ProductName = styled.div`
    font-weight: bold;
`;

const ItemAmount = styled.div`
    color: ${setColor.lightPink};
    font-weight: 700;
    margin: 0.5rem 0;
`;

const Quantity = styled.div`
    font-weight: bold;
`;

const NotFoundImage = styled(NotFoundIcon)`
    width: 100px;
    height: 100px;
`;
