import { Stack, Divider, Typography } from "@mui/material";
import FacebookIcon from "../assets/images/socialMediaIcons/fb.png";
import GoogleIcon from "../assets/images/socialMediaIcons/google.png";
import TwitterIcon from "../assets/images/socialMediaIcons/twitter.png";
import Button from "../components/common/button/Button";

export default function AuthSocial() {
    const handleSocialLogin = async (provider) => {
        window.open(`${process.env.REACT_APP_CORE_API_URI}/web/users/auth/${provider}/callback`, "_self");
    };

    return (
        <>
            {/* <Stack direction="row" spacing={2}> */}
            <Button
                customIcon={GoogleIcon}
                backgroundColor={"black"}
                labelColor={"white"}
                border={"grey"}
                buttonWidth={"100%"}
                borderRadius={"0px"}
                label="Continue with Google"
                variant="VanityLinkCustomizable"
                onClick={() => handleSocialLogin("google")}
            />
            {/* </Stack> */}
            {/* <Button
          onClick={() => handleSocialLogin('facebook')}
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          style={{ backgroundColor: '#3479EA' }}
        >
          <img src={FacebookIcon} alt="Facebook Sign-In" width={22} height={22} />
        </Button>

        <Button
          onClick={() => handleSocialLogin('twitter')}
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          style={{ backgroundColor: '#55D4FD' }}
        >
          <img src={TwitterIcon} alt="Twitter Sign-In" width={22} height={22} />
        </Button> */}

            {/* <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider> */}
        </>
    );
}
