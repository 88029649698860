import { store } from "../Store";

export const SaveVanityLink = (
    clientLogo,
    backgroundImage,
    freeformJson,
    clientName,
    emailLogin,
    spotifyLogin,
    broadcast,
    music,
    guestOnlyMode,
    vanityLinkId
) => ({
    type: "VANITY_LINK_SAVE",
    payload: {
        clientLogo,
        backgroundImage,
        freeformJson,
        clientName,
        emailLogin,
        spotifyLogin,
        broadcast,
        music,
        guestOnlyMode,
        vanityLinkId,
    },
});

export const DeleteVanityLink = () => {
    store.dispatch({ type: "DELETE_VANITY_LINK" });
};
