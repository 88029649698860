import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import UsersTable from "./components/UsersTable";
import Title from "../../components/Title";
import Body from "../../layout/Body";
import { getAllUsers } from "./api/usersController";
import { CACHE_QUERY_OPTIONS } from "../../../../constants";
import { formatDateTimeWithTimeZone } from "../../../../utils/generalUtils";
import SearchBar from "../../../../components/common/searchBar/SearchBar";
import Typography from "../../../../components/common/text/Typography";
import { setColor } from "../../../../assets/styles/styles";

const Users = () => {
    const [searchInput, setSearchInput] = useState("");
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [totalNumber, setTotalNumber] = useState(0);

    const {
        data: allUsers,
        error,
        isLoading,
    } = useQuery(["allUsers"], () => getAllUsers(), {
        ...CACHE_QUERY_OPTIONS(),
    });

    useEffect(() => {
        const users = [];
        if (allUsers && allUsers.length > 0) {
            for (let i = 0; i < allUsers.length; i += 1) {
                const createdDateTime = formatDateTimeWithTimeZone(allUsers[i].createdAt);
                allUsers[i].createdAt = createdDateTime;
                users.push({
                    ...allUsers[i],
                });
            }
        }
        users.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        if (searchInput.length === 0) {
            setFilteredTableData(users);
            setTotalNumber(users.length);
        } else {
            const allDAta = users
                .filter((user) => {
                    const text = searchInput.toLowerCase();
                    const role = user.role ? user.role.toLowerCase() : "";
                    const id = user.userId ? user.userId.toLowerCase() : "";
                    const displayName = user.displayName ? user.displayName.toLowerCase() : "";
                    const email = user.email ? user.email.toLowerCase() : "";
                    return (
                        id.includes(text) || displayName.includes(text) || email.includes(text) || role.includes(text)
                    );
                })
                .map((user) => {
                    return {
                        ...user,
                        createdDateTime: formatDateTimeWithTimeZone(user.createdAt),
                    };
                });
            setTotalNumber(allDAta.length);
            setFilteredTableData(allDAta || []);
        }
    }, [allUsers, searchInput]);

    const handleSearchChange = (value) => {
        setSearchInput(value); // Update search text state
    };

    return (
        <>
            <CardTable>
                <TableRow>
                    <Title title={"USERS OVERVIEW"} description={"Manage all users in the system."} />
                </TableRow>
            </CardTable>

            <Body isLoading={isLoading}>
                <>
                    <FilterContainer>
                        <SearchBar
                            width="400px"
                            placeholder="Search users by ID, display name, role and email..."
                            onChange={handleSearchChange}
                        />
                    </FilterContainer>
                    <FilterContainer>
                        {searchInput.length !== 0 && filteredTableData.length === 0 && (
                            <MessageCenter signal>
                                <Typography variant="ConsoleDescription">Search Results:</Typography>
                                No forms found
                            </MessageCenter>
                        )}
                        {searchInput.length !== 0 && filteredTableData.length !== 0 && (
                            <MessageCenter>
                                <Typography variant="ConsoleDescription">Search Results:</Typography>
                                {filteredTableData.length === 1
                                    ? ` ${filteredTableData.length} form found`
                                    : `${filteredTableData.length} forms found`}
                            </MessageCenter>
                        )}
                    </FilterContainer>

                    <UsersTable usersData={filteredTableData} searchInput={searchInput} />
                </>
            </Body>
        </>
    );
};

export default Users;

const CardTable = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;

const MessageCenter = styled.div`
    color: ${(props) => (props.signal ? setColor.errorColor : setColor.successColor)};
    margin: 1rem 0rem;
    display: flex;
    justify-content: flex-end;
`;
