import { API_POST } from "../../config/axiosConfig";

export async function getServer(token, userId, currentUrl) {
    try {
        const data = {
            userId,
            token,
            environmentType: process.env.REACT_APP_STREAM_API_ENV,
            showDebugger: true,
            roomId: "",
            disableTextChat: false,
            disableVoiceChat: false,
            isPrivateRoom: false,
            avatarPreset: "male1",
            disableCameraMouseRotation: false,
            restrictNetworkToDevice: false,
            url: currentUrl,
            activeSessionMinCount: 1,
            maxPlayers: 1,
            networkMode: 5,
            displaySize: `${window.innerWidth}x${window.innerHeight}`,
        };
        // console.log('data', data);
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/dcv/${userId}`;
        return await API_POST(url, data);
    } catch (err) {
        return err;
    }
}
