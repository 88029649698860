import { createContext, useState } from "react";

const UserContext = createContext({ user: {}, setUser: () => {} });

const InitializeFiltersContext = () => {
    const [user, setUser] = useState(null);

    return {
        user,
        setUser,
    };
};

export { UserContext, InitializeFiltersContext };
