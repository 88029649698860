/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { setColor, setFont } from "../../../../../assets/styles/styles";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as GreenFeatureCheckmark } from "../../../../landingPages/intraverseLandingPage/IntraverseHomeImages/GreenFeatureCheckmark.svg";
import { ReactComponent as RedCross } from "../../../../landingPages/intraverseLandingPage/IntraverseHomeImages/redCross.svg";
import {
    getUserProfileData,
    UpdateUserEmail,
    ConfirmPasswordReset,
    currentPasswordChecker,
    UpdateUserBirthday,
    UpdateUserProfileData,
    sendEventsLogCustomBody,
} from "../../../../../api/UserController";
import { default as Button } from "../../../../../components/common/button/Button";
import { updateEmail } from "../../../../../redux/actions/UserActions";
import { validateUsername } from "../../../../../utils/generalUtils";
import PasswordChecker from "../../../../login/PasswordChecker";
import Iconify from "../../../../../components/common/icon/Iconify";

export function EditContentModal({
    handleClose,
    setCurrentBirthday,
    userEmail,
    isEmail,
    isPassword,
    isBirthday,
    isUserData,
}) {
    const dispatch = useDispatch();
    const token = useSelector((state) => state?.user?.token);
    const userId = useSelector((state) => state?.user?.userId);

    // Email states
    const [email, setEmail] = useState("");
    const [emailConfirm, setEmailConfirm] = useState("");

    // Error message state
    const [confirmMessage, setConfirmMessage] = useState("");

    // User Details states
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [country, setCountry] = useState("");
    const [gender, setGender] = useState("");
    const [username, setUsername] = useState("");
    const [currentUsername, setCurrentUsername] = useState("");

    // Birthday states
    const [birthday, setBirthday] = useState(null);
    const [birthdayCounter, setBirthdayCounter] = useState(null);
    const [birthdaySupport, setBirthdaySupport] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Password states
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Password checker states
    const [lengthError, setLengthError] = useState(true);
    const [uppercaseError, setUppercaseError] = useState(true);
    const [numberError, setNumbersError] = useState(true);
    const [specialCharError, setSpecialCharError] = useState(true);

    const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

    const handleChangeEmail = async () => {
        const newEmail = emailConfirm;

        const result = await UpdateUserEmail(token, newEmail);
        if (result.success) {
            setConfirmMessage(`Email Succesfully Updated!`);
            dispatch(updateEmail(emailConfirm));
            setTimeout(() => handleClose(), 2000);
        } else {
            // const errorArray = result.message.error;
            // console.error('API call failed:', errorArray[0].msg);
            // setConfirmMessage(`Error: ${errorArray[0].msg}, Please try again or reach out to Support`);
            setConfirmMessage(`Error: Email already in use, Please try again or reach out to support`);
        }
    };

    const handleChangePassword = async () => {
        const passwordCheck = await currentPasswordChecker(currentPassword, token, userId);
        if (passwordCheck.status === 200) {
            const result = await ConfirmPasswordReset(token, newPassword);
            if (result.success) {
                setConfirmMessage(`Password Succesfully Updated!`);
                setTimeout(() => handleClose(), 2000);
            }
            if (!result.success) {
                if (result?.status?.response?.data?.message !== undefined && result?.status?.response?.status === 402) {
                    setConfirmMessage(
                        `Error: ${result.status.response.data.message} Please try again or reach out to Support`
                    );
                } else {
                    setConfirmMessage(`Error: Please try again or reach out to Support.`);
                }
            }
        } else {
            setConfirmMessage(
                `Error: Current password does not match our records, please try again or reset your password.`
            );
        }
    };

    const handleChangeBirthday = async () => {
        const thirteenYearsAgo = new Date();
        thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);

        const userBirthday = new Date(birthday);

        if (userBirthday > thirteenYearsAgo) {
            setErrorMessage("Error: User must be at least 13 years old.");
        }
        if (userBirthday < thirteenYearsAgo) {
            if (birthday != null) {
                const result = await UpdateUserBirthday(token, birthday, userId);
                if (result.status === 200) {
                    setConfirmMessage("Birthday updated successfully!");
                    setCurrentBirthday(birthday);
                    setTimeout(() => handleClose(), 3000);
                } else {
                    setConfirmMessage("");
                    setBirthdaySupport(true);
                }
                setErrorMessage("");
            }
        }
    };

    const submitAchievement = async () => {
        try {
            const achievementData = {
                source: "web",
                buildId: "1",
                userId,
                ownerId: null,
                roomId: null,
                serverId: "1",
                eventTs: new Date().toISOString(),
                eventName: "achievement",
                eventType: "The Final Touch",
                eventJson: {
                    count: 1,
                },
                envApi: process.env.REACT_APP_STREAM_API_ENV,
                url: process.env.REACT_APP_WEB_URL,
            };
            await sendEventsLogCustomBody(achievementData);
        } catch (error) {
            console.error("Error setting achievement:", error);
        }
    };

    const handleChangeDetails = async () => {
        if (firstName && country && gender) {
            submitAchievement();
        }
        if (username === currentUsername) {
            const result = await UpdateUserProfileData(token, { firstName, lastName, userCountry: country, gender });

            if (result.status === 200) {
                setConfirmMessage("Details updated successfully!");
                setTimeout(() => handleClose(), 3000);
            } else {
                setConfirmMessage(`Error: Display name already in use.`);
            }
        } else {
            const result = await UpdateUserProfileData(token, {
                firstName,
                lastName,
                userCountry: country,
                displayName: username,
                gender,
            });

            if (result.status === 200) {
                setConfirmMessage("Details updated successfully!");
                setTimeout(() => handleClose(), 3000);
            } else {
                setConfirmMessage(`Error: Display name already in use.`);
            }
        }
    };
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        const newUsername = e.target.value;
        if (!validateUsername(newUsername)) {
            setConfirmMessage(
                "Error: Username must start with a letter, only contain letters, numbers, underscores, or hyphens, and be 3 - 20 characters long."
            );
        } else {
            setConfirmMessage("");
        }
    };

    const handlePasswordChecking = (event) => {
        const minLengthRegex = /^.{8,}$/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /\d/;
        // eslint-disable-next-line no-useless-escape
        const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
        setNewPassword(event.target.value);

        if (minLengthRegex.test(event.target.value)) {
            setLengthError(false);
        } else {
            setLengthError(true);
        }
        if (uppercaseRegex.test(event.target.value)) {
            setUppercaseError(false);
        } else {
            setUppercaseError(true);
        }
        if (numberRegex.test(event.target.value)) {
            setNumbersError(false);
        } else {
            setNumbersError(true);
        }
        if (specialCharacterRegex.test(event.target.value)) {
            setSpecialCharError(false);
        } else {
            setSpecialCharError(true);
        }
    };

    useEffect(() => {
        const handlePageLoad = async () => {
            const userData = await getUserProfileData(token, userId);
            setBirthdaySupport(false);

            const { userBirthday, birthdayUpdateCount, displayName, firstName, lastName, userCountry, gender } =
                userData.data;
            const changesLeft = 3 - birthdayUpdateCount;
            setBirthdayCounter(changesLeft);

            setUsername(displayName);
            setCurrentUsername(displayName);
            setFirstName(firstName);
            setLastName(lastName);
            setCountry(userCountry);
            setGender(gender);

            if (userBirthday !== null && userBirthday !== undefined) {
                const apiDate = new Date(userBirthday);
                // apiDate.setDate(apiDate.getDate() + 1);
                setBirthday(apiDate);
            } else {
                setBirthday(null);
            }
        };

        handlePageLoad();
    }, [token, userId]);

    // eslint-disable-next-line react/prop-types
    const MyContainer = ({ className, children }) => (
        <div style={{ marginBottom: "-60px", width: "100%" }}>
            <BirthdayContainer className={className}>
                <div style={{ zIndex: 9999 }}>{children}</div>
            </BirthdayContainer>
        </div>
    );

    return (
        <div>
            <TitleTextContent>
                {isEmail && "Update Email"}
                {isPassword && "Update Password"}
                {isBirthday && !birthdaySupport
                    ? "When's Your Birthday"
                    : isBirthday && birthdaySupport
                      ? "Contact Support"
                      : null}
                {isUserData && "Update Details"}
            </TitleTextContent>
            <SmallSubtitle>
                {isEmail && "Change the email you use to login and receive Intraverse news."}
                {isPassword && "Please ensure your new password meets our criteria."}
                {isUserData && "Edit your profile details below."}
            </SmallSubtitle>
            {isBirthday && !birthdaySupport ? (
                <SmallSubtitle style={{ margin: "1rem 3rem" }}>
                    Add your birthday to receive a gift on your special day! You can change this information{" "}
                    <span> {birthdayCounter} more times</span>.
                </SmallSubtitle>
            ) : isBirthday && birthdaySupport ? (
                <SmallSubtitle>
                    You have hit the limit for changing your birthday. Please reach out to our support team to assist
                    you with updating this information.
                </SmallSubtitle>
            ) : null}
            <ConfirmResponseMessage
                style={{ color: `${confirmMessage.indexOf("Error") !== -1 ? "#DD42CE" : "green"}` }}
            >
                {confirmMessage}
            </ConfirmResponseMessage>
            {isBirthday && errorMessage !== "" && (
                <SmallSubtitle style={{ color: "red" }}>{errorMessage}</SmallSubtitle>
            )}
            {isEmail && (
                <>
                    <LabelText> Current Email</LabelText>
                    <InputContainer>
                        <CustomInput type="email" placeholder="Current Email" readonly value={userEmail} />
                    </InputContainer>
                    <LabelText> New Email</LabelText>
                    <InputContainer>
                        <CustomInput
                            type="email"
                            placeholder="New Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </InputContainer>
                    {email !== "" && (
                        <InputContainer
                            style={{
                                cursor: "default",
                                marginTop: "1rem",
                                padding: "0.2rem 1rem",
                                justifyContent: "flex-start",
                            }}
                        >
                            <TextContent>
                                {validateEmail(email) !== true ? (
                                    <>
                                        {" "}
                                        <RedCross style={{ marginRight: "5px", verticalAlign: "bottom" }} /> Invalid
                                        Email
                                    </>
                                ) : (
                                    <>
                                        {" "}
                                        <GreenFeatureCheckmark height={"15px"} style={{ verticalAlign: "bottom" }} />
                                        {"  "} Valid Email
                                    </>
                                )}
                            </TextContent>
                        </InputContainer>
                    )}
                    <LabelText> Confirm New Email</LabelText>
                    <InputContainer>
                        <CustomInput
                            type="email"
                            placeholder="Confirm New Email"
                            value={emailConfirm}
                            onChange={(e) => setEmailConfirm(e.target.value)}
                        />
                    </InputContainer>

                    {email !== "" && emailConfirm !== "" && (
                        <InputContainer
                            style={{
                                cursor: "default",
                                marginTop: "1rem",
                                padding: "0.2rem 1rem",
                                justifyContent: "flex-start",
                            }}
                        >
                            <TextContent>
                                {email === emailConfirm ? (
                                    <>
                                        <GreenFeatureCheckmark height={"15px"} style={{ verticalAlign: "bottom" }} />{" "}
                                        Emails Match!
                                    </>
                                ) : (
                                    <>
                                        <RedCross style={{ marginRight: "5px", verticalAlign: "bottom" }} /> Emails
                                        don't match
                                    </>
                                )}
                            </TextContent>
                        </InputContainer>
                    )}
                </>
            )}
            {isPassword && (
                <>
                    <LabelText> Current Password</LabelText>
                    <InputContainer>
                        <CustomInput
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            value={currentPassword}
                            type={showPassword ? "text" : "password"}
                            placeholder="Current Password"
                        />
                        <Iconify
                            icon={showPassword ? "ph:eye-slash-duotone" : "mdi:eye"}
                            onClick={toggleShowPassword}
                            Color={"grey"}
                            style={{ height: "27px", margin: "5px -5px 5px 0", cursor: "pointer" }}
                        />
                    </InputContainer>
                    <LabelText> New Password</LabelText>
                    <InputContainer>
                        <CustomInput
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(event) => {
                                handlePasswordChecking(event);
                            }}
                        />
                        <Iconify
                            icon={showPassword ? "ph:eye-slash-duotone" : "mdi:eye"}
                            onClick={toggleShowPassword}
                            Color={"grey"}
                            style={{ height: "27px", margin: "5px -5px 5px 0", cursor: "pointer" }}
                        />
                    </InputContainer>
                    {/* <InputContainer
                  style={{ cursor: 'default', marginTop: '1rem', padding: '0.2rem 1rem', justifyContent: 'flex-start' }}
                > */}
                    {/* <PasswordValidBox>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      {lengthError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      8 characters long
                    </p>
                    <p>
                      {uppercaseError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One uppercase
                    </p>
                  </PasswordValidText>
                  <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>
                      {' '}
                      {numberError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One number
                    </p>
                    <p>
                      {specialCharError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                      ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                      )}{' '}
                      One special character
                    </p>
                  </PasswordValidText>
                </PasswordValidBox> */}
                    {/* </InputContainer> */}
                    <PasswordChecker password={newPassword} />
                    <LabelText> Confirm New Password</LabelText>
                    <InputContainer>
                        <CustomInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm New password"
                            value={newPasswordConfirm}
                            onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        />
                        <Iconify
                            icon={showPassword ? "ph:eye-slash-duotone" : "mdi:eye"}
                            onClick={toggleShowPassword}
                            Color={"grey"}
                            style={{ height: "27px", margin: "5px -5px 5px 0", cursor: "pointer" }}
                        />
                    </InputContainer>
                    {currentPassword !== "" && newPassword !== "" && newPasswordConfirm !== "" && (
                        <InputContainer
                            style={{
                                cursor: "default",
                                marginTop: "1rem",
                                padding: "0.2rem 1rem",
                                justifyContent: "flex-start",
                            }}
                        >
                            <TextContent>
                                {newPassword === newPasswordConfirm ? (
                                    <>
                                        <GreenFeatureCheckmark height={"15px"} style={{ verticalAlign: "bottom" }} />{" "}
                                        Passwords Match!
                                    </>
                                ) : (
                                    <>
                                        <RedCross style={{ marginRight: "5px", verticalAlign: "bottom" }} /> Passwords
                                        do not match
                                    </>
                                )}
                            </TextContent>
                        </InputContainer>
                    )}
                </>
            )}
            <ButtonDiv>
                {isEmail && (
                    <Button
                        variant={"PinkButton"}
                        label="Update My Email"
                        buttonWidth="100%"
                        margin={"0rem 0rem 2rem 0rem"}
                        disabled={
                            (validateEmail(email) !== true && validateEmail(emailConfirm) !== true) ||
                            email !== emailConfirm
                        }
                        onClick={() => handleChangeEmail()}
                    />
                )}
                {isPassword && (
                    <Button
                        variant={"PinkButton"}
                        label="Update My Password"
                        buttonWidth="100%"
                        margin={"0rem 0rem 2rem 0rem"}
                        disabled={
                            lengthError ||
                            uppercaseError ||
                            numberError ||
                            specialCharError ||
                            currentPassword === "" ||
                            newPassword !== newPasswordConfirm
                        }
                        onClick={() => handleChangePassword()}
                    />
                )}
                {isBirthday && !birthdaySupport ? (
                    <BirthdayContainer>
                        <StyledBirthdaySelector>
                            <DateInput
                                popperPlacement="top"
                                calendarContainer={MyContainer}
                                selected={birthday}
                                onChange={(date) => setBirthday(date)}
                                dateFormat="MM/dd/yyyy"
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                fixedHeight
                                showTimeInput={false}
                                placeholderText="Click to select a date"
                            />
                        </StyledBirthdaySelector>
                        <Button
                            variant={"PinkButton"}
                            label="Add My Birthday"
                            disabled={birthday === null}
                            buttonWidth="100%"
                            margin={"1rem 0rem 2rem 0rem"}
                            onClick={() => handleChangeBirthday()}
                        />
                    </BirthdayContainer>
                ) : isBirthday && birthdaySupport ? (
                    <BirthdayContainer>
                        <InputContainer>
                            <CustomInput
                                style={{ color: setColor.white }}
                                type="email"
                                value="hello@terrazero.com"
                                readonly
                            />
                        </InputContainer>
                        <Button
                            variant={"OutlinedButton"}
                            label="Contact Support"
                            buttonWidth="100%"
                            onClick={() => {
                                window.location = "mailto:hello@terrazero.com";
                            }}
                        >
                            <p>Contact Support</p>
                        </Button>
                    </BirthdayContainer>
                ) : null}
                {isUserData && (
                    <DetailModal>
                        <LabelText>Username *</LabelText>
                        <InputContainer>
                            <CustomInput type="text" value={username} onChange={handleUsernameChange} />
                        </InputContainer>
                        <DetailDoubleRow>
                            <DetailDoubleColumn>
                                <LabelText> First Name *</LabelText>
                                <InputContainer>
                                    <CustomInput
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => {
                                            const value = e.target.value.trim();
                                            const isValid = /^[a-zA-Z\s'-]*$/.test(value);
                                            if (isValid) {
                                                setFirstName(value);
                                            }
                                        }}
                                    />
                                </InputContainer>
                            </DetailDoubleColumn>
                            <DetailDoubleColumn>
                                <LabelText> Last Name *</LabelText>
                                <InputContainer>
                                    <CustomInput
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => {
                                            const value = e.target.value.trim();
                                            const isValid = /^[a-zA-Z\s'-]*$/.test(value);
                                            if (isValid) {
                                                setLastName(value);
                                            }
                                        }}
                                    />
                                </InputContainer>
                            </DetailDoubleColumn>
                        </DetailDoubleRow>
                        <LabelText>Country</LabelText>
                        <InputContainer>
                            <InputSelect id="country" value={country} onChange={(e) => setCountry(e.target.value)}>
                                <InputSelectOption value="">Select a country</InputSelectOption>
                                <InputSelectOption value="United States of America">
                                    United States of America
                                </InputSelectOption>
                                <InputSelectOption value="Canada">Canada</InputSelectOption>
                            </InputSelect>
                        </InputContainer>
                        <LabelText>Gender</LabelText>
                        <InputContainer>
                            <InputSelect id="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                                <InputSelectOption value="">Select a gender</InputSelectOption>
                                <InputSelectOption value="Female">Female</InputSelectOption>
                                <InputSelectOption value="Male">Male</InputSelectOption>
                                <InputSelectOption value="Non Binary">Non Binary</InputSelectOption>
                                <InputSelectOption value="Prefer not to say">Prefer not to say</InputSelectOption>
                            </InputSelect>
                        </InputContainer>
                        <Button
                            margin="1rem 0rem 2rem 0rem"
                            buttonWidth="100%"
                            variant="PinkButton"
                            label="Update My Details"
                            disabled={!validateUsername(username) || firstName === "" || lastName === ""}
                            onClick={() => handleChangeDetails()}
                        />
                    </DetailModal>
                )}
            </ButtonDiv>
        </div>
    );
}

EditContentModal.propTypes = {
    handleClose: PropTypes.func,
    // currentBirthday: PropTypes.string,
    setCurrentBirthday: PropTypes.func,
    userEmail: PropTypes.string,
    isEmail: PropTypes.bool,
    isPassword: PropTypes.bool,
    isBirthday: PropTypes.bool,
    isUserData: PropTypes.bool,
};

const DetailModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;
const DetailDoubleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px;
`;
const DetailDoubleColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const TitleTextContent = styled.h1`
    color: ${setColor.accent4};
    font-family: ${setFont.barlowCondensed}, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 1rem 0rem;
    text-align: center;

    @media (max-width: 425px) {
        font-size: 24px;
        margin: 5px 0;
    }
`;
const TextContent = styled.p`
    color: ${setColor.circularProgress};
    text-align: left;
    font-family: ${setFont.barlow}, sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin: 5px;
`;

const SmallSubtitle = styled.p`
    text-align: center;
    color: ${setColor.circularProgress};
    font-family: ${setFont.barlow}, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 0rem;
    margin-bottom: 5px;

    & > span {
        color: ${setColor.spanTextcolor2};
        font-weight: 700;
    }

    @media (max-width: 425px) {
        font-size: 14px;
        margin: 5px 0;
    }
`;

const ConfirmResponseMessage = styled.p`
    text-align: center;
    color: ${setColor.green};
    font-family: ${setFont.barlow}, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 0rem;
    margin-bottom: 5px;

    & > span {
        color: ${setColor.accent4};
        font-weight: 700;
    }
`;
const LabelText = styled.p`
    text-align: left;
    color: ${setColor.circularProgress};
    font-family: ${setFont.barlow}, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 5px;

    @media (max-width: 425px) {
        font-size: 14px;
        margin-top: 5px;
    }

    @media (max-height: 742px) {
        margin-top: 5px;
    }
`;

const CustomInput = styled.input`
    flex: 1;
    border: none;
    background: none;
    width: 90%;
    height: 35px;
    padding-left: 10px;
    color: white;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: ${() => `0 0 0 30px ${setColor.purpleDarkTwo} inset !important`};
        -webkit-text-fill-color: white !important;
    }
`;
const InputSelect = styled.select`
    flex: 1;
    border: none;
    background: none;
    width: 90%;
    height: 35px;
    padding-left: 10px;
    color: ${setColor.white};
`;
const InputSelectOption = styled.option`
    flex: 1;
    border: none;
    background: ${setColor.inputBgColor};
    width: 90%;
    height: 35px;
    padding-left: 10px;
    color: ${setColor.white};
`;

const InputContainer = styled.div`
    display: flex;
    flex: 1;
    border-radius: 5px;
    justify-content: center;
    background: ${setColor.purpleDark};
    cursor: pointer;
    height: 35px;
    align-items: center;
    border: 1px solid ${setColor.loadingButtonBg};

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
    }
`;

const ButtonDiv = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledBirthdaySelector = styled.div`
    display: flex;
    flex-direction: column;
    margin: 4rem 0rem 0rem 0rem;
`;

const BirthdayContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const DateInput = styled(DatePicker)`
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid ${setColor.loadingButtonBg};
    background-color: transparent;
    color: ${setColor.white};
    width: 100%;
`;
