import { store } from "../Store";

export const UpdateData = (user, role) => ({
    type: "UPDATE_USER",
    payload: {
        user,
        role,
    },
});

export const SaveUser = (email, user, token, refreshToken, userId, role, isMerchant, expiration, profileIcon = "") => ({
    type: "USER_SAVE",
    payload: {
        email,
        user,
        token,
        refreshToken,
        userId,
        role,
        isMerchant,
        expiration,
        profileIcon,
    },
});

export const updateEmail = (email) => ({
    type: "UPDATE_EMAIL",
    payload: {
        email,
    },
});
export const UpdateUsername = (username) => ({
    type: "UPDATE_USERNAME",
    payload: {
        username,
    },
});

export const DeleteUser = () => {
    store.dispatch({ type: "USER_DELETE" });
};

export const RefreshAccessToken = (newToken, expiration, newRefreshToken) => ({
    type: "REFRESH_TOKEN",
    payload: {
        token: newToken,
        expiration,
        refreshToken: newRefreshToken,
    },
});
