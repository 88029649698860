import { authenticateAutoLoginLink } from "../../../api/VanityLinkController";
import { authenticateNapsterLogin } from "../../../api/NapsterController";

export const AuthenticateAutoLogin = async (id, access, vanity) => {
    try {
        const authenticatedLink = await authenticateAutoLoginLink(id, access, vanity);

        if (!authenticatedLink) {
            return null;
        }

        return { ...authenticatedLink, vanity };
    } catch (error) {
        console.error("Error fetching the vanity link:", error);
        return null;
    }
};

export const AuthenticateNapsterLogin = async (base64Object, clientName) => {
    try {
        const userData = await authenticateNapsterLogin(base64Object, clientName);

        if (!userData) {
            return null;
        }

        return userData;
    } catch (error) {
        console.error("Error fetching the vanity link:", error);
        return null;
    }
};
