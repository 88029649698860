// NoteCreationStep2.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { formatDistanceToNow } from "date-fns";
import StickerSelection from "./StickerSelection";
import NotesBackground from "./images/NotesBackground2.png";
import Chevron from "./images/Chevron.png";
import noneIcon from "./images/noneIcon.png";
import purpleX from "../../assets/images/buttonIcons/purpleX.png";
import CheckIcon from "./images/CheckIcon.png";
import SampleGuestbook from "./images/SampleGuestbook.png";
import FontSelection from "./FontSelection";
import Typography from "../../components/common/text/Typography";
import { setFont } from "../../assets/styles/styles";
import ColorSelection from "./ColorSelection";
import PinBlack from "./images/pins/PinBlack.png";
import PinGreen from "./images/pins/PinGreen.png";
import PinPink from "./images/pins/PinPink.png";
import PinPurple from "./images/pins/PinPurple.png";
import PinWhite from "./images/pins/PinWhite.png";
import PinYellow from "./images/pins/PinYellow.png";
import thumbsUpblk from "./images/Thumbs_Up_Black.png";
import thumbsUpwht from "./images/Thumbs_Up_White.png";
import thumbsDownblk from "./images/Thumbs_Down_Black.png";
import thumbsDownwht from "./images/Thumbs_Down_White.png";
import stickyFoldBlack from "./images/stickyFolds/stickyFold_Black1.png";
import stickyFoldGreen from "./images/stickyFolds/stickyFold_Green1.png";
import stickyFoldPink from "./images/stickyFolds/stickyFold_Pink1.png";
import stickyFoldPurple from "./images/stickyFolds/stickyFold_Purple1.png";
import stickyFoldWhite from "./images/stickyFolds/stickyFold_White1.png";
import stickyFoldYellow from "./images/stickyFolds/stickyFold_Yellow1.png";
import Blush from "./images/emojis/Blush.png";
import Happy from "./images/emojis/Happy.png";
import InLove from "./images/emojis/InLove.png";
import Laugh from "./images/emojis/Laugh.png";
import Sad from "./images/emojis/Sad.png";
import Worried from "./images/emojis/Worried.png";
import Tongue from "./images/emojis/Tongue.png";
import { addNote } from "./api/notesController";
import { stickers } from "../../constants";

const NoteCreationStep2 = ({
    message,
    selectedColor,
    selectedSticker,
    selectedFont,
    onSubmitNote,
    setCurrentStep,
    setIsCreatingNote,
    useEmailCreator,
    isAnonymous,
    roomId,
    isProUser,
    displayName,
}) => {
    const [noteMessage, setNoteMessage] = useState(message);
    const [color, setColor] = useState(selectedColor || "#C5B7FF");
    const [sticker, setSticker] = useState(selectedSticker || noneIcon);
    const [emojiName, setEmojiName] = useState("none");
    const [font, setFont] = useState(selectedFont || "Barlow, sans serif");
    const [likes, setLikes] = useState(0);
    const [dislikes, setDislikes] = useState(0);
    const [sidebarContent, setSidebarContent] = useState("default");

    const pins = [
        { pinColor: "#C5B7FF", pinIcon: PinPurple },
        { pinColor: "#FFCDED", pinIcon: PinPink },
        { pinColor: "#FFF3B1", pinIcon: PinYellow },
        { pinColor: "#C7FFC8", pinIcon: PinGreen },
        { pinColor: "#4E4C55", pinIcon: PinBlack },
        { pinColor: "#FFFFFF", pinIcon: PinWhite },
    ];

    const stickyCorners = [
        { stickyColor: "#C5B7FF", stickyFoldImg: stickyFoldPurple },
        { stickyColor: "#FFCDED", stickyFoldImg: stickyFoldPink },
        { stickyColor: "#FFF3B1", stickyFoldImg: stickyFoldYellow },
        { stickyColor: "#C7FFC8", stickyFoldImg: stickyFoldGreen },
        { stickyColor: "#4E4C55", stickyFoldImg: stickyFoldBlack },
        { stickyColor: "#FFFFFF", stickyFoldImg: stickyFoldWhite },
    ];

    const handleSubmit = async () => {
        if (onSubmitNote) {
            const resp = await addNote(roomId, noteMessage, color, emojiName, font, isAnonymous, useEmailCreator);
            if (resp?.status === 201) {
                setCurrentStep(0);
                setIsCreatingNote(false);
                onSubmitNote();
            } else {
                console.log("Error adding note");
            }
        } else {
            console.error("onSubmitNote is not passed properly");
        }
    };

    useEffect(() => {
        setSidebarContent("default");
    }, [color, sticker, font]);

    return (
        <NoteCustomizationContainer>
            <Banner>
                {sidebarContent !== "default" && (
                    <StyledImage
                        style={{ position: "fixed", top: "1.3rem", left: "1rem", height: "30px" }}
                        alt="back"
                        src={Chevron}
                        onClick={() => setSidebarContent("default")}
                    />
                )}
                <Typography style={{ marginLeft: "2rem" }} variant="guestbookHeader">
                    Leave a Note
                </Typography>
                <NotesBox>
                    <Typography style={{ padding: "1rem" }} variant="guestbookHeader" color="#E4DEFF">
                        <StyledImageChevron
                            style={{ height: "30px" }}
                            alt="back"
                            src={Chevron}
                            onClick={() => setCurrentStep(1)}
                        />
                        Step 2 of 2
                    </Typography>
                    <Button
                        onClick={() => {
                            setCurrentStep(0);
                            setIsCreatingNote(false);
                        }}
                    >
                        <StyledImage style={{ height: "35px" }} src={purpleX} alt="Close" />
                    </Button>
                </NotesBox>
            </Banner>
            <SideBar>
                {sidebarContent === "default" && (
                    <>
                        <Option onClick={() => setSidebarContent("text")}>
                            <CustomizeIcon bgColor="#23173E" style={{ fontFamily: font || setFont.barlow }}>
                                Aa
                            </CustomizeIcon>{" "}
                            <p style={{ color: "white" }}>TEXT</p>
                        </Option>
                        <Option onClick={() => setSidebarContent("color")}>
                            <CustomizeIcon bgColor={color || "#C5B7FF"} /> <p style={{ color: "white" }}>COLOR</p>
                        </Option>
                        <Option onClick={() => setSidebarContent("sticker")}>
                            <CustomizeIcon bgColor="#23173E">
                                {sticker === "none" && <StyledImageDefault alt="none icon" src={noneIcon} />}
                                {sticker === "" && <StyledImageDefault alt="none icon" src={noneIcon} />}
                                {sticker !== "none" && sticker !== "" && (
                                    <StyledImageDefault alt="icon" src={sticker} />
                                )}
                            </CustomizeIcon>
                            <p style={{ color: "white" }}>STICKER</p>
                        </Option>
                    </>
                )}
                {sidebarContent === "text" && (
                    <SideBarGrid>
                        <FontSelection selectedFont={font} onSelectFont={setFont} />
                    </SideBarGrid>
                )}

                {sidebarContent === "color" && (
                    <SideBarGrid>
                        <ColorSelection selectedColor={color} onSelectColor={setColor} />
                    </SideBarGrid>
                )}

                {sidebarContent === "sticker" && (
                    <SideBarGrid>
                        <StickerSelection
                            selectedSticker={sticker}
                            onSelectSticker={setSticker}
                            setSelectedEmojiName={setEmojiName}
                            emojiName={emojiName}
                        />
                    </SideBarGrid>
                )}
            </SideBar>
            <SampleBackground src={SampleGuestbook} alt="Sample GuestBook" />
            <SubmitButton onClick={handleSubmit}>
                <img style={{ margin: "0 10px", height: "100px" }} alt="CheckIcon" src={CheckIcon} />
            </SubmitButton>
            <MessageBoxContainer>
                <StickyNote style={{ backgroundColor: color || "#1B1B23" }}>
                    <UsernameContainer
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            width: "100%",
                            fontWeight: "500",
                            padding: "0.5rem 1rem",
                            backgroundColor: color || "#1B1B23",
                            color: color === "#4E4C55" ? "white" : "#1B1B23",
                        }}
                    >
                        <UserNameAndPin>
                            <img
                                alt="pin"
                                height={"50px"}
                                src={
                                    pins.find((pin) => pin.pinColor?.toLowerCase() === color?.toLowerCase())?.pinIcon ||
                                    PinYellow
                                }
                            />
                            {isAnonymous ? "Anonymous" : displayName}
                        </UserNameAndPin>
                        <span
                            style={{
                                textAlign: "right",
                                color: color === "#4E4C55" ? "white" : "rgba(27, 27, 35, 0.50)",
                            }}
                        >
                            {formatDistanceToNow(new Date())}
                        </span>
                    </UsernameContainer>
                    <p
                        style={{
                            fontFamily: font,
                            padding: "1rem 1.5rem",
                            letterSpacing: 0,
                            color: color === "#4E4C55" ? "white" : "#1B1B23",
                            textAlign: "left",
                            width: "100%",
                            fontSize: "18px",
                        }}
                    >
                        {noteMessage}
                    </p>
                    <div style={{ position: "absolute", bottom: "-70px", right: "-23px" }}>
                        {sticker && sticker !== "none" && (
                            <img
                                src={sticker}
                                alt="sticker"
                                style={{ width: "90px", height: "90px", transform: "rotate(15deg)" }}
                            />
                        )}
                        {/* {selectedSticker === 'none' && !sticker && (
                            <>
                                <img
                                    src={noneIcon}
                                    alt="sticker"
                                    style={{ width: '90px', height: '90px', transform: 'rotate(15deg)' }}
                                />
                            </>
                        )} */}
                    </div>
                    <ThumbsWrapper>
                        <Thumb
                            style={{
                                border: color === "#4E4C55" ? "2px solid white" : "2px solid rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={() => setLikes(likes + 1)}
                        >
                            <ThumbIcon src={color === "#4E4C55" ? thumbsUpwht : thumbsUpblk} alt="Thumbs Up" />
                            <p
                                style={{
                                    color: color === "#4E4C55" ? "white" : "rgba(0, 0, 0, 0.5)",
                                    marginLeft: "8px",
                                }}
                            >
                                {likes}
                            </p>
                        </Thumb>
                        <Thumb
                            style={{
                                border: color === "#4E4C55" ? "2px solid white" : "2px solid rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={() => setDislikes(dislikes + 1)}
                        >
                            <ThumbIcon src={color === "#4E4C55" ? thumbsDownwht : thumbsDownblk} alt="Thumbs Down" />
                            <p
                                style={{
                                    color: color === "#4E4C55" ? "white" : "rgba(0, 0, 0, 0.5)",
                                    marginLeft: "8px",
                                }}
                            >
                                {dislikes}
                            </p>
                        </Thumb>
                    </ThumbsWrapper>
                    <BottomFold
                        src={
                            stickyCorners.find((corner) => corner.stickyColor?.toLowerCase() === color?.toLowerCase())
                                ?.stickyFoldImg || stickyFoldPurple
                        }
                        alt="bottom fold"
                    />
                </StickyNote>
            </MessageBoxContainer>
        </NoteCustomizationContainer>
    );
};

export default NoteCreationStep2;

const NoteCustomizationContainer = styled.div`
    position: relative;
    padding: 0 20px;
    background: linear-gradient(73deg, #11113f 1.32%, #210d35 96.92%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-bottom: 2rem;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.85);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(190, 174, 255, 0.5);
        border-radius: 6px;
    }
`;
const MessageBoxContainer = styled.div`
    margin-top: 100px;
    width: 85%;
    display: flex;
    flex-direction: column;
`;

const StyledImageDefault = styled.img`
    margin: 1rem;
    width: 80px;
    @media (max-width: 960px) {
        width: 30px;
    }
`;
const SideBar = styled.div`
    position: fixed;
    top: 70px;
    left: 0;
    width: fit-content;
    height: 100%;
    background-color: #2c3e50;
    display: flex;
    padding-top: 0.5rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-right: 3px solid #3d364f;
    background: #0f0621;
    color: #fbfcff;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.85);
        height: 110%;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(190, 174, 255, 0.5);
        border-radius: 6px;
    }

    p {
        font-size: 18px;
        text-align: left;
    }

    @media (max-height: 550px) {
        height: 120vh;
    }

    @media (max-width: 960px) {
        width: 30vw;
        height: 100%;
    }

    @media (min-width: 378px) and (max-width: 700px) {
        top: 55px;
    }
`;

const Option = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    flex-direction: column;
    cursor: pointer;

    p {
        margin-left: 1rem;
        font-size: 1.2rem;
        color: #333;

        @media (max-width: 960px) {
            font-size: 10px;
            margin-left: 0;
        }
    }
`;

const SubmitButton = styled.button`
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;

    @media (max-width: 960px) {
        bottom: 5px;
        right: 5px;
    }
`;
const SideBarGrid = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    flex-direction: column;
    cursor: pointer;
    /* margin-left: 1rem; */
    font-size: 1.2rem;
    color: #333;
`;

const Banner = styled.div`
    width: 100%;
    height: fit-content;
    background: url(${NotesBackground}), linear-gradient(73deg, #11113f 1.32%, #210d35 96.92%);
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0rem 0rem 0rem 2rem;

    @media (max-width: 700px) {
        padding: 0rem;
    }
`;

const NotesBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const CustomizeIcon = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 1rem;
    background-color: ${(props) => props.bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 36px;

    p {
        font-size: 28px;
        font-weight: 700;
    }

    @media (max-width: 960px) {
        font-size: 18px;
        width: 50px;
        height: 50px;
    }
`;

const Button = styled.button`
    padding: 1rem 2rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: white;
    border-left: 4px solid rgba(190, 174, 255, 0.2);

    @media (max-width: 700px) {
        padding: 0.5rem 0.2rem;
    }
`;

const StyledImage = styled.img`
    @media (max-width: 700px) {
        width: 30px;
        height: auto;
    }
`;
const SampleBackground = styled.img`
    position: absolute;
    top: 70px;
    left: 15vw;
    width: 80%;

    @media (max-width: 700px) {
        width: auto;
        height: 100%;
    }
`;
const StyledImageChevron = styled.img`
    margin: 0 1.5rem -0.4rem 0;

    @media (max-width: 700px) {
        margin: 0 0.5rem -0.6rem -2.2rem;
        padding-top: 8px;
    }
`;

const StickyNote = styled.div`
    background-color: ${(props) => props.color || "#FFF3B1"};
    font-family: ${setFont.barlow};
    width: 100%;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    position: relative;
    display: inline-block;
    position: absolute;
    top: 31%;
    left: 50%;
    transform: translateX(-30%) translateY(calc(-5% / 10));
    align-items: center;
    width: 25vw;
    height: "600px";
    /* padding: 10px; */

    @media (max-width: 1044px) {
        width: 230px;
        top: 13%;
    }
`;

const UserNameAndPin = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const UsernameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    padding: 0.5rem 1rem;

    @media (max-width: 1064px) {
        flex-direction: column;
    }
`;

const ThumbsWrapper = styled.div`
    display: flex;
    justify-content: left;
    margin-top: 20px;
    padding: 0 15px;
    /* margin-bottom: -10px; */
    gap: 10px;
`;

const Thumb = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #333;
    cursor: pointer;

    border: 2px solid rgba(0, 0, 0, 0.5);
    border-radius: 100px;
    width: fit-content;
    padding: 10px 20px;
`;

const ThumbIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`;

const BottomFold = styled.img`
    width: 100%;
    height: auto;
    margin-bottom: -46px;

    @media (min-width: 1175px) {
        margin-bottom: -56px;
    }

    @media (min-width: 900px) and (max-width: 1042px) {
        margin-bottom: -38px;
    }

    @media (min-width: 768px) and (max-width: 900px) {
        margin-bottom: -38px;
    }

    @media (min-width: 577px) and (max-width: 768px) {
        margin-bottom: -35px;
    }

    @media (max-width: 576px) {
        margin-bottom: -34px;
    }
`;
