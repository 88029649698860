import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import "@fontsource/barlow";
import Chevron from "../../../assets/images/buttonIcons/Chevron.png";
import { setColor, setFont } from "../../../assets/styles/styles";

const NavigationBar = ({ title, func }) => (
    <Container>
        <Button alt="Accept" onClick={func}>
            <img className="chevron-icon" src={Chevron} alt="Navigate" />
        </Button>
        <Title>{title}</Title>
    </Container>
);

export default NavigationBar;

NavigationBar.defaultProp = {
    title: "Title Default",
};

NavigationBar.propTypes = {
    title: PropTypes.string,
    func: PropTypes.func.isRequired,
};

const Button = styled.div`
    cursor: pointer;
    padding: 25px;
    border-right: 4px solid #ffffff33;
    @media (max-width: 960px) {
        padding: 15px;
    }

    .chevron-icon {
        resizemode: contain;
        height: 30px;
        width: auto;

        @media (max-width: 960px) {
            height: 20px;
        }
    }
`;

const Title = styled.div`
    text-transform: uppercase;
    font-size: 20px;
    color: white;
    font-family: ${setFont.barlow};
    letter-spacing: 0.1em;
    font-style: bold;
    font-weight: 600;
    padding-left: 25px;

    @media (max-width: 960px) {
        font-size: 16px;
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${setColor.darkBlue};
    display: flex;
    align-items: center;
    justify-content: start;
    height: auto;
    max-height: 80px;
    width: 100%;
    z-index: 1000;
    transition: transform 0.3s ease;
`;
