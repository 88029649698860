const defaultState = {
    email: "",
    user: "",
    token: "",
    refreshToken: "",
    userId: "",
    role: "",
    isMerchant: "",
    expiration: 0,
    profileIcon: "",
};

const UserReducer = (state, action) => {
    switch (action.type) {
        case "USER_SAVE": {
            return {
                ...state,
                email: action.payload.email,
                user: action.payload.user,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken,
                userId: action.payload.userId,
                role: action.payload.role,
                isMerchant: action.payload.isMerchant,
                expiration: action.payload.expiration,
                profileIcon: action.payload.profileIcon,
            };
        }

        case "UPDATE_USER": {
            return {
                ...state,
                user: action.payload.user,
            };
        }

        case "UPDATE_EMAIL":
            return {
                ...state,
                email: action.payload.email,
            };

        case "UPDATE_USERNAME":
            return {
                ...state,
                username: action.payload.username,
            };

        case "USER_DELETE": {
            return defaultState;
        }

        case "REFRESH_TOKEN": {
            return {
                ...state,
                token: action.payload.token,
                expiration: action.payload.expiration,
                refreshToken: action.payload.refreshToken,
            };
        }

        default:
            if (state === undefined) {
                return defaultState;
            }
            return state;
    }
};

export default UserReducer;
