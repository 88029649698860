import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DiamondBackground from "../../../assets/images/backgrounds/Diamond_Checkered_Background.png";
import { breakpoints } from "../../../assets/styles/mediaBreakpoints";
import { setColor } from "../../../assets/styles/styles";

const BackgroundContainer = ({
    variant,
    backgroundColor,
    backgroundImage,
    videoSource,
    margin,
    padding,
    width,
    height,
    boxShadow,
    border,
    loginBorder,
    backgroundSize,
    backgroundPosition,
    children,
    selectorScreenPage,
    zIndex,
    ...props
}) => {
    const ContainerComponent = BackgroundContainer.variants[variant] || BackgroundContainer.variants.DiamondBackground;

    return (
        <>
            {variant === "CustomVideo" ? (
                <div>
                    <ContainerComponent autoPlay loop muted>
                        <source src={videoSource} type="video/mp4" />
                    </ContainerComponent>
                    <ContentContainer margin={margin} width={width} height={height}>
                        {children}
                    </ContentContainer>
                </div>
            ) : (
                <ContainerComponent
                    {...props}
                    margin={margin}
                    backgroundColor={backgroundColor}
                    boxShadow={boxShadow}
                    border={border}
                    loginBorder={loginBorder}
                    padding={padding}
                    width={width}
                    height={height}
                    zIndex={zIndex}
                    backgroundSize={backgroundSize}
                    backgroundPosition={backgroundPosition}
                    selectorScreenPage={selectorScreenPage}
                    backgroundImage={backgroundImage}
                >
                    {loginBorder ? <BorderOutlineStroke>{children}</BorderOutlineStroke> : children}
                </ContainerComponent>
            )}
        </>
    );
};

export default BackgroundContainer;

BackgroundContainer.variants = {
    DiamondBackground: styled.div`
        box-sizing: border-box;
        background: ${(props) => (props.backgroundColor ? props.backgroundColor : setColor.inputBgColor)};
        background-image: linear-gradient(rgba(18, 18, 24, 0.8), rgba(18, 18, 24, 0.8)), url(${DiamondBackground});
        background-size: 350px auto;
        background-position: center;
        ${(props) => (props.border ? `border: ${props.border}` : null)};
        ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
        ${(props) => (props.padding ? `padding: ${props.padding}` : "padding: 20px")};
        height: ${(props) => (props.height ? props.height : "100%")};
        width: ${(props) => (props.width ? props.width : "100%")};
        ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
        // @media (max-height: 700px) {
        //   height: auto;
        // }

        @media (max-width: 768px) {
            height: ${(props) => (props.fullHeightMobile ? "100vh" : "100%")};
            position: ${(props) => (props.fullHeightMobile ? "fixed" : "static")};
            width: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
            height: ${(props) => (props.fullHeightTablet ? "100vh" : "100%")};
            position: ${(props) => (props.fullHeightTablet ? "fixed" : "static")};
            width: 100%;
            overflow: auto;
        }
    `,
    CustomImage: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: ${(props) => (props.backgroundColor ? props.backgroundColor : setColor.inputBgColor)};
        background-image: ${(props) =>
            props.backgroundImage ? `url(${props.backgroundImage})` : `url(${DiamondBackground})`};
        background-size: ${(props) => (props.backgroundSize ? props.backgroundSize : `350px auto`)};
        background-position: ${(props) => (props.backgroundPosition ? props.backgroundPosition : `center`)};
        ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
        padding: ${(props) => (props.selectorScreenPage === "home" ? "75px" : "0px")};
        height: ${(props) => (props.height ? props.height : "100vh")};
        width: ${(props) => (props.width ? props.width : "100vw")};
        overflow-y: scroll;
        overflow-x: hidden;

        ::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
            padding: ${(props) => (props.selectorScreenPage === "home" ? "25px" : "0px")};
        }
    `,
    CustomVideo: styled.video`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    `,
};

BackgroundContainer.propTypes = {
    variant: PropTypes.string,
    backgroundColor: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    zIndex: PropTypes.number,
    backgroundImage: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    boxShadow: PropTypes.string,
    border: PropTypes.string,
    backgroundSize: PropTypes.string,
    backgroundPosition: PropTypes.string,
    selectorScreenPage: PropTypes.string,
    loginBorder: PropTypes.bool,
    children: PropTypes.any,
};

BackgroundContainer.defaultProps = {
    variant: "DiamondBackground",
    backgroundColor: "",
    height: "",
    width: "",
    backgroundImage: "",
    margin: "",
    padding: "",
    boxShadow: "",
    border: "",
    loginBorder: false,
};

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
    padding: 0;
    height: ${(props) => (props.height ? props.height : "100vh")};
    width: ${(props) => (props.width ? props.width : "100vw")};
    overflow-y: auto;
    overflow-x: hidden;
`;

const BorderOutlineStroke = styled.div`
    z-index: 3000;
    border: ${() => `3px solid ${setColor.redBackground}`};
    padding: 10px;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        padding: 5px;
        border: none;
    }

    @media (max-height: 700px) {
        padding: 5px;
        border: none;
        height: auto;
    }
`;
