import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { setFont } from "../../../assets/styles/styles";

const Title = ({ text, backgroundColor, onClick, fontSize, variant, margin }) => {
    const TitleComponent = Title.variants[variant] || Title.variants.PurpleTitle;
    return (
        <TitleComponent backgroundColor fontSize variant margin>
            {text}
        </TitleComponent>
    );
};

export default Title;

Title.propTypes = {
    variant: PropTypes.oneOf(["PurpleTitle", "PageTitle"]),
    backgroundColor: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

Title.defaultProps = {
    variant: "PurpleTitle",
    backgroundColor: "",
};

Title.variants = {
    PurpleTitle: styled.h1`
        background-color: purple;
        background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: ${setFont.barlowCondensed};
        font-size: 55px;
        font-style: normal;
        font-weight: 700;
        line-height: 66px;
        margin: ${(props) => props.margin};
        text-transform: uppercase;
        @media (max-width: 768px) {
            margin: auto;
        }
    `,
    PageTitle: styled.h1`
        font-size: 2.5em;
        color: white;
        background-color: black;
        font-family: ${setFont.signikaNegative};
        letter-spacing: 0.05em;
        font-style: bold;
        font-weight: 600;
        @media (max-width: 960px) {
            font-size: 1.5em;
        }
    `,
};
