import { API_POST } from "../config/axiosConfig";

export async function authenticateNapsterLogin(base64Object, clientName) {
    try {
        const data = {
            encryptedOject: base64Object,
            clientName,
        };

        const response = await API_POST(
            `${process.env.REACT_APP_CORE_API_URI}/web/integrations/napster/createUser`,
            data,
            "",
            "POST"
        );

        if (response.status === 200 && response) {
            return response;
        }

        return null;
    } catch (error) {
        console.error("Error authenticating auto login:", error);
        return null;
    }
}
