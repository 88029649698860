import React, { useState, useMemo } from "react";
import { uniqueId } from "lodash";
import styled from "@emotion/styled";
import { setColor } from "../../../assets/styles/styles";
import { ReactComponent as NotFoundIcon } from "../../../assets/images/icons/NotFound.svg";
import { ReactComponent as DropdownIcon } from "../../../assets/images/icons/Chevron.svg";
import usernameIcon from "../../../assets/images/icons/username.svg";

function IconEye(props) {
    return (
        <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" {...props}>
            <path d="M396 512a112 112 0 10224 0 112 112 0 10-224 0zm546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z" />
        </svg>
    );
}

const DataTableFiles = ({ headers, data, renderRow, openModal, goToRecord, handleOpenModal, handleModalInfo }) => {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };

    // Adding an extra column for buttons
    if (!headers.includes("")) {
        headers.unshift("");
    }

    return (
        <TableContainer>
            <thead>
                <TableRow>
                    {headers.map((header) => {
                        if (typeof header !== "string") {
                            return (
                                <TableHeader key={uniqueId()}>
                                    <p key={uniqueId()}>{header.column_name}</p>
                                </TableHeader>
                            );
                        }
                        return <TableHeader key={uniqueId()}>{header}</TableHeader>;
                    })}
                </TableRow>
            </thead>
            <tbody>
                {data.length === 0 ? (
                    <tr>
                        <td key={uniqueId()} colSpan={headers.length} align="center">
                            <NotFoundImage key={uniqueId("header")} alt="Not Found" />
                        </td>
                    </tr>
                ) : (
                    data.map((row, index) => {
                        return (
                            <StyledTableRow
                                key={uniqueId("table-row")}
                                onClick={() => goToRecord(row)}
                                isSelected={index === selectedRowIndex}
                            >
                                {renderRow(row).map((cell, idx) => {
                                    if (typeof cell === "object" || "boolean") {
                                        // Case of a react element
                                        if (cell) {
                                            if (typeof cell.type === "symbol")
                                                return <TableCell key={uniqueId()}>{cell}</TableCell>;
                                        }
                                        // Arrays and Json object columns
                                        if (idx === 0) {
                                            return (
                                                <React.Fragment key={uniqueId()}>
                                                    <TableCell key={uniqueId()} onClick={(e) => e.stopPropagation()}>
                                                        <div
                                                            key={uniqueId("div")}
                                                            style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleOpenModal();
                                                            }}
                                                            onMouseEnter={(e) => {
                                                                e.stopPropagation();
                                                                handleModalInfo(row);
                                                            }}
                                                        >
                                                            <IconEye
                                                                key={uniqueId("div")}
                                                                color={setColor.iconColor3}
                                                                cursor={"default"}
                                                                height={"20px"}
                                                                width={"20px"}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell key={uniqueId()}>{cell}</TableCell>
                                                </React.Fragment>
                                            );
                                        }

                                        const json = JSON.stringify(cell, getCircularReplacer());
                                        return <TableCell key={uniqueId()}>{json}</TableCell>;
                                    }

                                    return <TableCell key={uniqueId()}>{cell}</TableCell>;
                                })}
                            </StyledTableRow>
                        );
                    })
                )}
            </tbody>
        </TableContainer>
    );
};

export default DataTableFiles;

const TableContainer = styled.table`
    width: 100%;
    height: 70vh;
    overflow-y: none;
    border-collapse: separate;
    border-spacing: 0 15px;
    background-color: transparent;
    overflow-x: auto;
    position: relative;
    @media (max-width: 768px) {
        height: auto;
        overflow-y: auto;
    }
`;

const TableRow = styled.tr`
    margin-bottom: 15px;
    text-align: center;
    height: 50px;
    color: ${setColor.inputText};
`;
const TableHeader = styled.th`
    font-size: 17px;
    padding: 10px;
    text-align: left;
    color: ${setColor.iconColor3};
    background-color: transparent;
`;

const StyledTableRow = styled.tr`
    background-color: ${setColor.onHoverTextcolor2};
    cursor: pointer;
    color: ${setColor.inputText};
    &:hover {
        background-color: ${setColor.accent4};
        color: ${setColor.buttonTextBlack};
    }
`;

const NotFoundImage = styled(NotFoundIcon)`
    width: 300px;
    height: 300px;

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;
const TableCell = styled.td`
    overflow-wrap: break-word;
    font-size: 15px;
    padding: 10px;
    width: auto;
    text-align: left;
    min-width: 60px;
    align-items: center;
    justify-content: center;
    &:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    &:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;
