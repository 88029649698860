import React from "react";
import styled from "styled-components";

const ColorSelection = ({ selectedColor, onSelectColor }) => {
    const colors = ["#C5B7FF", "#FFCDED", "#FFF3B1", "#C7FFC8", "#4E4C55", "#FFFFFF"];

    return (
        <ColorSelectionContainer>
            {colors.map((color, index) => (
                <ColorOption key={index} onClick={() => onSelectColor(color)}>
                    <ColorBoxContainer isSelected={selectedColor === color}>
                        <ColorBox style={{ background: color }} />
                    </ColorBoxContainer>
                </ColorOption>
            ))}
        </ColorSelectionContainer>
    );
};

export default ColorSelection;

const ColorSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 150px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 20px;
    width: 100%;

    @media (max-width: 960px) {
        display: flex;
        flex-direction: column;
    }
`;

const ColorOption = styled.div`
    font-size: 30px;
    cursor: pointer;
`;
const ColorBoxContainer = styled.div`
    cursor: pointer;
    width: 150px;
    height: 150px;
    border-radius: 4px;
    border: ${({ isSelected }) => (isSelected ? "2px solid #f0153f" : "none")};
    display: flex;
    justify-content: center;
    align-items: center;
    background: #23173e;
    margin: 5px;

    @media (max-width: 960px) {
        width: 60px;
        height: 60px;
    }
`;

const ColorBox = styled.div`
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 120px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

    @media (max-width: 960px) {
        width: 50px;
        height: 60px;
    }
`;
