import React from "react";
import styled from "styled-components";

const ToggleSwitch = ({ value, onChange }) => (
    <SwitchContainer isChecked={value}>
        <HiddenCheckbox checked={value} onChange={() => onChange(!value)} />
        <Slider isChecked={value} />
    </SwitchContainer>
);

export default ToggleSwitch;

const SwitchContainer = styled.label`
    position: relative;
    display: inline-block;
    outline: ${(props) => (props.isChecked ? "2px solid #dd42ce" : "2px solid #9da0a7")};
    border-radius: 20px;
    width: 55px;
    height: 25px;
    cursor: pointer;

    @media (max-width: 960px) {
        margin: 0.5rem 0 0 1.5rem;
    }
`;

const Slider = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1b1644;
    transition: background-color 0.4s ease;
    border-radius: 85px;

    &::before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 2px;
        background: ${(props) =>
            props.isChecked
                ? "linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)"
                : "linear-gradient(216deg, #B3B6BE 13.26%, #55575A 91.45%)"};
        transition: transform 0.4s ease;
        border-radius: 50%;
        transform: ${(props) => (props.isChecked ? "translateX(26px)" : "translateX(0)")};
    }

    &::after {
        content: ${(props) =>
            props.isChecked
                ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.4em' height='1.4em' viewBox='0 0 12 12'%3E%3Cpath fill='%233c3a41' d='M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03'/%3E%3C/svg%3E")` // Checkmark icon when on
                : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.4em' height='1.4em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%233D3B4D' stroke-linecap='round' stroke-linejoin='round' stroke-width='3.1' d='m7 7l10 10M7 17L17 7'/%3E%3C/svg%3E")`};
        position: absolute;
        height: ${(props) => (props.isChecked ? "14px" : "14px")};
        width: 7px;
        top: 35%;
        transform: translateY(-50%);
        ${(props) => (props.isChecked ? "left: 3px;" : "right: 20px;")};
        transition: all 0.4s ease;
    }
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    opacity: 0;
    width: 0;
    height: 0;
`;
