import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import ReactCrop from "react-image-crop";
import { setColor, setFont } from "../../../../../assets/styles/styles";
import { putBannerImage, putPersonalizationItem } from "../../../../../api/RoomsController";
import { uploadFile, checkUrlImageSize } from "../../../../../api/FilesController";
import Banner from "../../../../../components/layout/ui/Banner";
import Button from "../../../../../components/common/button/Button";
import Typography from "../../../../../components/common/text/Typography";

import "react-image-crop/dist/ReactCrop.css";

const FILE = "file";
const VIDEO = "video";
const CROP = "crop";
const LINK = "link";

const MediaUploader = ({ frame, typeUpload, setIsOpen, setSuccessBannerMessage, token, userId }) => {
    const placeHolderText =
        frame.frameType === VIDEO
            ? "https://player.vimeo.com/progressive_redirect/playback/1008935729/rendition/720p/file.mp4?loc=external&signature=a48..."
            : "https://www.myimage.com/cats.jpg";
    const [placeholder, setPlaceholder] = useState(placeHolderText);
    const [maxWidth, maxHeight] =
        frame.frameType === "thumbnail" ? ["274", "490"] : frame.recommendedFileSize.split("x");
    const aspect = maxWidth / maxHeight;
    const imageRef = useRef(null);
    const [mode, setMode] = useState(typeUpload || LINK);
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [crop, setCrop] = useState({
        unit: "%",
        x: 0,
        y: 0,
        height: 15,
        maxHeight,
        maxWidth,
        aspect,
    });
    const [src, setSrc] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [openBanner, setOpenBanner] = useState("");
    const [bannerMessage, setBannerMessage] = useState("");
    const [bannerType, setBannerType] = useState("ERROR");

    const runBanner = (message) => {
        setBannerMessage(message);
        setBannerType("ERROR");
        setOpenBanner(true);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 5242880) {
            runBanner("File size must be less than 5 MB.");
            setIsLoading(false);
            return;
        }

        if (!file.type.startsWith("image/")) {
            runBanner("Please select an image file.");
            setIsLoading(false);
            return;
        }

        URL.createObjectURL(file);
        setFile(file);
        setFileName(file.name);

        const reader = new FileReader();
        reader.onload = () => {
            setSrc(reader.result);
        };
        reader.onerror = (error) => {};
        reader.readAsDataURL(file);

        setMode(CROP);
    };

    const isValidUrl = async (url) => {
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?\.(jpg|jpeg|png)$/i;
        if (!urlRegex.test(url)) {
            return false;
        }

        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    const isValidImageFile = (filename) => {
        const fileRegex = /\.(jpg|png|jpeg)$/i;
        return fileRegex.test(filename);
    };

    const isVideoUrl = (url) => {
        const videoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/\d+|player\.vimeo\.com\/.*\.mp4)(\?.*)?$/;
        return videoRegex.test(url);
    };

    const isFileSizeLessThan5MB = (fileSizeInBytes) => {
        const sizeLimitInBytes = 5 * 1024 * 1024; // 5 MB in bytes
        return fileSizeInBytes <= sizeLimitInBytes;
    };

    const onImageLoaded = (image) => {
        imageRef.current = image;
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedImageFile = await getCroppedImg(imageRef.current, crop, "croppedImage.png");
            setCroppedImageUrl(croppedImageFile);
        }
    };

    const handleSaveImage = () => {
        setMode(FILE);
        setFile(croppedImageUrl);
    };

    const returnToFile = () => {
        setMode(FILE);
        clearAllOptions();
    };

    const clearAllOptions = () => {
        setFile(null);
        setCroppedImageUrl(null);
        setFileName(null);
        setSrc(null);

        setInputValue("");
    };

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                const newFile = new File([blob], fileName, {
                    type: "image/png",
                    lastModified: Date.now(),
                });

                resolve(newFile);
            }, "image/png");
        });
    };

    const handleSubmitMainThumbnail = async () => {
        setIsLoading(true);

        if (mode === LINK) {
            const validUrl = await isValidUrl(inputValue);

            if (!validUrl) {
                runBanner("Invalid Url, should contain jpg | jpeg | png extension.");
                setIsLoading(false);
                return;
            }

            const checkUrlSize = await checkUrlImageSize(inputValue);

            if (!checkUrlSize?.data?.valid) {
                runBanner("Invalid Url image size, maximum file size of 5 MB");
                setIsLoading(false);
                return;
            }

            const response = await putBannerImage(
                frame.roomId,
                { bannerImageKey: null, bannerImageExternalUrl: inputValue },
                token
            );

            if (response.status === 200) {
                setSuccessBannerMessage("URL updated");
                setIsOpen(false);
            } else {
                runBanner("Invalid URL provided");
                setIsLoading(false);
            }
        }

        if (mode === FILE) {
            file.usageType = "roomBannerImage";
            if (!isValidImageFile(file.name)) {
                runBanner("There was an error uploading your file. Please use .jpg or .png files");
                setIsLoading(false);
                return;
            }

            if (!isFileSizeLessThan5MB(file.size)) {
                runBanner("Media exceeds maximum file size of 5 MB");
                setIsLoading(false);
                return;
            }

            try {
                const fileUploadedResponse = await uploadFile(
                    { file, usageType: "roomBannerImage", roomId: frame.roomId, targetUserId: userId },
                    token
                );

                const response = await putBannerImage(
                    frame.roomId,
                    { bannerImageKey: fileUploadedResponse.data.id, bannerImageExternalUrl: null },
                    token
                );

                if (response.status === 200) {
                    setSuccessBannerMessage("Changes are live in Intraverse!");
                    setIsOpen(false);
                } else {
                    runBanner("There was an error with uploading your file.");
                }
            } catch (error) {
                console.error(error);
                runBanner("There was an error with uploading your file.");
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        if (frame.isMain) {
            await handleSubmitMainThumbnail();
            return;
        }

        if (mode === LINK) {
            if (frame.frameType === VIDEO) {
                const validVideoUrl = await isVideoUrl(inputValue);

                if (!validVideoUrl) {
                    runBanner("Invalid URL provided. Please provide a Vimeo link");
                    setIsLoading(false);
                    return;
                }
            } else {
                const validUrl = await isValidUrl(inputValue);

                if (!validUrl) {
                    runBanner("Invalid Url, should contain jpg | jpeg | png extension.");
                    setIsLoading(false);
                    return;
                }

                const checkUrlSize = await checkUrlImageSize(inputValue);

                if (!checkUrlSize.data.valid) {
                    runBanner("Invalid Url image size, maximum file size of 5 MB");
                    setIsLoading(false);
                    return;
                }
            }

            const response = await putPersonalizationItem(
                frame.id,
                {
                    isExternalUrl: true,
                    externalUrl: inputValue,
                    fileId: null,
                },
                token
            );

            if (response.status === 204 || response.status === 200) {
                setSuccessBannerMessage("Successfully updated URL");
                setIsOpen(false);
            } else {
                runBanner("Invalid URL Provided");
                setIsLoading(false);
            }
        }

        if (mode === FILE) {
            file.usageType = "roomDetailsImage";

            if (!isValidImageFile(file.name)) {
                runBanner("There was an error uploading your file. Please use .jpg, .jpeg or .png files");
                setIsLoading(false);
                return;
            }

            if (!isFileSizeLessThan5MB(file.size)) {
                runBanner("Media exceeds maximum file size of 5 MB");
                setIsLoading(false);
                return;
            }

            let response = await uploadFile(
                { file, usageType: "roomDetailsImage", roomId: frame.roomId, targetUserId: userId },
                token
            );

            if (response.status === 200) {
                const fileId = response.data.id;
                response = await putPersonalizationItem(
                    frame.id,
                    {
                        isExternalUrl: false,
                        externalUrl: null,
                        fileId,
                    },
                    token
                );
                if (response.status === 204) {
                    setSuccessBannerMessage("Changes are live in Intraverse!");
                    setIsOpen(false);
                }
            } else {
                runBanner("Error Uploading File");
            }

            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (openBanner) {
            const timer = setTimeout(() => {
                setOpenBanner(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [openBanner]);

    return (
        <MediaUploaderContainer>
            <Banner
                message={bannerMessage}
                type={bannerType}
                openBanner={openBanner}
                setOpenBanner={setOpenBanner}
                topDisplay
                left={"0"}
                showCloseButton={false}
                position={"absolute"}
            />

            {mode === CROP ? (
                <>
                    <UserConsoleText style={{ marginTop: "0", textAlign: "center" }}>Crop media</UserConsoleText>
                    <CropContainer>
                        <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={onImageLoaded}
                            onComplete={onCropComplete}
                            onChange={onCropChange}
                            maxWidth={maxWidth}
                            maxHeight={maxHeight}
                            keepSelection
                            aspect={aspect}
                            minWidth={25}
                            minHeight={25}
                        />
                    </CropContainer>
                    <Button
                        variant="PinkButton"
                        onClick={() => handleSaveImage()}
                        label={"Save and Crop"}
                        margin={"15px 0"}
                        style={{ width: "100%" }}
                        onHoverColor="white"
                    />

                    <Typography variant="GreyText" onClick={() => returnToFile()} margin={"0"}>
                        GO BACK
                    </Typography>
                </>
            ) : (
                <>
                    <UserConsoleText style={{ marginTop: "0", textAlign: "center" }}>Update media</UserConsoleText>
                    <SmallSubtitle>
                        Customize your space by uploading an{frame.frameType === VIDEO ? " video " : " image "}or using
                        an existing link.
                    </SmallSubtitle>
                    <ButtonsContainer>
                        <CustomButton
                            isActive={mode === FILE}
                            onClick={() => {
                                clearAllOptions();
                                if (frame.frameType) {
                                    if (frame.frameType === VIDEO) return;
                                }
                                setMode(FILE);
                            }}
                            isVideo={frame.frameType === VIDEO}
                        >
                            Upload File
                        </CustomButton>
                        <CustomButton
                            isActive={mode === LINK}
                            onClick={() => {
                                clearAllOptions();
                                setMode(LINK);
                            }}
                        >
                            Online Link
                        </CustomButton>
                    </ButtonsContainer>

                    {mode === LINK && (
                        <UrlInput
                            placeholder={placeholder}
                            value={inputValue}
                            onFocus={() => setPlaceholder("")}
                            onBlur={() => setPlaceholder(placeHolderText)}
                            onInput={(e) => {
                                setInputValue(e.target.value);
                            }}
                        />
                    )}

                    {mode === FILE && (
                        <FormInputWrapper style={{ cursor: "pointer" }}>
                            <FormInput
                                accept="image/*"
                                required
                                id="fileInput"
                                type="file"
                                name="file"
                                onChange={handleFileChange}
                            />

                            <SvgHolder>
                                {!file ? (
                                    <>
                                        <svg
                                            width="47"
                                            height="30"
                                            viewBox="0 0 47 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M37.5323 11.325C36.2134 4.85625 30.3362 0 23.2759 0C17.6703 0 12.8017 3.075 10.3772 7.575C4.53879 8.175 0 12.9563 0 18.75C0 24.9562 5.21767 30 11.6379 30H36.8534C42.2069 30 46.5517 25.8 46.5517 20.625C46.5517 15.675 42.5754 11.6625 37.5323 11.325ZM27.1552 16.875V24.375H19.3966V16.875H13.5776L23.2759 7.5L32.9741 16.875H27.1552Z"
                                                fill="#BEAEFF"
                                            />
                                        </svg>

                                        <TextFileUpload>Drag or browse a file to upload.</TextFileUpload>
                                    </>
                                ) : (
                                    <>
                                        <TextFileUpload>Selected File</TextFileUpload>
                                        <TextFileUpload>{fileName}</TextFileUpload>
                                    </>
                                )}
                            </SvgHolder>
                        </FormInputWrapper>
                    )}

                    <InfoContainer>
                        <SpanRow>
                            <Text>
                                File Type:{" "}
                                <TextLight>
                                    {(() => {
                                        if (frame.frameType === VIDEO) {
                                            return "Vimeo link";
                                        }
                                        return "PNG, JPEG, JPG";
                                    })()}
                                </TextLight>
                            </Text>
                        </SpanRow>
                        <SpanRow>
                            <Text>
                                Recommended size:
                                <TextLight>
                                    {frame.frameType === "thumbnail" ? "490 x 274" : frame.recommendedFileSize} pixels
                                </TextLight>
                            </Text>
                        </SpanRow>
                        <SpanRow>
                            <Text>
                                Maximun file size:
                                <TextLight>5 MB</TextLight>
                            </Text>
                        </SpanRow>
                    </InfoContainer>
                    <Button
                        variant="PinkButton"
                        loading={isLoading}
                        onClick={() => handleSubmit()}
                        label={"Add Media"}
                        margin={"15px 0 0 0"}
                        style={{ width: "100%" }}
                        disabled={!inputValue && !file && !isLoading}
                    />
                </>
            )}
        </MediaUploaderContainer>
    );
};

const CropContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 25px 0;
`;

const UserConsoleText = styled.h1`
    color: ${setColor.iconColor3};
    font-family: "Barlow Condensed", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 1rem 0rem;
`;

const MediaUploaderContainer = styled.div``;

const FormInputWrapper = styled.div`
    border-radius: 5px;
    margin-top: 25px;
    border: 2.5px dashed ${setColor.greyBackground};
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 2;
`;

const SvgHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
`;

const FormInput = styled.input`
    height: 100px;
    opacity: 0;
    width: 100%;
    z-index: 4;
    cursor: pointer;
`;

const TextFileUpload = styled.h3`
    font-family: ${setFont.barlow};
    color: ${setColor.iconColor};
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
`;

const UrlInput = styled.input`
    margin: 20px 0px;
    width: 100%;
    height: 50px;
    background-color: transparent;
    padding-left: 8px;
    border: 0.2px solid white;
    border-radius: 5px;
    color: white;
    padding-left: 10px;
    box-sizing: border-box;
`;

const SmallSubtitle = styled.p`
    text-align: center;
    color: ${setColor.circularProgress};
    font-family: "Barlow", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 10px;
    padding-bottom: 5px;

    & > span {
        color: ${setColor.iconColor3};
        font-weight: 700;
    }
`;

const InfoContainer = styled.div`
    background-color: ${setColor.greyBackground};
    margin-top: 15px;
    box-sizing: border-box;
    padding: 10px;
`;

const SpanRow = styled.span`
    font-size: 16px;
    display: flex;
    color: ${setColor.iconColor};
`;

const Text = styled.strong`
    display: flex;
    font-size: 16px;
`;

const TextLight = styled.p`
    padding-left: 5px;
    font-weight: 400;
`;

const ButtonsContainer = styled.div`
    display: flex;
    font-size: 20px;
    font-family: ${setFont.barlow};
    justify-content: space-between;
`;

const CustomButton = styled.div`
    display: flex;
    background-color: ${(props) => (props.isActive ? `${setColor.iconColor3}` : "")};
    background-color: ${(props) => (props.isVideo ? `grey` : "")};
    border: ${(props) => (props.isActive ? `1px solid` : "1px solid #2B2A38")};
    color: ${(props) => (props.isActive ? `${setColor.inputBgColor}` : `${setColor.iconColor}`)};
    font-weight: ${(props) => (props.isActive ? "700" : "400")};
    font-family: ${setFont.barlow};
    width: 285px;
    height: 64px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
`;

export default MediaUploader;
