import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import UserReducer from "./reducers/UserReducer";
import ProjectReducer from "./reducers/ProjectReducer";
import ProductReducer from "./reducers/ProductReducer";
import MerchantReducer from "./reducers/MerchantReducer";
import VanityLinkReducer from "./reducers/VanityLinkReducer";
import SpotifyReducer from "./reducers/SpotifyReducer";

const persistConfig = {
    key: "user",
    storage,
};

const reducers = combineReducers({
    user: UserReducer,
    projects: ProjectReducer,
    products: ProductReducer,
    merchant: MerchantReducer,
    vanityLink: VanityLinkReducer,
    spotify: SpotifyReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

// Configurestore already adds the thunk middleware by default:
const createdStore = configureStore({
    reducer: persistedReducer,
});
const createdPersistor = persistStore(createdStore);

export const store = createdStore;
export const persistor = createdPersistor;
