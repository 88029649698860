const defaultState = {
    token: null,
    refreshToken: null,
    player: null,
};

const SpotifyReducer = (state, action) => {
    switch (action.type) {
        case "SPOTIFY_SAVE": {
            return {
                ...state,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken,
                player: action.payload.player,
            };
        }

        case "SPOTIFY_SAVE_PLAYER": {
            return {
                ...state,
                player: action.payload.player,
            };
        }

        case "UPDATE_SPOTIFY": {
            return {
                ...state,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken,
                player: action.payload.player,
            };
        }

        case "SPOTIFY_DELETE": {
            return defaultState;
        }

        default:
            if (state === undefined) {
                return defaultState;
            }
            return state;
    }
};

export default SpotifyReducer;
