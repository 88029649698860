import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import PasswordInput from "./PasswordInput";
import Title from "../../../components/common/text/Title";
import Description from "../../../components/common/text/Description";
import Banner from "../../../components/layout/ui/Banner";

const Body = (props) => (
    <BodyContainer>
        <TextContainer>
            <Title text={props.broadcastTitle} />
            <Description text={props.broadcastDescription} />
        </TextContainer>
        <PasswordInput
            placeHolder={props.broadcastInputPlaceholder}
            value={props.password}
            setValue={props.setPassword}
            handleInputKeyDown={props.handleInputKeyDown}
        />
        <Divider />
        <Banner
            message={
                props.openBanner === "ERROR"
                    ? "The password you entered is incorrect. Please try again."
                    : "You are now broadcasting to all guests in this session."
            }
            type={props.openBanner}
            openBanner={props.openBanner}
            setOpenBanner={props.setOpenBanner}
            topDisplay={false}
            showCloseButton={false}
        />
    </BodyContainer>
);
export default Body;

Body.propTypes = {
    openBanner: PropTypes.any,
    setOpenBanner: PropTypes.any,
};

const Divider = styled.div`
    margin-bottom: 25px;
    @media (max-width: 960px) {
        margin-bottom: 15px;
    }
`;

const BodyContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 0 2.5rem;
    @media (max-width: 960px) {
        padding: 15px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    @media (max-width: 960px) {
        margin-bottom: 30px;
    }
`;
