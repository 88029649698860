import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { isMobileDevice } from "../../utils/generalUtils";
import CornerIconButton from "../../components/common/button/CornerIconButton";
import BroadCasterModal from "./components/BroadcasterModal";
import { getBroadcastingToken } from "./services/BroadcastController";
import Banner from "../../components/layout/ui/Banner";
import { sendEventsLog } from "../../api/UserController";
import { fetchUserToken, fetchUserId } from "../../redux/slices/users";
import { actionTypes } from "../../utils/actionTypes";
import { getFromSessionStorage } from "../../hooks/useSessionStorage";
import { LOG_EVENTS_BROADCAST_STARTED } from "../../constants";

const BroadCast = ({
    openBroadcastModal,
    setOpenBroadcastModal,
    participant,
    setParticipant,
    broadCaster,
    setBroadCaster,
    roomName,
}) => {
    const userToken = useSelector(fetchUserToken);
    const userId = useSelector(fetchUserId);
    const isMobile = isMobileDevice();
    const [participantBanner, setParticipantBanner] = useState("");
    const [audioContext, setAudioContext] = useState(null);
    const [streamDestination, setStreamDestination] = useState(null);
    const [room, setRoom] = useState(null);
    const [isMuted, setIsMuted] = useState(false);
    const audioElements = [];
    const rooms = {};

    useEffect(() => {
        localStorage.setItem("currentIdentity", null);

        getBroadcastingToken(roomName)
            .then((res) => {
                if (res) {
                    startListening(res);
                }
            })
            .catch((error) => {
                console.error(error);
            });

        return () => {
            disconnect();
        };
    }, []);

    const subscribeToTrack = (publication) => {
        publication.on("subscribed", (track) => {
            const audioElement = track.attach();
            document.body.appendChild(audioElement);
            audioElements.push(audioElement);
        });
    };

    const updateParticipantStatus = () => {
        if (participant === "") {
            setParticipant(true);
            setParticipantBanner(true);
        }
    };

    const startListening = async (validToken) => {
        rooms[roomName] = new window.LivekitClient.Room();
        await rooms[roomName].connect(process.env.REACT_APP_BROADCAST_ROOM_ID, validToken);

        const { remoteParticipants } = rooms[roomName];
        const currentId = localStorage.getItem("currentIdentity");
        remoteParticipants.forEach((participant) => {
            if (participant.identity.startsWith("broadcaster-") && participant.identity !== currentId) {
                participant.on("trackPublished", subscribeToTrack);
                updateParticipantStatus();
                participant.trackPublications.forEach((publication) => {
                    if (publication.isSubscribed) {
                        const { track } = publication;
                        const audioElement = track.attach();
                        document.body.appendChild(audioElement);
                    } else {
                        subscribeToTrack(publication);
                    }
                });
            }
        });

        rooms[roomName].on("participantConnected", (participant) => {
            const currentId = localStorage.getItem("currentIdentity");

            if (participant.identity.startsWith("broadcaster-") && participant.identity !== currentId) {
                participant.on("trackPublished", subscribeToTrack);
                updateParticipantStatus();
                participant.trackPublications.forEach((publication) => {
                    if (publication.isSubscribed) {
                        const { track } = publication;
                        const audioElement = track.attach();
                        document.body.appendChild(audioElement);
                    } else {
                        subscribeToTrack(publication, audioElements);
                    }
                });
            }
        });

        rooms[roomName].on("trackSubscribed", () => {
            const token = getFromSessionStorage("userToken") || userToken;
            sendEventsLog(
                token,
                roomName,
                userId,
                LOG_EVENTS_BROADCAST_STARTED,
                "",
                { listenerCount: rooms[roomName]?.numParticipants, room: roomName },
                "",
                ""
            );
        });

        rooms[roomName].on("trackUnsubscribed", () => {
            setParticipant("");
            setParticipantBanner("");
        });

        setRoom(rooms[roomName]);
    };

    const muteMicrophone = () => {
        room.localParticipant.trackPublications.forEach((publication) => {
            if (publication.track) {
                if (!isMuted) {
                    publication.track.mute();
                } else {
                    publication.track.unmute();
                }
                setIsMuted((mute) => !mute);
            }
        });
    };

    const reconnect = () => {
        if (room) {
            room.startAudio();
        }
    };

    const disconnect = () => {
        if (room) {
            localStorage.setItem("currentIdentity", null);
            room.disconnect();
        }

        if (streamDestination && audioContext) {
            const tracks = streamDestination.stream.getTracks();
            tracks.forEach((track) => track.stop());
            audioContext.close();
        }
        setAudioContext(null);
        setStreamDestination(null);
        setBroadCaster(false);
        setRoom(null);
        setIsMuted(false);
    };

    return (
        <>
            {broadCaster && !openBroadcastModal && (
                <MicrophoneButton>
                    <CornerIconButton
                        iconSize={"250%"}
                        mobileSize={"150%"}
                        iconColor="white"
                        activeIcon="clarity:microphone-solid"
                        unActiveIcon="clarity:microphone-mute-solid"
                        func={muteMicrophone}
                    />
                </MicrophoneButton>
            )}
            <Banner
                width={isMobile ? "420px" : "auto"}
                zIndex="3000"
                message={"A user is now broadcasting their audio. If you can not hear, CLICK HERE to reconnect."}
                type={"MESSAGE"}
                openBanner={participantBanner}
                setOpenBanner={setParticipantBanner}
                topDisplay
                showCloseButton
                gameScreen
                func={reconnect}
            />
            <Banner
                width={"auto"}
                zIndex="3000"
                message={"You are broadcasting to all guests in this session."}
                type={"MESSAGE"}
                openBanner={broadCaster && !openBroadcastModal}
                setOpenBanner={setBroadCaster}
                topDisplay
                showCloseButton={false}
                gameScreen
            />
            {/* <BroadCastButton type="button" onClick={() => setOpenBroadcastModal((value) => !value)}>
        CONNECT BROADCAST HERE
      </BroadCastButton> */}
            {openBroadcastModal && (
                <BroadCasterModal
                    openBroadcastModal={openBroadcastModal}
                    broadCaster={broadCaster}
                    setBroadCaster={setBroadCaster}
                    setOpenBroadcastModal={setOpenBroadcastModal}
                    audioContext={audioContext}
                    setAudioContext={setAudioContext}
                    streamDestination={streamDestination}
                    setStreamDestination={setStreamDestination}
                    setRoom={setRoom}
                    disconnect={disconnect}
                    roomName={roomName}
                />
            )}
        </>
    );
};
export default BroadCast;

const BroadCastButton = styled.button`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    @media (max-width: 960px) {
        height: 60px;
    }
    @media (max-width: 400px) {
        height: 7%;
    }
`;

const MicrophoneButton = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1000;
    transform: ${(props) => (props.landscape ? "rotate(-90deg)" : "none")};
    top: 0;
    left: 0;
`;
