import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { setColor } from "../../../../../assets/styles/styles";
import Typography from "../../../../../components/common/text/Typography";
import { default as Button } from "../../../../../components/common/button/Button";
import { updateRole } from "../api/rolesPermissionsController";
import { ReactComponent as DropdownIcon } from "../../../../../assets/images/icons/Chevron.svg";
import Banner from "../../../../../components/layout/ui/Banner";

const EditModal = ({ roleId, roleName, closeEditModal }) => {
    const [successMessageBanner, setSuccessMessageBanner] = useState("");
    const [name, setName] = useState(roleName);

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeEditModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);

    const EditRoleClick = async () => {
        const response = await updateRole(roleId, name);
        if (response.status === 200) {
            setSuccessMessageBanner("Role Updated Successfully");
        }
    };

    return (
        <>
            <ModalContent>
                <Banner
                    width={"100%"}
                    zIndex="1000"
                    message={`${successMessageBanner}`}
                    type={"SUCCESS"}
                    openBanner={!!successMessageBanner}
                    setOpenBanner={setSuccessMessageBanner}
                    topDisplay
                    showCloseButton
                    gameScreen={false}
                    top={"0px"}
                    left={"0"}
                />
                <GeneralContainer>
                    <Typography variant="h2PurpleHeading" fontSize={"24px"} margin={`1rem 0 0 0`}>
                        Edit Role
                    </Typography>

                    <Card>
                        <Table>
                            <TableRow>
                                <TableColumn>
                                    <Label>Role Id</Label>
                                    <InputBox type="text" id="id" value={roleId} disabled />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Role Name</Label>
                                    <InputBox
                                        type="text"
                                        id="name"
                                        value={name}
                                        placeholder="Enter Role Name"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <Button
                                    variant={"PinkButton"}
                                    buttonWidth={"100%"}
                                    disabled={name === ""}
                                    label={"Edit Role"}
                                    onClick={EditRoleClick}
                                />
                            </TableRow>
                        </Table>
                    </Card>
                </GeneralContainer>
            </ModalContent>
        </>
    );
};

export default EditModal;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: "Barlow-Regular", Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;
const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? "1rem" : "0rem")};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;
const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;
