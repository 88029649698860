import React, { useState } from "react";
import styled from "styled-components";
import { setColor } from "../../../../assets/styles/styles";
import Typography from "../../../../components/common/text/Typography";
import Button from "../../../../components/common/button/Button";
import { device } from "../../../../assets/styles/mediaBreakpoints";
import ChatIcon from "../assets/icons/UI_Website_Graphic_Chat.png";
import EmoteIcon from "../assets/icons/UI_Website_Graphic_Emote.png";
import MusicIcon from "../assets/icons/UI_Website_Graphic_Music.png";
import RewardsIcon from "../assets/icons/UI_Website_Graphic_Rewards.png";
import Modal from "../../../console/components/Modal";
import EmailInputModal from "../../components/EmailInputModal";
import { isMobileDevice } from "../../../../utils/generalUtils";

const FeatureSection = () => {
    const isMobile = isMobileDevice();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Modal isOpen={openModal} setIsOpen={setOpenModal} children={<EmailInputModal />} />
            <SectionBackground>
                <SectionContainer>
                    <Feature>
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                <Highlighted>Design</Highlighted> Your Persona
                            </StyledTypographyTitle>
                            <StyledTypographyDescription variant="pWhite">
                                Personalize yourself with our{" "}
                                <HighlightedDescription>collection of avatars.</HighlightedDescription> Stay casual or
                                select from various music-themed outfits!
                            </StyledTypographyDescription>
                            <Button
                                variant="VanityLinkCustomizable"
                                label="Design An Avatar"
                                onClick={() => setOpenModal(true)}
                                style={{ borderRadius: "10px", width: "fit-content" }}
                            />
                        </FeatureTextContainer>
                        <FeatureImageContainer>
                            <FeatureVideo muted autoPlay loop style={{ marginLeft: "175px" }}>
                                <source
                                    type="video/mp4"
                                    src={"/videos/landingPages/Live_Design_Your_Persona_Feathered.mp4"}
                                />
                            </FeatureVideo>
                        </FeatureImageContainer>
                    </Feature>
                    <Feature>
                        {!isMobile && (
                            <FeatureImageContainer>
                                <FeatureVideo muted autoPlay loop style={{ marginRight: "75px" }}>
                                    <source type="video/mp4" src={"/videos/landingPages/Live_Room_Templates.mp4"} />
                                </FeatureVideo>
                            </FeatureImageContainer>
                        )}
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                <Highlighted>Create</Highlighted> Your Own Room
                            </StyledTypographyTitle>
                            <StyledTypographyDescription variant="pWhite">
                                Get your own apartment, decorated in a theme that{" "}
                                <HighlightedDescription>resonates with you</HighlightedDescription>! As we grow, so will
                                the customizable features to make this space your own.
                            </StyledTypographyDescription>
                            <Button
                                variant="VanityLinkCustomizable"
                                label="Start Customizing"
                                onClick={() => setOpenModal(true)}
                                style={{ borderRadius: "10px", width: "fit-content" }}
                            />
                        </FeatureTextContainer>
                        {isMobile && (
                            <FeatureImageContainer>
                                <FeatureVideo muted autoPlay loop>
                                    <source type="video/mp4" src={"/videos/landingPages/Live_Room_Templates.mp4"} />
                                </FeatureVideo>
                            </FeatureImageContainer>
                        )}
                    </Feature>
                    <Feature>
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                <Highlighted>Socialize</Highlighted> And Vibe
                            </StyledTypographyTitle>
                            <StyledTypographyDescription variant="pWhite">
                                Whether you enjoy making new friends, jamming to music, or engaging with your fav
                                creator, <HighlightedDescription>we have something for you</HighlightedDescription>!
                            </StyledTypographyDescription>
                            <IconSection>
                                <SocialLogoBlock>
                                    <SocialLogo src={ChatIcon} alt="Chat Icon" />
                                    <Typography
                                        variant="pWhite"
                                        style={{
                                            margin: "0",
                                            fontWeight: "400",
                                            textTransform: "uppercase",
                                            fontSize: "9px",
                                        }}
                                    >
                                        Chat
                                    </Typography>
                                </SocialLogoBlock>
                                <SocialLogoBlock>
                                    <SocialLogo src={MusicIcon} alt="Chat Icon" />
                                    <Typography
                                        variant="pWhite"
                                        style={{
                                            margin: "0",
                                            fontWeight: "400",
                                            textTransform: "uppercase",
                                            fontSize: "9px",
                                        }}
                                    >
                                        Music and Videos
                                    </Typography>
                                </SocialLogoBlock>
                                <SocialLogoBlock>
                                    <SocialLogo src={EmoteIcon} alt="Emote Icon" />
                                    <Typography
                                        variant="pWhite"
                                        style={{
                                            margin: "0",
                                            fontWeight: "400",
                                            textTransform: "uppercase",
                                            fontSize: "9px",
                                        }}
                                    >
                                        Emote
                                    </Typography>
                                </SocialLogoBlock>
                                <SocialLogoBlock>
                                    <SocialLogo src={RewardsIcon} alt="Rewards Icon" />
                                    <Typography
                                        variant="pWhite"
                                        style={{
                                            margin: "0",
                                            fontWeight: "400",
                                            textTransform: "uppercase",
                                            fontSize: "9px",
                                        }}
                                    >
                                        Rewards
                                    </Typography>
                                </SocialLogoBlock>
                            </IconSection>
                            <Button
                                variant="VanityLinkCustomizable"
                                label="Discover More"
                                onClick={() => setOpenModal(true)}
                                style={{ borderRadius: "10px", width: "fit-content" }}
                            />
                        </FeatureTextContainer>
                        <FeatureImageContainer style={{ marginLeft: "75px" }}>
                            <FeatureVideo muted autoPlay loop>
                                <source type="video/mp4" src={"/videos/landingPages/Live_Socialize.mp4"} />
                            </FeatureVideo>
                        </FeatureImageContainer>
                    </Feature>
                </SectionContainer>
            </SectionBackground>
        </>
    );
};

export default FeatureSection;

const StyledTypographyTitle = styled(Typography)`
    font-size: 3.2rem;
    text-align: left;

    @media (max-width: 960px) {
        text-align: center;
        margin-bottom: 0;
    }
`;

const StyledTypographyDescription = styled(Typography)`
    text-align: left;
    margin-top: 0;
    margin-left: 0;
    line-height: 1.4;
    font-size: 19px;

    @media (max-width: 960px) {
        text-align: center;
        font-size: 16px;
        margin-right: 0;
        margin: 20px 0;
    }
`;

const IconSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 15px;

    @media (max-width: 950px) {
        gap: 10px;
        justify-content: center;
    }
`;

const SocialLogoBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 85px;
    width: 85px;

    background: ${setColor.accent4}1a;
    border-radius: 20px;
    padding: 10px;
    gap: 10px;

    @media (max-width: 950px) {
        height: 75px;
        width: 75px;
        gap: 0;
        padding: 5px;
    }
`;

const SocialLogo = styled.img`
    height: 30px;
    width: 30px;
`;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;

const HighlightedDescription = styled.span`
    color: ${setColor.yellowText};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #15151b;
`;

const SectionContainer = styled.div`
    max-width: 1300px;
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 50px;

    @media ${device.desktopWide} {
        padding: 75px 0;
    }

    @media (max-width: 960px) {
        width: 90%;
    }
`;

const Feature = styled.div`
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 950px) {
        flex-direction: column;
        height: auto;
        gap: 15px;
    }
`;

const FeatureTextContainer = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    @media (max-width: 950px) {
        width: 100%;
        align-items: center;
    }
`;

const FeatureImageContainer = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 960px) {
        width: 100%;
        justify-content: center;
        margin-left: 0 !important;
    }
`;

const FeatureVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 960px) {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
`;
