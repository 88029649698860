import { useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import { setColor, setFont } from "../../../assets/styles/styles";

export default function SearchBar({
    width = "100%",
    onChange,
    backgroundColor = setColor.hightlight,
    borderColor = setColor.borderColor,
    color = setColor.textGray,
    placeholder = "Search Campaign",
    isOpen = false,
    ...props
}) {
    const [searchText, setSearchText] = useState("");

    const handleChange = (e) => {
        setSearchText(e.target.value);
        if (onChange) onChange(e.target.value);
    };

    const handleClear = () => {
        setSearchText("");
        if (onChange) onChange("");
    };

    return (
        <SearchBarContainer width={width} backgroundColor={backgroundColor} borderColor={borderColor}>
            <SearchIconWrapper>
                <SearchIcon sx={{ color: searchText ? setColor.accent4 : setColor.textGray }} />
            </SearchIconWrapper>
            <Input
                type="search"
                value={searchText}
                onChange={handleChange}
                placeholder={placeholder}
                backgroundColor={backgroundColor}
                color={color}
                isopen={isOpen ? "true" : "false"}
                {...props}
            />
            {searchText && (
                <ClearIconWrapper onClick={handleClear}>
                    <ClearIcon sx={{ color: setColor.accent4 }} />
                </ClearIconWrapper>
            )}
        </SearchBarContainer>
    );
}

SearchBar.propTypes = {
    borderColor: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
};

const SearchBarContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 1rem 0rem 0rem;
    border-radius: 3px;
    border: 1px solid ${setColor.modalOutline};
    position: relative; /* Added to position icons inside the input field */
    width: ${(props) => props.width};
`;

const SearchIconWrapper = styled.div`
    position: absolute;
    margin: 0.3rem 10rem 0rem 0.7rem;
    pointer-events: none;
`;

const ClearIconWrapper = styled.div`
    position: absolute;
    right: 10px;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
`;

const Input = styled.input`
    padding: 10px 10px 10px 35px;
    margin: 0 0.6rem;
    background-color: transparent;
    color: ${setColor.accent4};
    font-family: ${setFont.barlowCondensed};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    border: none;
    width: 100%;
    &:focus {
        outline: none;
    }
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;
