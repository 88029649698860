import React, { useEffect, useState, useCallback, useMemo } from "react";
// import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { API_GET } from "../../config/axiosConfig";
import VagonStreamIntraverse from "./VagonStreamIntraverse";
import { getFromSessionStorage } from "../../hooks/useSessionStorage";
import { isMobileDevice } from "../../utils/generalUtils";
// import { RegisterUserWithLionGaming } from '../lionGaming/apiController';

const VanityURLRedirects = (props) => {
    const [redirectURL, setRedirectURL] = useState(null);
    // const email = useSelector((state) => state.user.email);
    const resolvedPathId = props.pathId || window.location.pathname.split("/").pop();
    const currentPath = window.location.href;

    const launchFlags = useMemo(
        () => ({
            "-userid": props.userId,
            "-authtoken": props.token || getFromSessionStorage("userToken"),
            "-api": process.env.REACT_APP_STREAM_API_ENV,
            "-disableVoiceChat": false,
            "-showdebugger": true,
            "-userdevicetype": isMobileDevice() ? "mobile" : "desktop",
            "-avatarpreset": getFromSessionStorage("selectedAvatar") || props.finalAvatar,
            "-url": encodeURIComponent(currentPath),
            "-disablecameramouserotation": true,
        }),
        [props.userId, props.token, currentPath, props.finalAvatar]
    );

    const fetchVanityLink = useCallback(async (id) => {
        try {
            // const response = await API_GET(`https://core-api-dev.intraverse.com/core-api/web/vanity_links/${id}`);
            const response = id ? await API_GET(`/web/vanity_links/${id}`) : { data: {} };
            const { roomId = "", staticUrl = process.env.REACT_APP_DEFAULT_STREAM_URL } = response.data;

            // if (id === 'tz-casino') {
            //     const response = await RegisterUserWithLionGaming(email);
            //     if (response.status !== 200) {
            //         console.error('Error registering user with Lion Gaming:', response.data);
            //     }
            // }

            if (roomId !== "") {
                launchFlags["-roomid"] = roomId;
            } else {
                delete launchFlags["-roomid"];
            }

            const queryString = Object.entries(launchFlags)
                .map(([key, value]) => `${key}%20${value}`)
                .join("%20");
            const redirectURL = `${staticUrl}?launchFlags=${queryString}`;
            setRedirectURL(redirectURL);
        } catch (error) {
            if (process.env.REACT_APP_REDIRECT_TOGGLE) {
                window.location.replace("https://intraverse.com/");
            }
            console.error("Error fetching the vanity link:", error);
        }
    }, []);

    useEffect(() => {
        fetchVanityLink(resolvedPathId);
    }, [resolvedPathId, fetchVanityLink]);

    return redirectURL && <VagonStreamIntraverse streamUrl={redirectURL} spotifyPlayer={props?.spotifyPlayer} />;
};

export default VanityURLRedirects;

VanityURLRedirects.propTypes = {
    spotifyPlayer: PropTypes.object,
    userId: PropTypes.string,
    token: PropTypes.string,
    finalAvatar: PropTypes.string,
};
