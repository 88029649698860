import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "../../../assets/styles/index.css";
import { breakpoints } from "../../../assets/styles/mediaBreakpoints";
import { setColor, setFont } from "../../../assets/styles/styles";

const Typography = ({
    children,
    onClick,
    variant,
    className,
    color,
    align,
    style,
    fontFamily,
    FontWeight,
    fontSize,
    margin,
}) => {
    const Tag = variants[variant] || variants.body;

    return (
        <Tag
            className={className}
            onClick={onClick}
            fontFamily={fontFamily}
            fontSize={fontSize}
            FontWeight={FontWeight}
            style={style}
            margin={margin}
            color={color}
            align={align}
        >
            {children}
        </Tag>
    );
};

const variants = {
    h1: styled.h1`
        font-size: ${(props) => (props.fontSize ? props.fontSize : `75px`)};
        font-family: ${(props) => (props.fontFamily ? props.fontFamily : `'Barlow Condensed', sans-serif`)};
        color: ${(props) => (props.color ? props.color : `black`)};

        @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
            font-size: 35px;
        }
        /* additional styles if needed */
    `,
    h1UserHeader: styled.h1`
        font-size: ${(props) => (props.fontSize ? props.fontSize : `75px`)};
        font-family: ${(props) => (props.fontFamily ? props.fontFamily : `'Barlow Condensed', sans-serif`)};
        font-weight: 700;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
            font-size: 35px;
        }
        /* additional styles if needed */
    `,
    h2: styled.h2`
        /* additional styles if needed */
    `,
    h2PurpleHeading: styled.h2`
        color: ${(props) => (props.color ? props.color : `${setColor.accent4}`)};
        margin: ${(props) => (props.margin ? props.margin : `30px 0 0 0`)};
        text-align: center;
        font-family: "Barlow Condensed", sans-serif;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        font-size: ${(props) => (props.fontSize ? props.fontSize : `36px`)};

        @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
            font-size: ${(props) => (props.fontSize ? props.fontSize : `35px`)};
        }
    `,
    h3: styled.h3`
        /* additional styles if needed */
    `,
    h4: styled.h4`
        /* additional styles if needed */
    `,
    h5: styled.h5`
        /* additional styles if needed */
    `,
    h6: styled.h6`
        /* additional styles if needed */
    `,
    p: styled.p`
        font-size: 16px;
        @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
            font-size: 10px;
        }
        /* additional styles if needed */
    `,
    pWhite: styled.p`
        color: ${(props) => (props.color ? props.color : `#ced1d9`)};
        text-align: ${(props) => (props.align ? props.align : `center`)};
        font-family: ${setFont.barlow};
        font-size: ${(props) => (props.fontSize ? props.fontSize : `20px`)};
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: ${(props) => (props.margin ? props.margin : `30px 10px`)};

        @media (max-height: 768px) {
            font-size: 16px;
        }
    `,
    ConsoleTitle: styled.h1`
        width: auto;
        font-family: ${setFont.barlowCondensed};
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        margin: ${(props) => (props.margin ? props.margin : `30px 0`)};
        background: linear-gradient(45deg, rgba(240, 21, 63, 1) 10%, rgba(221, 66, 206, 1) 40%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
    `,
    ConsoleDescription: styled.p`
        color: ${setColor.circularProgress};
        font-family: ${setFont.barlow};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin: ${(props) => (props.margin ? props.margin : `0`)};
        text-align: ${(props) => (props.align ? props.align : `left`)};
        & > span {
            color: ${setColor.accent4};
            font-weight: 700;
        }
    `,
    UsernameText: styled.h3`
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        margin-bottom: 10px;
        color: ${setColor.inputText};
        font-family: ${setFont.barlow};
        font-size: 24px;
        font-style: normal;
        font-weight: 700;

        & img {
            margin-right: 15px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;

            & img {
                margin-right: -35px;
            }

            & p {
                margin-left: 40px;
            }
        }
    `,
    UsernameSubtitle: styled.p`
        text-align: left;
        padding-right: 40px;
        color: ${setColor.inputText};
        font-family: ${setFont.barlow};
        font-size: 18px;
        font-style: normal;
        font-weight: 500;

        @media (max-width: 768px) {
            padding-right: 0px;
            text-align: center;
        }
    `,
    GreyText: styled.p`
        cursor: pointer;
        color: ${setColor.iconColor};
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: ${setFont.barlow};
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        margin: ${(props) => (props.margin ? props.margin : `30px 0`)};
    `,
    PinkText: styled.p`
        font-family: "Barlow", Bold;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        width: fit-content;
        background: ${setColor.pinkButtonBgGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        margin: ${(props) => (props.margin ? props.margin : `0px`)};
    `,
    PinkHeading: styled.h1`
        margin: ${(props) => (props.margin ? props.margin : "0")};
        font-family: Signika Negative;
        wordwrap: break-word;
        font-weight: 700;
        line-height: normal;
        width: fit-content;
        background: ${setColor.pinkButtonBgGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
    `,
    LandingPageTitle: styled.h1`
        text-align: center;
        font-family: ${setFont.barlowCondensed}, sans-serif;
        font-size: 4rem;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${setColor.nextStepText};
        margin-bottom: 1rem;

        @media (max-width: 960px) {
            font-size: 2.5rem !important;
        }

        @media (min-width: 960px) and (max-width: 1024px) {
            font-size: 4rem;
        }
    `,
    LandingPageTSubtitle: styled.h3`
        text-align: center;
        font-family: ${setFont.barlowCondensed}, sans-serif;
        font-size: 1.85rem;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: ${setColor.nextStepText};
        margin-bottom: 1rem;

        @media (max-width: 960px) {
            font-size: 2rem;
        }

        @media (min-width: 960px) and (max-width: 1024px) {
            font-size: 4rem;
        }
    `,
    guestbookHeader: styled.h3`
        color: ${(props) => (props.color ? props.color : `#BEAEFF`)};
        font-family: ${setFont.barlow}, sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.6px;
        text-transform: uppercase;

        @media (max-width: 700px) {
            font-size: 14px;
            padding: 1rem;
        }
    `,
    guestbookInner: styled.h3`
        color: ${(props) => (props.color ? props.color : `rgba(190, 174, 255, 0.50)`)};
        text-align: center;
        font-family: ${setFont.signikaNegative};
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media (max-width: 700px) {
            font-size: 20px;
        }
    `,
    guestbookP: styled.h3`
        color: ${(props) => (props.color ? props.color : `#ced1d9`)};
        text-align: ${(props) => (props.align ? props.align : `left`)};
        font-family: ${(props) => (props.fontFamily ? props.fontFamily : `${setFont.barlow}`)};
        font-size: ${(props) => (props.fontSize ? props.fontSize : `26px`)};
        line-height: normal;
        margin: ${(props) => (props.margin ? props.margin : `30px 10px`)};
        font-style: normal;
        font-weight: ${(props) => (props.FontWeight ? props.FontWeight : `400`)};

        @media (max-width: 700px) {
            font-size: 20px;
        }
    `,
};

export default Typography;

Typography.propTypes = {
    variant: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.object,
    fontFamily: PropTypes.string,
    FontWeight: PropTypes.string,
    fontSize: PropTypes.string,
    margin: PropTypes.string,
    align: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.any,
};
