import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { setFont } from "../../../assets/styles/styles";

const Description = ({ text }) => <PageDescription>{text}</PageDescription>;
export default Description;

Description.propTypes = {
    text: PropTypes.string.isRequired,
};

const PageDescription = styled.div`
    margin-top: 10px;
    font-size: 1.5em;
    color: white;
    font-family: ${setFont.barlow};
    letter-spacing: 0.05em;
    font-weight: 50;

    @media (max-width: 960px) {
        margin-top: 10px;
        font-size: 1em;
        text-align: center;
    }
`;
