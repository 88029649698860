import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { setColor } from "../../../../assets/styles/styles";
import Button from "../../../../components/common/button/Button";
import { device } from "../../../../assets/styles/mediaBreakpoints";
import Typography from "../../../../components/common/text/Typography";

const ButtonSection = ({ videoUrl }) => {
    // Share via email
    const shareEmail = () => {
        const recipientEmail = "activate@terrazero.com";
        const subject = "I would like to learn more about Intraverse Pro";
        const body = "";

        window.location.href = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    const linkToCalendly = () => {
        window.open("https://calendly.com/intraversepro", "_blank");
    };

    return (
        <VideoContainer>
            <video
                style={{
                    zIndex: "-500",
                    height: "100vh",
                    width: "100vw",
                    objectFit: "cover",
                    position: "absolute",
                    top: "0",
                    left: "0",
                }}
                controls={false}
                muted
                autoPlay
                loop
            >
                <source src={videoUrl} type="video/mp4" />
            </video>
            <HeroBackgroundOverlay />
            <HeroContent>
                <StyledTypographyTitle variant="LandingPageTitle">
                    <Highlighted>Any Questions?</Highlighted>
                </StyledTypographyTitle>
                <StyledTypographyDescription variant="pWhite" style={{ fontWeight: "800", fontSize: "19px" }}>
                    Contact us anytime! We are happy to chat.
                </StyledTypographyDescription>
                <ButtonContainer>
                    <Button
                        variant="VanityLinkCustomizable"
                        label="Schedule A Call"
                        onClick={() => linkToCalendly()}
                        style={{ borderRadius: "10px" }}
                    />
                    <Button
                        variant="VanityLinkCustomizable"
                        label="Email Us"
                        onClick={() => shareEmail()}
                        style={{ borderRadius: "10px" }}
                    />
                </ButtonContainer>
            </HeroContent>
        </VideoContainer>
    );
};

ButtonSection.propTypes = {
    videoUrl: PropTypes.string,
};
export default ButtonSection;

const ButtonContainer = styled.div`
    width: 500px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    gap: 25px;

    @media (max-width: 960px) {
        width: 75%;
        flex-direction: column;
        gap: 0;
    }
`;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;

const StyledTypographyTitle = styled(Typography)`
    font-size: 3rem;
    text-align: left;

    @media (max-width: 960px) {
        text-align: center;
        margin-bottom: 0;
    }
`;

const StyledTypographyDescription = styled(Typography)`
    text-align: left;
    margin-top: 0;
    margin-left: 0;
    @media (max-width: 960px) {
        text-align: center;
        font-size: 14px;
        margin-right: 0;
        margin: 20px 0;
    }
`;

const HeroBackgroundOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -200;
    background-color: ${setColor.darkPurpleOverlay};
    mix-blend-mode: multiply;
    pointer-events: none;
`;

const VideoContainer = styled.div`
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 65%, #110e25 100%);
    // @media ${device.desktopWide} {
    //     height: 55vh;
    // }
`;

const HeroContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 90%;
        height: auto;
    }
`;
