import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ReactComponent as PhoneLogo } from "../images/phone.svg";
import { ReactComponent as UserLogo } from "../images/user.svg";
import { ReactComponent as AddressLogo } from "../images/address.svg";
import { setColor } from "../../../../../assets/styles/styles";

const PaymentMethod = ({ paymentMethod }) => {
    const { address } = paymentMethod.billing_details;

    return (
        <Container>
            <RowOne>
                <CreditCardNumber>{`${paymentMethod.card.brand} ${paymentMethod.card.last4}`}</CreditCardNumber>
            </RowOne>
            <RowTwo>
                <IconTextWrapper>
                    <UserLogo />
                    <IconText>{paymentMethod.billing_details.name}</IconText>
                </IconTextWrapper>
                <IconTextWrapper>
                    <PhoneLogo />
                    <IconText>{paymentMethod.billing_details.phone}</IconText>
                </IconTextWrapper>
                <IconTextWrapper>
                    <AddressLogo />
                    <IconText>{`${address.line1}, ${address.city}, ${address.postal_code}, ${address.country}`}</IconText>
                </IconTextWrapper>
            </RowTwo>
        </Container>
    );
};

PaymentMethod.propTypes = {
    paymentMethod: PropTypes.shape({
        billing_details: PropTypes.shape({
            address: PropTypes.shape({
                line1: PropTypes.string,
                city: PropTypes.string,
                postal_code: PropTypes.string,
                country: PropTypes.string,
            }),
            name: PropTypes.string,
            phone: PropTypes.string,
        }),
        card: PropTypes.shape({
            brand: PropTypes.string,
            last4: PropTypes.string,
        }),
    }).isRequired,
};

const Container = styled.div`
    width: 100%;
    border-radius: 5px;
    background: ${setColor.passwordValidBox};
    height: 168px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 1rem;
    @media (max-width: 720px) {
        height: fit-content;
    }
`;

const IconTextWrapper = styled.div`
    display: flex;
    padding-left: 2rem;
    align-items: center;
    justify-content: center;
    @media (max-width: 720px) {
        padding-left: 0px;
    }
`;

const IconText = styled.p`
    color: ${setColor.circularProgress};
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 1rem;
    @media (max-width: 720px) {
        font-size: 1.375rem;
    }
`;

const CreditCardNumber = styled.p`
    color: ${setColor.inputText};
    font-family: Barlow;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 2rem;

    @media (max-width: 720px) {
        padding-left: 0rem;
    }
`;

const RowOne = styled.div`
    display: flex;
    align-items: center;
    height: 50%;
    box-sizing: border-box;
    @media (max-width: 720px) {
        justify-content: space-between;
    }
`;

const RowTwo = styled.div`
    display: flex;
    align-items: center;
    height: 50%;
    box-sizing: border-box;
    @media (max-width: 720px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export default PaymentMethod;
