import React from "react";
import styled from "styled-components";
import Blush from "./images/emojis/Blush.png";
import Happy from "./images/emojis/Happy.png";
import InLove from "./images/emojis/InLove.png";
import Laugh from "./images/emojis/Laugh.png";
import Sad from "./images/emojis/Sad.png";
import Worried from "./images/emojis/Worried.png";
import Tongue from "./images/emojis/Tongue.png";
import noneIcon from "./images/noneIcon.png";
import { stickers } from "../../constants";

const StickerSelection = ({ selectedSticker, onSelectSticker, setSelectedEmojiName, emojiName }) => {
    const handleStickerSelect = (sticker) => {
        onSelectSticker(sticker.src);
        setSelectedEmojiName(sticker.id);
    };
    return (
        <StickerSelectionContainer>
            {stickers.map((sticker, index) => (
                <>
                    {sticker.id === "none" && (
                        <StickerOption key={index} onClick={() => handleStickerSelect(sticker)}>
                            <StickerBox isSelected={emojiName === sticker.id}>
                                <img height={"75px"} src={noneIcon} alt="Sticker" />
                            </StickerBox>
                        </StickerOption>
                    )}
                    {sticker.id !== "none" && (
                        <StickerOption key={index} onClick={() => handleStickerSelect(sticker)}>
                            <StickerBox isSelected={emojiName === sticker.id}>
                                <img height={"75px"} src={sticker.src} alt="Sticker" />
                            </StickerBox>
                        </StickerOption>
                    )}
                </>
            ))}
        </StickerSelectionContainer>
    );
};

export default StickerSelection;

const StickerSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 150px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    grid-auto-rows: auto;

    @media (max-width: 960px) {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
`;

const StickerOption = styled.div`
    font-size: 30px;
    cursor: pointer;
`;

const StickerBox = styled.div`
    cursor: pointer;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    border: ${({ isSelected }) => (isSelected ? "2px solid #f0153f" : "none")};
    display: flex;
    justify-content: center;
    align-items: center;
    background: #23173e;
    margin: 5px;

    @media (max-width: 960px) {
        width: 80px;
        height: 80px;
    }
`;
