import React from "react";
import styled from "styled-components";
import TopStars from "./images/TopStars.png";
import ModalCloseIcon from "./images/ModalCloseIcon.png";
import Button from "../../components/common/button/Button";
import Typography from "../../components/common/text/Typography";
import { setColor } from "../../assets/styles/styles";
import { ReactComponent as warningYellow } from "../../assets/images/icons/warningYellow.svg";

const UnsavedModal = ({ onClose, onCloseUnsavedModal }) => {
    return (
        <>
            <Overlay onClick={onClose} />
            <ModalContainer>
                <ModalCloseIconBox onClick={onClose} src={ModalCloseIcon} alt="ModalCloseIcon" />
                <ModalContent>
                    <StyledWarningYellow />
                    <Typography
                        variant="guestbookP"
                        fontFamily="Signika Negative"
                        align="center"
                        FontWeight="700"
                        fontSize={"38px"}
                        color="#E4DEFF"
                    >
                        Unsaved changes!
                    </Typography>
                    <p style={{ fontSize: "18px", color: "#CED1D9" }}>If you leave now, your changes won’t be saved.</p>

                    <ButtonContainer>
                        <Button
                            label="Continue Editing"
                            borderRadius="0px"
                            backgroundColor="rgba(255, 255, 255, 0.05)"
                            variant="OutlinedButton"
                            onClick={onCloseUnsavedModal}
                            margin="0rem 1.2rem 0 0"
                            buttonWidth="50%"
                            lowercase
                            fontSize="20px"
                            padding="10px 20px"
                            border=" #E4DEFF"
                        />
                        <Button
                            label="Abandon Changes"
                            borderRadius="0px"
                            backgroundColor="rgba(36.53, 23.80, 89.25, 0.50)"
                            variant="OutlinedButton"
                            onClick={onClose}
                            margin="0rem 1rem 0 0"
                            buttonWidth="50%"
                            lowercase
                            fontSize="20px"
                            padding="10px 20px"
                            border=" #BEAEFF"
                        />
                    </ButtonContainer>
                </ModalContent>
            </ModalContainer>
        </>
    );
};

export default UnsavedModal;

const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: fit-content;
    background-color: #0e0517;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${TopStars});
    background-size: 100% 50%;
    background-position: top center;
    background-repeat: no-repeat;
    border: 4px solid #3d364f;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0rem;
    z-index: 1000;

    @media (max-width: 768px) {
        width: 80%;
        padding: 0rem;
        font-size: 12px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const ModalContent = styled.div`
    color: white;
    border-radius: 10px;
    text-align: center;
    padding: 0rem;
    width: 75%;
    @media (max-width: 700px) {
        width: auto;
    }
`;
const ModalCloseIconBox = styled.img`
    position: absolute;
    top: -30px;
    right: -45px;
    height: 100px;
    width: 90px;
    cursor: pointer;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;
const StyledWarningYellow = styled(warningYellow)`
    width: 55px;
    height: 55px;
    margin-top: 1rem;
`;
