import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { sendEventsLog } from "../../api/UserController";
import {
    LOG_EVENTS_LISTENER_DATA,
    LOG_EVENTS_LISTENER_MODAL_CLOSE,
    LOG_EVENTS__DATA_MODAL_TYPE,
} from "../../constants";
import purpleX from "../../assets/images/buttonIcons/purpleX.png";
import { sendVagonMessage } from "../vagon/helpers/utils";
import * as constants from "../../constants";
import { getFromSessionStorage } from "../../hooks/useSessionStorage";
import Modal from "../../pages/console/components/Modal";
import Typography from "../../components/common/text/Typography";
import Button from "../../components/common/button/Button";

const casinoGames = [
    {
        name: "777_gold_spin",
        url: "https://rgs-demo04.lab.wagerworks.com/skb/gateway?nscode=SKB&countrycode=GB&securetoken=RGS-DEMO04.SKB&skincode=IDV&technology=HTML&presenttype=STD&minbet=1.0&denomamount=1.0&currencycode=CAD&uniqueid=paolo1&language=en&softwareid=200-1609-001",
    },
    {
        name: "fortune_coin",
        url: "https://rgs-demo04.lab.wagerworks.com/skb/gateway?nscode=SKB&countrycode=GB&securetoken=RGS-DEMO04.SKB&skincode=IDV&technology=HTML&presenttype=STD&minbet=1.0&denomamount=1.0&currencycode=CAD&uniqueid=paolo1&language=en&softwareid=200-1581-001",
    },
    {
        name: "cleo_money_mania",
        url: "https://rgs-demo04.lab.wagerworks.com/skb/gateway?nscode=SKB&countrycode=GB&securetoken=RGS-DEMO04.SKB&skincode=IDV&technology=HTML&presenttype=STD&minbet=1.0&denomamount=1.0&currencycode=CAD&uniqueid=paolo1&language=en&softwareid=200-1623-001",
    },
    {
        name: "video_poker",
        url: "https://rgs-demo04.lab.wagerworks.com/skb/gateway?nscode=SKB&countrycode=GB&securetoken=RGS-DEMO04.SKB&skincode=IDV&technology=HTML&presenttype=STD&minbet=1.0&denomamount=1.0&currencycode=CAD&uniqueid=paolo1&language=en&softwareid=200-1204-001",
    },
];

const Igt = (props) => {
    const [modalOpen, setModalOpen] = React.useState(false);

    const [url, setUrl] = React.useState(null);
    const modalMessage = useRef();

    const bearerToken = useRef();
    const displayName = useRef();
    const clientSessionId = useRef();
    const envUnity = useRef();
    const ownerId = useRef();
    const slotRef = useRef();

    useEffect(() => {
        if (!props.data) {
            return;
        }

        const params = props.data.split("&").reduce((acc, param) => {
            const [key, value] = param.split("=");
            acc[key] = value;
            return acc;
        }, {});

        envUnity.current = params.envunity;
        clientSessionId.current = params.clientSessionId;
        bearerToken.current = params.token;
        const slotDisplayName = `tz_${params.displayName}`;
        displayName.current = slotDisplayName;
        ownerId.current = params.ownerId;

        const slot = params.slot;

        if (slot) {
            let url = casinoGames.find((game) => game.name === slot)?.url;

            if (url) {
                const urlObj = new URL(url);
                const params = new URLSearchParams(urlObj.search);
                params.set("uniqueid", slotDisplayName);
                urlObj.search = params.toString();
                url = urlObj.toString();

                setUrl(url);
                slotRef.current = url;
                sendEventsLog(
                    bearerToken,
                    "igt",
                    "",
                    LOG_EVENTS_LISTENER_DATA,
                    "info",
                    { generatedSlotUrl: url },
                    getFromSessionStorage("clientSessionId"),
                    getFromSessionStorage("envUnity")
                );
            }
        }
    }, [props]);

    const updateModal = () => {
        setModalOpen((prev) => !prev);
    };

    const sendCloseEventLog = () => {
        const data = {
            timestamp: new Date().toISOString(),
            displayName: displayName.current,
            slot: slotRef.current,
            modalType: LOG_EVENTS__DATA_MODAL_TYPE,
        };

        sendEventsLog(
            bearerToken.current,
            "igt",
            "",
            LOG_EVENTS_LISTENER_MODAL_CLOSE,
            "info",
            data,
            clientSessionId.current,
            envUnity.current
        );
    };

    useEffect(() => {
        async function handleMessage(event) {
            // check the event.source is the iframe that the game is in, also event.data.messageSource should be ‘IXF’. if so:

            if (event.data.messageType !== undefined) {
                const data = {
                    messageSource: event.data.messageSource,
                    messageType: event.data.messageType,
                    messageData: event.data.messageData,
                    timestamp: new Date().toISOString(),
                    displayName: displayName.current,
                    slot: slotRef.current,
                };

                const allUndefined = Object.values(data).every((value) => value === undefined);
                const isEmpty = Object.keys(data).length === 0;

                if (event.data.messageType === "closeGame") {
                    modalMessage.current =
                        event.data?.messageData?.message || "An error occurred. Please try again later.";
                    updateModal();
                }

                if (event.data.messageType === "displayMessage") {
                    const code = event.data?.messageData?.code;
                    const excludedCodes = [
                        "CD-001",
                        "CD-002",
                        "CD-004",
                        "CD-005",
                        "CD-109",
                        "CD-111",
                        "CD-204",
                        "CD-006",
                        "CD-009",
                        "CD-010",
                        "CD-104",
                        "CD-203",
                        "JP-101",
                        "CO-006",
                        "CO-008",
                        "CM-001",
                        "CM-002",
                    ];

                    if (code && !excludedCodes.includes(code) && !code.startsWith("FT")) {
                        modalMessage.current =
                            event.data?.messageData?.message || "An error occurred. Please try again later.";
                        updateModal();
                    }
                }
                if (!allUndefined && !isEmpty && event.data.messageType !== "loadProgress") {
                    await sendEventsLog(
                        bearerToken.current,
                        "igt",
                        "",
                        LOG_EVENTS_LISTENER_DATA,
                        "info",
                        data,
                        clientSessionId.current,
                        envUnity.current
                    );
                }
            }
        }

        window.addEventListener("message", handleMessage);

        // Cleanup function
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                children={
                    <>
                        <Typography variant="pWhite" margin={"30px 0"}>
                            {modalMessage.current}
                        </Typography>
                        <Button
                            margin={"15px 0px 0px 0px"}
                            label="Okay"
                            height={"40px"}
                            variant="OutlinedButton"
                            onClick={() => {
                                sendCloseEventLog();
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                props.setIgtSlot(null);
                                modalMessage.current = false;
                            }}
                            buttonWidth="100%"
                            fontSize="20px"
                        />
                    </>
                }
                closeButton={false}
            />
            {url && (
                <iframe
                    title="IGT Iframe Content"
                    id="splash"
                    scrolling="no"
                    allow="autoplay"
                    style={{
                        position: "absolute",
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        display: "block",
                        padding: 0,
                        border: 0,
                        zIndex: 9998,
                        top: 0,
                        left: 0,
                    }}
                    src={url}
                />
            )}
            <CloseButton
                type="button"
                onClick={() => {
                    sendCloseEventLog();
                    sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                    props.setIgtSlot(null);
                }}
            >
                <StyledImage src={purpleX} alt="Close" />
            </CloseButton>
        </div>
    );
};

export default Igt;

const StyledImage = styled.img`
    @media (max-width: 960px) {
        width: 30px;
        height: auto;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    z-index: 9999;
    top: 45px;
    right: 25px;
    border: none;
    background: transparent;
    cursor: pointer;

    @media (max-width: 960px) {
        top: 40px;
        right: 15px;
    }
`;
