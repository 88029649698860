import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { CustomTooltip } from "../../components/common/tooltip/CusomTooltip";
import purpleX from "../../assets/images/buttonIcons/purpleX.png";
import gameDefault from "./arcade-icons/game-default.png"; // fallback icon
import ExitContainer from "./arcade-icons/ExitContainer.png";
import ExitContainer1 from "./arcade-icons/ExitContainer1.png";
import ExitTag from "./arcade-icons/ExitTag.png";
import FixedButton from "./arcade-icons/FixedButton.png";
import { sendVagonMessage } from "../vagon/helpers/utils";
import * as constants from "../../constants";
import Modal from "../../pages/console/components/Modal";
import Typography from "../../components/common/text/Typography";
import Button from "../../components/common/button/Button";
import { setFont } from "../../assets/styles/styles";
import { getArcadeRoomGames } from "../../api/RoomsController";

const ArcadeGame = ({ onClose }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const modalMessage = useRef();
    const [selectedGameUrl, setSelectedGameUrl] = useState(null);
    const roomSettings = JSON.parse(localStorage.getItem("roomSettings")) || {};
    const [gameData, setGameData] = useState([]);
    const [title, setTitle] = useState("Arcade");
    const [subtitle, setSubtitle] = useState("Choose a game library to start playing from thousands of options!");
    const [icons, setIcons] = useState({});
    const [tooltipText, setTooltipText] = useState("Click to play");

    const {
        data: fetchedGameData,
        error: gameErr,
        isLoading: gameLdng,
    } = useQuery(["games", roomSettings?.roomId], () => getArcadeRoomGames(roomSettings?.roomId), {
        enabled: !!roomSettings?.roomId,
        ...constants.CACHE_QUERY_OPTIONS(),
    });

    useEffect(() => {
        if (fetchedGameData) {
            if (Array.isArray(fetchedGameData?.gamesList)) {
                if (fetchedGameData?.gamesList?.length === 0) {
                    setGameData(constants.DEFAULT_ARCADE_GAMES);
                } else {
                    setGameData(fetchedGameData?.gamesList || constants.DEFAULT_ARCADE_GAMES);
                }
            } else {
                setGameData(constants.DEFAULT_ARCADE_GAMES);
            }
        }
    }, [fetchedGameData]);

    useEffect(() => {
        if (gameErr) {
            setGameData(constants.DEFAULT_ARCADE_GAMES);
        }
    }, [gameErr]);

    const images = require.context("./arcade-icons", false, /\.png$/);

    const getImagePath = (imageName) => {
        try {
            return images(`./${imageName}.png`);
        } catch (error) {
            console.error(`Image ${imageName} not found, using default.`);
            return images("./game-default.png");
        }
    };

    const updateModal = () => {
        setModalOpen((prev) => !prev);
    };

    useEffect(() => {
        async function handleMessage(event) {
            if (event.data.messageType === "closeGame") {
                modalMessage.current = event.data?.messageData?.message || "An error occurred. Please try again later.";
                updateModal();
            }
        }

        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                children={
                    <>
                        <Typography variant="pWhite" margin={"30px 0"}>
                            {modalMessage.current}
                        </Typography>
                        <Button
                            margin={"15px 0px 0px 0px"}
                            label="Okay"
                            height={"30px"}
                            variant="OutlinedButton"
                            onClick={() => {
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                modalMessage.current = false;
                            }}
                            buttonWidth="100%"
                            fontSize="20px"
                        />
                    </>
                }
                closeButton={false}
            />
            {selectedGameUrl ? (
                <>
                    <CustomTooltip placement="bottom" title={"Click to return to Intraverse!"}>
                        <ExitContainerDiv
                            type="button"
                            onClick={() => {
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                setSelectedGameUrl(null);
                            }}
                        >
                            <ExitImage src={ExitTag} alt="ExitTag" />
                        </ExitContainerDiv>
                    </CustomTooltip>
                    <GameBanner>
                        You are viewing an <span style={{ fontWeight: 700, padding: "0 4px" }}>external</span> website
                        outside of Intraverse.
                    </GameBanner>
                    <iframe
                        title="Arcade Gaming Iframe Content"
                        id="splash"
                        allow="autoplay"
                        style={{
                            position: "absolute",
                            overflow: "auto",
                            width: "100%",
                            height: "94%",
                            display: "block",
                            padding: 0,
                            border: 0,
                            zIndex: 9998,
                            top: "36px",
                            left: 0,
                        }}
                        src={selectedGameUrl}
                    />
                </>
            ) : (
                <GameMenuModal>
                    <ArcadeHeading>{fetchedGameData?.title || title}</ArcadeHeading>
                    <Typography style={{ textAlign: "center", fontSize: "22px", color: "#FBFCFF" }} variant={"pWhite"}>
                        {fetchedGameData?.subtitle || subtitle}
                    </Typography>
                    <Grid itemCount={gameData?.length}>
                        {gameData.map((game, index) => (
                            <CustomTooltip key={index} placement="bottom" title={game.tooltipText || tooltipText}>
                                <GameIcon onClick={() => setSelectedGameUrl(game.url || "https://freegames.org/")}>
                                    <img
                                        height={70}
                                        width={70}
                                        style={{ justifyContent: "center" }}
                                        src={getImagePath(game.icon)}
                                        alt={game.name || "Game Icon"}
                                    />
                                    <p>{game.name || "Free Game"}</p>
                                </GameIcon>
                            </CustomTooltip>
                        ))}
                    </Grid>
                    <CloseButton
                        type="button"
                        onClick={() => {
                            sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                            onClose();
                            modalMessage.current = false;
                        }}
                    >
                        <StyledImage style={{ height: "35px" }} src={purpleX} alt="Close" />
                    </CloseButton>
                </GameMenuModal>
            )}
        </div>
    );
};

export default ArcadeGame;

const StyledImage = styled.img`
    @media (max-width: 960px) {
        width: 30px;
        height: auto;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 1rem;
    justify-items: center;

    @media (max-width: 920px) {
        grid-template-rows: repeat(auto-fit, minmax(80px, 1fr));
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(2, auto);
        gap: 10px;
        margin-top: 0.5rem;
    }

    ${({ itemCount }) =>
        itemCount < 4 &&
        `
        grid-template-columns: repeat(${itemCount}, 1fr);
    `}
`;

const ArcadeHeading = styled.h2`
    font-family: "Signika Negative";
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(180deg, #beaeff 0%, rgba(190, 174, 255, 0.6) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const GameMenuModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(253deg, rgba(11, 11, 44, 0.9) 4.16%, rgba(33, 13, 53, 0.9) 100.79%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    padding-top: 1rem;

    @media (max-width: 920px) {
        padding-top: 3rem;
    }
`;

const GameIcon = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 7.667px;
    border: 2px solid #3d364f;
    background: #23173e;

    & p {
        color: #fbfcff;
        font-family: ${setFont.barlow};
        font-weight: 500;
        font-size: 18px;
        margin-top: 0.7rem;
        letter-spacing: normal;
    }

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 920px) {
        width: 150px;
        height: 150px;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    z-index: 99999;
    top: 15px;
    right: 25px;
    border: none;
    background: transparent;
    cursor: pointer;

    @media (max-width: 960px) {
        right: 5px;
    }
`;

const ExitContainerDiv = styled.button`
    position: absolute;
    z-index: 19999;
    top: -4px;
    right: 0px;
    height: 120px;
    width: 320px;
    border: none;
    background: url(${ExitContainer1}) no-repeat;
    background-size: cover;
    cursor: pointer;
    display: flex;
    transform: scale(0.6);
    transform-origin: top right;
    align-items: center;
    justify-content: center;

    @media (max-width: 960px) {
        transform: scale(0.5);
    }
`;

const GameBanner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
    background: #000;
    color: white;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    @media (max-width: 600px) {
        justify-content: flex-start;
        padding-left: 1rem;
        font-size: 12px;
        flex-wrap: wrap;
    }
`;

const ExitImage = styled.img`
    position: relative;
    height: 50px;
    width: auto;
    margin-right: 3rem;
`;
