import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { setColor } from "../../../assets/styles/styles";
import X from "../../../assets/images/buttonIcons/X.png";
import { hexToRGBA } from "../../../utils/generalUtils";

const Modal = ({ setIsOpen, isOpen, children, closeButton, width }) => {
    const handleClose = () => setIsOpen(false);

    return (
        <>
            {isOpen && (
                <ModalContainer>
                    <ModalContent width={width}>
                        {closeButton && (
                            <Buttons>
                                <div>
                                    <CloseIcon src={X} alt="Close" onClick={() => handleClose()} close />
                                </div>
                            </Buttons>
                        )}
                        <Children>{children}</Children>
                    </ModalContent>
                </ModalContainer>
            )}
        </>
    );
};

Modal.defaultProps = {
    closeButton: true,
};

Modal.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node,
    closeButton: PropTypes.bool,
    width: PropTypes.string,
};

export default Modal;

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${hexToRGBA(setColor.black, 0.65)};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
`;

const ModalContent = styled.div`
    border: 4px solid #24242f;
    background-color: ${setColor.inputBgColor};
    padding: 25px;
    border-radius: 10px;
    width: ${(props) => (props.width ? props.width : "700px")};
    height: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;

    @media (min-width: 768px) {
        &::-webkit-scrollbar {
            width: 0.75em; /* Adjust the width as needed */
            border-width: 1px;
        }

        &::-webkit-scrollbar-track {
            border-top-right-radius: 18px;
            border-bottom-right-radius: 18px;
            border-width: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${hexToRGBA(setColor.accent4, 0.1)};
            border-radius: 10px; /* Adjust the border-radius as needed */
            border-width: 1px;
        }
    }

    @media (max-width: 768px) {
        max-height: 100vh;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        border-radius: 0;
        justify-content: space-between;
    }
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
`;

const Children = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const CloseIcon = styled.img`
    height: 20px;
    width: auto;
    cursor: pointer;

    @media (max-width: 960px) {
        margin-bottom: 15px;
    }
`;
