import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { setColor } from "../../../../assets/styles/styles";
import Typography from "../../../../components/common/text/Typography";
import { device } from "../../../../assets/styles/mediaBreakpoints";
import Button from "../../../../components/common/button/Button";
import { isMobileDevice } from "../../../../utils/generalUtils";

const burlbTitles = ["An Artist", "An Agency", "A Brand", "An Influencer", "A Musician", "A Streamer"];

const GallerySection = () => {
    const isMobile = isMobileDevice();

    const [blurbIndex, setCurrentBlurbIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    const linkToCalendly = () => {
        window.open("https://calendly.com/intraversepro", "_blank");
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible(false);
            setTimeout(() => {
                setCurrentBlurbIndex((prevIndex) => (prevIndex + 1) % burlbTitles.length);
                setIsVisible(true);
            }, 1000);
        }, 2500);

        return () => clearInterval(interval);
    }, []);

    const currentFeature = burlbTitles[blurbIndex];

    return (
        <SectionBackground>
            <SectionContainer>
                {!isMobile && (
                    <GalleryDisplay>
                        <FeatureVideo muted autoPlay loop>
                            <source type="video/mp4" src={"/videos/landingPages/Pro_Artists.mp4"} />
                        </FeatureVideo>
                    </GalleryDisplay>
                )}
                <TextContainer>
                    <StyledTypography variant="LandingPageTitle">
                        Are You...
                        <br />
                        <HighlightedTitle isVisible={isVisible}>{currentFeature}</HighlightedTitle>?
                    </StyledTypography>
                    <StyledParagraphTypography variant="pWhite">
                        Unleash your creativity with a personalized{" "}
                        <HighlightedDescription>3D virtual environment</HighlightedDescription> that's uniquely yours.
                        <br />
                        <br />
                        Connect to your audience with a <HighlightedDescription>
                            custom avatar.
                        </HighlightedDescription>{" "}
                        Host private or public events, hold contests, present live streams, sell merchandise, post
                        surveys and more. The possibilities are endless with Intraverse Pro.
                        <br />
                        <br />
                        Let's connect to bring your vision to life!
                    </StyledParagraphTypography>
                    <Button
                        variant="VanityLinkCustomizable"
                        label="Schedule A Call"
                        onClick={() => linkToCalendly()}
                        style={{ borderRadius: "10px", width: "250px" }}
                    />
                </TextContainer>
                {isMobile && (
                    <GalleryDisplay>
                        <FeatureVideo muted autoPlay loop>
                            <source type="video/mp4" src={"/videos/landingPages/Pro_Artists.mp4"} />
                        </FeatureVideo>
                    </GalleryDisplay>
                )}
            </SectionContainer>
        </SectionBackground>
    );
};

export default GallerySection;

const TextContainer = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;

    @media (max-width: 960px) {
        width: 100%;
        gap: 10px;
        align-items: center;
    }
`;

const FeatureVideo = styled.video`
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-right: 100px;

    @media (max-width: 960px) {
        margin-right: 0;
    }
`;

const StyledTypography = styled(Typography)`
    text-align: left;

    @media (max-width: 960px) {
        margin-right: 0;
        text-align: center;
    }
`;

const StyledParagraphTypography = styled(Typography)`
    margin: 0;
    text-align: left;
    font-size: 18px;

    @media (max-width: 960px) {
        text-align: center;
    }
`;

const HighlightedTitle = styled.span`
    color: ${setColor.accent4};
    animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 1s ease-in-out;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;

    @media (max-width: 960px) {
        position: static;
        margin-left: 0;
    }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const HighlightedDescription = styled.span`
    color: ${setColor.yellowText};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #110e25;

    @media (max-width: 950px) {
        height: auto;
    }
`;

const SectionContainer = styled.div`
    max-width: 1300px;
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media ${device.desktopWide} {
        padding: 75px 0;
    }

    @media (max-width: 960px) {
        flex-direction: column;
        width: 90%;
    }
`;

const GalleryDisplay = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 950px) {
        width: 100%;
        gap: 25px;
        flex-wrap: wrap;
        margin-bottom: 25px;
    }
`;
