import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import moment from "moment";
import { setColor } from "../../../../../assets/styles/styles";
import Title from "../../../components/Title";
import Body from "../../../layout/Body";
import { CACHE_QUERY_OPTIONS } from "../../../../../constants";
import Typography from "../../../../../components/common/text/Typography";
import { default as Button } from "../../../../../components/common/button/Button";
import { ReactComponent as ChevronIcon } from "../../../../../assets/images/icons/Chevron.svg";
import { getRoomGames } from "../api/roomController";
import DataTable from "../../../../../components/common/dataTable/DataTable";
import Modal from "../../../components/Modal";
import Pagination from "../../../../../components/common/pagination/Pagination";
import { ReactComponent as EditIcon } from "../../../../../assets/images/icons/editIcon.svg";
import { formatDateTimeWithTimeZone } from "../../../../../utils/generalUtils";
import AddUpdateGames from "./AddUpdateGames";

const CasinoGamesPage = () => {
    const { id } = useParams();
    const ROWS_PER_PAGE = 5;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [visibleRows, setVisibleRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isEditModal, setIsEditModal] = useState(false);
    const [openAddGameModal, setOpenAddGameModal] = useState(false);
    const [action, setAction] = useState("");
    const [selectedRow, setSelectedRow] = useState({});

    const handleBack = () => {
        navigate(`/console/rooms/${id}`);
    };

    const {
        data: games,
        error: gameErr,
        isLoading: gameLdng,
    } = useQuery(["games", id], () => getRoomGames(id), {
        enabled: !!id,
        ...CACHE_QUERY_OPTIONS(),
    });

    useEffect(() => {
        if (games) {
            setTotalPages(Math.ceil(games.length / ROWS_PER_PAGE));
            const filteredGames = games.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
            setVisibleRows(filteredGames.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE));
            setIsLoading(false);
        }
    }, [games, currentPage]);

    const handleEditClick = (row) => {
        setOpenAddGameModal(true);
        setAction("Update");
        setSelectedRow(row);
    };

    const renderRow = (row) => {
        const buttonEdit = <StyledEditIcon onClick={() => handleEditClick(row)} />;
        const statusIndicator = <StatusCircle isActive={row.isActive} />;
        return [
            row.gameId,
            row.url,
            row.slotNumber,
            row.type,
            row.clientName,
            <>
                {statusIndicator} {row.isActive ? "Active" : "Inactive"}
            </>,
            formatDateTimeWithTimeZone(row.createdAt),
            <>{buttonEdit}</>,
        ];
    };

    const addGameClick = () => {
        setOpenAddGameModal(true);
        setAction("Add");
    };

    return (
        <>
            <Title
                title={"ROOM CASINO GAMES"}
                description={"Manage the casino games in this room. Add, edit, or deactive games."}
            />

            <UserConsoleText style={{ marginBottom: "1rem" }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>

            <Body isLoading={isLoading}>
                <>
                    <CardTable>
                        <TableRow>
                            <TableColumn>
                                <Typography variant="PinkText" margin={"5px"}>
                                    <strong>{id}</strong>
                                </Typography>
                            </TableColumn>

                            <TableColumnButton>
                                <Button
                                    variant={"SmallButton"}
                                    label="ADD GAME"
                                    buttonWidth="auto"
                                    margin="0rem 1rem 0rem 0rem"
                                    onClick={addGameClick}
                                    border={`${setColor.accent4}`}
                                />
                            </TableColumnButton>
                        </TableRow>
                    </CardTable>

                    {games && (
                        <>
                            <DataTable
                                headers={["GAME ID", "URL", "SLOT", "TYPE", "CLIENT NAME", "STATUS", "CREATED AT"]}
                                data={visibleRows}
                                renderRow={renderRow}
                                isHover={false}
                            />
                            <Pagination
                                totalNumber={games.length}
                                visibleRows={visibleRows}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                tag={"Games"}
                            />
                        </>
                    )}
                    {openAddGameModal && (
                        <Modal
                            isOpen={openAddGameModal}
                            setIsOpen={setOpenAddGameModal}
                            children={
                                <AddUpdateGames
                                    roomId={id}
                                    action={action}
                                    closeModal={() => setOpenAddGameModal(false)}
                                    selectedRow={selectedRow}
                                />
                            }
                        />
                    )}
                </>
            </Body>
        </>
    );
};

export default CasinoGamesPage;

const UserConsoleText = styled.h1`
    font-family: "Barlow Condensed", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const StyledEditIcon = styled(EditIcon)`
    width: 15px;
    height: 15px;
    cursor: pointer;
`;

const StatusCircle = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => (props.isActive ? setColor.success : setColor.warning)};
    display: inline-block;
    margin-right: 5px;
`;
