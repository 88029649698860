import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Typography from "../../../components/common/text/Typography";
import { ReactComponent as ChevronIcon } from "../../../assets/images/icons/Chevron.svg";
import { setColor } from "../../../assets/styles/styles";

const Title = ({ title, description, button, navigateBack }) => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <HeaderContainer>
            <Text>
                <Typography variant="ConsoleTitle">{title}</Typography>
                <DescriptionText variant="ConsoleDescription">{description}</DescriptionText>
                {navigateBack && (
                    <NavigateBackContainer onClick={handleBack}>
                        <StyledIconPrev />
                        <BackText>Back</BackText>
                    </NavigateBackContainer>
                )}
            </Text>
            {button && <Button>{button}</Button>}
        </HeaderContainer>
    );
};

Title.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.node,
    navigateBack: PropTypes.bool,
};

export default Title;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    transform: rotate(90deg) !important;
`;

const NavigateBackContainer = styled.div`
    width: fit-content;
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`;

const BackText = styled.p`
    font-family: "Barlow Condensed", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: ${setColor.accent4};
`;

const DescriptionText = styled(Typography)`
    @media (max-width: 768px) {
        text-align: center;
    }
`;

const HeaderContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0;

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center !important;
        margin: 1rem 0 1.5rem 0;
    }
`;

const Text = styled.div`
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
`;

const Button = styled.div`
    @media (max-width: 768px) {
        margin-top: 2rem;
    }
`;
