import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { formatDistanceToNow } from "date-fns";
import NoteCreationStep1 from "./NoteCreationStep1";
import NoteCreationStep2 from "./NoteCreationStep2";
import NoteSubmitSuccessModal from "./NoteSubmitSuccessModal";
import ExitIntraverseModal from "./ExitIntraverseModal";
import UnsavedModal from "./UnsavedModal";
import purpleX from "../../assets/images/buttonIcons/purpleX.png";
import NotesBackground from "./images/NotesBackground2.png";
import thumbsUpblk from "./images/Thumbs_Up_Black.png";
import thumbsUpwht from "./images/Thumbs_Up_White.png";
import thumbsDownblk from "./images/Thumbs_Down_Black.png";
import thumbsDownwht from "./images/Thumbs_Down_White.png";
import PinBlack from "./images/pins/PinBlack.png";
import PinGreen from "./images/pins/PinGreen.png";
import PinPink from "./images/pins/PinPink.png";
import PinPurple from "./images/pins/PinPurple.png";
import PinWhite from "./images/pins/PinWhite.png";
import PinYellow from "./images/pins/PinYellow.png";
import PlusIcon from "./images/PlusIcon.png";
import Typography from "../../components/common/text/Typography";
import { setFont } from "../../assets/styles/styles";
import EmailModal from "./EmailModal";
import exitFrame from "./images/exitFrame.png";
import stickyFoldBlack from "./images/stickyFolds/stickyFold_Black1.png";
import stickyFoldGreen from "./images/stickyFolds/stickyFold_Green1.png";
import stickyFoldPink from "./images/stickyFolds/stickyFold_Pink1.png";
import stickyFoldPurple from "./images/stickyFolds/stickyFold_Purple1.png";
import stickyFoldWhite from "./images/stickyFolds/stickyFold_White1.png";
import stickyFoldYellow from "./images/stickyFolds/stickyFold_Yellow1.png";
import { getAllNotes, getRoomUsers, updateNoteReaction } from "./api/notesController";
import { CACHE_QUERY_OPTIONS, emojiMap, GUESTBOOK_FONTS } from "../../constants";
import LoadingIcon from "../../components/common/loading/LoadingIcon";

const Guestbook = ({ onClose }) => {
    const user = useSelector((state) => state.user);
    const roomSettings = JSON.parse(localStorage.getItem("roomSettings")) || {};
    const [isCreatingNote, setIsCreatingNote] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [message, setMessage] = useState("");
    const [stickyNotes, setStickyNotes] = useState([]);
    const [selectedColor, setSelectedColor] = useState("#C5B7FF");
    const [selectedSticker, setSelectedSticker] = useState("none");
    const [selectedFont, setSelectedFont] = useState("Barlow, sans serif");
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emailCreator, setEmailCreator] = useState(false);
    const [isProUser, setIsProUser] = useState(false);
    const [isRoomOwner, setIsRoomOwner] = useState(false);
    const [roomAdminName, setRoomAdminName] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    const [columns, setColumns] = useState([]);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [activeButton, setActiveButton] = useState("All");
    const [updatedMessage, setUpdatedMessage] = useState("");
    const [unApprovedNotesNumbers, setUnApprovedNotesNumbers] = useState(0);
    const [exitIntraModal, setExitIntraModal] = useState(false);
    const [openUnsavedModal, setOpenUnsavedModal] = useState(false);

    const {
        data: notesData,
        error,
        isLoading,
        refetch: refetchNotes,
    } = useQuery(["notes", roomSettings?.roomId], () => getAllNotes(roomSettings?.roomId), {
        enabled: !!roomSettings?.roomId,
        ...CACHE_QUERY_OPTIONS(),
    });

    const {
        data: roomUsersData,
        error: roomUserErr,
        isLoading: roomUserLdng,
    } = useQuery(["roomUsers", roomSettings?.roomId], () => getRoomUsers(roomSettings?.roomId), {
        enabled: !!roomSettings?.roomId,
        ...CACHE_QUERY_OPTIONS(),
    });

    useEffect(() => {
        if (!exitIntraModal) {
            refetchNotes();
        }
    }, [exitIntraModal, refetchNotes]);

    useEffect(() => {
        if (notesData) {
            const approvedNotes = notesData.filter((note) => note.isApproved);
            const updatedApprovedNotesData = approvedNotes.map((note) => {
                const fontStyle = note.fontStyle || "";
                const matchedFont = GUESTBOOK_FONTS.find((font) => font.name.toLowerCase() === fontStyle.toLowerCase());
                return {
                    ...note,
                    fontFamily: matchedFont ? matchedFont.family : "Barlow, sans serif",
                };
            });

            setStickyNotes(updatedApprovedNotesData);
            const sortedMessages = [...updatedApprovedNotesData].sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setColumns(splitItemsIntoColumns(sortedMessages));
            const unapprovedNotes = notesData.filter((note) => !note.isApproved);
            setUnApprovedNotesNumbers(unapprovedNotes.length);
        }
        if (roomUsersData?.length !== 0) {
            roomUsersData?.forEach((element) => {
                if (element?.userId === user?.userId && element?.roleName === "admin") {
                    setIsRoomOwner(true);
                }
                if (element?.roleName === "admin") {
                    setRoomAdminName(`${element?.displayName}`);
                }
            });
        }
        if (message.length !== 0) {
            setUpdatedMessage(message);
        }
    }, [notesData, roomUsersData, message]);

    const pins = [
        { pinColor: "#C5B7FF", pinIcon: PinPurple },
        { pinColor: "#FFCDED", pinIcon: PinPink },
        { pinColor: "#FFF3B1", pinIcon: PinYellow },
        { pinColor: "#C7FFC8", pinIcon: PinGreen },
        { pinColor: "#4E4C55", pinIcon: PinBlack },
        { pinColor: "#FFFFFF", pinIcon: PinWhite },
    ];

    const stickyCorners = [
        { stickyColor: "#C5B7FF", stickyFoldImg: stickyFoldPurple },
        { stickyColor: "#FFCDED", stickyFoldImg: stickyFoldPink },
        { stickyColor: "#FFF3B1", stickyFoldImg: stickyFoldYellow },
        { stickyColor: "#C7FFC8", stickyFoldImg: stickyFoldGreen },
        { stickyColor: "#4E4C55", stickyFoldImg: stickyFoldBlack },
        { stickyColor: "#FFFFFF", stickyFoldImg: stickyFoldWhite },
    ];

    const splitItemsIntoColumns = (items) => {
        const columnCount = 3;
        const columns = Array.from({ length: columnCount }, () => []);
        items.forEach((item, index) => {
            columns[index % columnCount].push(item);
        });
        return columns;
    };

    const handleTopRatedClick = () => {
        const sortedMessages = [...stickyNotes].sort((a, b) => {
            const netRatingA = a.likesCount - a.dislikesCount; // Net rating for item A
            const netRatingB = b.likesCount - b.dislikesCount; // Net rating for item B
            return netRatingB - netRatingA; // Sort by net rating in descending order
        });
        setColumns(splitItemsIntoColumns(sortedMessages));
        setActiveButton("TopRated");
    };

    const handleAllRecentClick = () => {
        const sortedMessages = [...stickyNotes].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setColumns(splitItemsIntoColumns(sortedMessages));
        setActiveButton("All");
    };

    const handleNoteCreation = () => {
        if (isProUser) {
            setShowEmailModal(true);
        } else {
            setCurrentStep(1);
            setIsCreatingNote(true);
            setShowEmailModal(false);
        }
    };

    const handleCharacterCountChange = (count) => {
        setCharacterCount(count);
    };

    const handleOptionSelection = (useEmailCreator) => {
        setEmailCreator(useEmailCreator);
        setCurrentStep(1);
        setIsCreatingNote(true);
        setShowEmailModal(false);
    };

    const handleNextStep = (message, color, sticker, font) => {
        setMessage(message);
        setCurrentStep(2);
    };

    const onSubmitNote = async () => {
        setShowSuccessModal(true);
        setIsCreatingNote(false);
        setMessage("");
    };

    const EmailArtist = (EmailArtist) => {
        setMessage(EmailArtist);
        setCurrentStep(0);
        setIsCreatingNote(false);
        setShowSuccessModal(true);
        setMessage("");
    };

    const handleCloseModal = async () => {
        setShowSuccessModal(false);
        await refetchNotes();
    };

    const handleThumbUp = async (id) => {
        const hasLikesClicked = localStorage.getItem(`hasLikesClicked_${id}`);
        const hasDislikesClicked = localStorage.getItem(`hasDislikesClicked_${id}`);

        if (hasLikesClicked) {
            stickyNotes.forEach(async (element) => {
                if (element.noteId === id) {
                    element.likesCount -= 1;
                    await updateNoteReaction(id, "like", element.likesCount, element.dislikesCount);
                }
            });

            setColumns((prevState) =>
                prevState.map((note) => (note.noteId === id ? { ...note, likesCount: note.likesCount - 1 } : note))
            );
            localStorage.removeItem(`hasLikesClicked_${id}`);
        } else if (hasDislikesClicked) {
            stickyNotes.forEach(async (element) => {
                if (element.noteId === id) {
                    element.likesCount += 1;
                    element.dislikesCount -= 1;
                    await updateNoteReaction(id, "like", element.likesCount, element.dislikesCount);
                    await updateNoteReaction(id, "dislike", element.likesCount, element.dislikesCount);
                }
            });
            setColumns((prevState) =>
                prevState.map((note) =>
                    note.noteId === id
                        ? { ...note, likesCount: note.likesCount + 1, dislikesCount: note.dislikesCount - 1 }
                        : note
                )
            );
            localStorage.setItem(`hasLikesClicked_${id}`, "true");
            localStorage.removeItem(`hasDislikesClicked_${id}`);
        } else {
            stickyNotes.forEach(async (element) => {
                if (element.noteId === id) {
                    element.likesCount += 1;
                    await updateNoteReaction(id, "like", element.likesCount, element.dislikesCount);
                }
            });

            setColumns((prevState) =>
                prevState.map((note) => (note.noteId === id ? { ...note, likesCount: note.likesCount + 1 } : note))
            );
            localStorage.setItem(`hasLikesClicked_${id}`, "true");
        }
    };

    const handleCloseGuestbook = () => {
        onClose();
    };

    const handleUnsavedChanges = () => {
        const messageWithSingleSpaces = message.replace(/\s+/g, " ").trim();
        if (messageWithSingleSpaces.length > 1) {
            setOpenUnsavedModal(true);
        } else {
            setOpenUnsavedModal(false);
            setCurrentStep(0);
            setIsCreatingNote(false);
            setMessage("");
        }
    };

    const handleThumbDown = async (id) => {
        const hasLikesClicked = localStorage.getItem(`hasLikesClicked_${id}`);
        const hasDislikesClicked = localStorage.getItem(`hasDislikesClicked_${id}`);

        if (hasDislikesClicked) {
            stickyNotes.forEach(async (element) => {
                if (element.noteId === id) {
                    element.dislikesCount -= 1;
                    await updateNoteReaction(id, "dislike", element.likesCount, element.dislikesCount);
                }
            });

            setColumns((prevState) =>
                prevState.map((note) =>
                    note.noteId === id ? { ...note, dislikesCount: note.dislikesCount - 1 } : note
                )
            );
            localStorage.removeItem(`hasDislikesClicked_${id}`);
        } else if (hasLikesClicked) {
            stickyNotes.forEach(async (element) => {
                if (element.noteId === id) {
                    element.likesCount -= 1;
                    element.dislikesCount += 1;
                    await updateNoteReaction(id, "dislike", element.likesCount, element.dislikesCount);
                    await updateNoteReaction(id, "like", element.likesCount, element.dislikesCount);
                }
            });

            setColumns((prevState) =>
                prevState.map((note) =>
                    note.noteId === id
                        ? { ...note, likesCount: note.likesCount - 1, dislikesCount: note.dislikesCount + 1 }
                        : note
                )
            );
            localStorage.setItem(`hasDislikesClicked_${id}`, "true");
            localStorage.removeItem(`hasLikesClicked_${id}`);
        } else {
            stickyNotes.forEach(async (element) => {
                if (element.noteId === id) {
                    element.dislikesCount += 1;
                    await updateNoteReaction(id, "dislike", element.likesCount, element.dislikesCount);
                }
            });
            setColumns((prevState) =>
                prevState.map((note) =>
                    note.noteId === id ? { ...note, dislikesCount: note.dislikesCount + 1 } : note
                )
            );
            localStorage.setItem(`hasDislikesClicked_${id}`, "true");
        }
    };

    function darkenColor(color, amount = 0.3) {
        const colorHex = color.startsWith("#") ? color.slice(1) : color; // Remove the '#' if it's present
        let r = parseInt(colorHex.substr(0, 2), 16);
        let g = parseInt(colorHex.substr(2, 2), 16);
        let b = parseInt(colorHex.substr(4, 2), 16);

        r = Math.max(0, r - r * amount);
        g = Math.max(0, g - g * amount);
        b = Math.max(0, b - b * amount);

        return `#${Math.round(r).toString(16).padStart(2, "0")}${Math.round(g).toString(16).padStart(2, "0")}${Math.round(b).toString(16).padStart(2, "0")}`;
    }

    const handleRoomOwnerClick = () => {
        setExitIntraModal(true);
    };

    return (
        <div
            style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: "4000",
                width: "100%",
                height: "100%",
                overflow: "hidden",
            }}
        >
            {!isCreatingNote && currentStep === 0 && (
                <>
                    {isLoading && (
                        <LoadingDiv>
                            <LoadingIcon />
                        </LoadingDiv>
                    )}
                    {!isLoading && (
                        <>
                            <GuestbookOverlay show={showSuccessModal || showEmailModal || exitIntraModal} />
                            <GuestbookContainer>
                                <img
                                    alt="bg"
                                    style={{ position: "fixed", top: "0", width: "100vw", height: "1rem" }}
                                    src={NotesBackground}
                                />
                                {isRoomOwner && (
                                    <>
                                        <Layer show={showSuccessModal || showEmailModal || exitIntraModal} />
                                        <OwnerBanner onClick={handleRoomOwnerClick}>
                                            <p>
                                                You have <span>{unApprovedNotesNumbers}</span> new notes. Tap to manage
                                                your guestbook.
                                            </p>
                                            <img
                                                height="30px"
                                                style={{ marginRight: "1rem" }}
                                                src={exitFrame}
                                                alt="exit banner"
                                            />
                                        </OwnerBanner>
                                    </>
                                )}
                                <Layer show={showSuccessModal || showEmailModal || exitIntraModal} />
                                <Banner>
                                    <Typography variant="guestbookHeader">Guestbook</Typography>
                                    <NotesBox>
                                        <Typography
                                            style={{ padding: "1rem" }}
                                            variant="guestbookHeader"
                                            color="#E4DEFF"
                                        >
                                            {stickyNotes.length} {stickyNotes.length === 1 ? "note" : "notes"}
                                        </Typography>
                                        <Button>
                                            <StyledImage
                                                style={{ height: "35px" }}
                                                src={purpleX}
                                                alt="Close"
                                                onClick={handleCloseGuestbook}
                                            />
                                        </Button>
                                    </NotesBox>
                                </Banner>
                                <Gradient />
                                <StickyNoteGrid empty={stickyNotes.length === 0}>
                                    {stickyNotes.length !== 0 && (
                                        <ThumbsWrapper
                                            style={{
                                                position: "absolute",
                                                left: "13vw",
                                                top: isRoomOwner ? "145px" : "90px",
                                            }}
                                        >
                                            <AllButton
                                                isActive={activeButton === "All"}
                                                onClick={() => handleAllRecentClick()}
                                            >
                                                {" "}
                                                All{" "}
                                            </AllButton>
                                            <TopRated
                                                isActive={activeButton === "TopRated"}
                                                onClick={() => handleTopRatedClick()}
                                            >
                                                {" "}
                                                Top Rated{" "}
                                            </TopRated>
                                        </ThumbsWrapper>
                                    )}
                                    {stickyNotes.length === 0 ? (
                                        <Typography
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            variant={"guestbookInner"}
                                        >
                                            Tap the
                                            <button
                                                type="button"
                                                style={{ background: "transparent", border: "none" }}
                                                onClick={handleNoteCreation}
                                            >
                                                <img style={{ margin: "0 5px" }} alt="PlusIcon" src={PlusIcon} />
                                            </button>
                                            to create a note!
                                        </Typography>
                                    ) : (
                                        columns.map((column, index) => (
                                            <Column key={index}>
                                                {column.map((note) => {
                                                    // Darken the color of the thumbs
                                                    const darkenedStickyColor = darkenColor(note.color, 0.3); // Darken the sticky color by 30%
                                                    const hasLiked = localStorage.getItem(
                                                        `hasLikesClicked_${note.noteId}`
                                                    );
                                                    const hasDisliked = localStorage.getItem(
                                                        `hasDislikesClicked_${note.noteId}`
                                                    );
                                                    return (
                                                        <StickyNote
                                                            key={note.noteId}
                                                            color={note.color}
                                                            style={{
                                                                backgroundColor: note.color,
                                                                // padding: '10px',
                                                                borderRadius: "0px",
                                                                position: "relative",
                                                                maxWidth: "600px",
                                                                height: "fit-content",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    whiteSpace: "nowrap",
                                                                    width: "100%",
                                                                    fontWeight: "500",
                                                                    color:
                                                                        note.color === "#4E4C55"
                                                                            ? "#FFFFFF"
                                                                            : "#1B1B23",
                                                                    padding: "0.5rem 1rem",
                                                                }}
                                                            >
                                                                <UserNameAndPin>
                                                                    <img
                                                                        alt="pin"
                                                                        src={
                                                                            pins.find(
                                                                                (pin) =>
                                                                                    pin.pinColor?.toLowerCase() ===
                                                                                    note.color?.toLowerCase()
                                                                            )?.pinIcon || PinYellow
                                                                        }
                                                                    />
                                                                    {note.isAnonymous ? "Anonymous" : note.displayName}
                                                                </UserNameAndPin>
                                                                <span
                                                                    style={{
                                                                        textAlign: "right",
                                                                        color:
                                                                            note.color === "#4E4C55"
                                                                                ? "#FFFFFF"
                                                                                : "rgba(27, 27, 35, 0.50)",
                                                                    }}
                                                                >
                                                                    {formatDistanceToNow(new Date(note.createdAt))}
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: "-70px",
                                                                    right: "-22px",
                                                                }}
                                                            >
                                                                {note.sticker && (
                                                                    <img
                                                                        src={note.sticker}
                                                                        alt="sticker"
                                                                        style={{
                                                                            width: "90px",
                                                                            height: "90px",
                                                                            transform: "rotate(15deg)",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <p
                                                                style={{
                                                                    fontFamily: note.fontFamily,
                                                                    padding: "1rem 1.5rem",
                                                                    letterSpacing: 0,
                                                                    fontSize: "18px",
                                                                    color:
                                                                        note.color === "#4E4C55"
                                                                            ? "#FFFFFF"
                                                                            : "#1B1B23",
                                                                }}
                                                            >
                                                                {note.message}
                                                            </p>
                                                            {emojiMap[note.stickerId] && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        bottom: "-70px",
                                                                        right: "-23px",
                                                                    }}
                                                                >
                                                                    {note.stickerId && (
                                                                        <img
                                                                            src={emojiMap[note.stickerId]}
                                                                            alt="sticker"
                                                                            style={{
                                                                                width: "90px",
                                                                                height: "90px",
                                                                                transform: "rotate(15deg)",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            )}
                                                            <ThumbsWrapper>
                                                                <Thumb
                                                                    style={{
                                                                        border:
                                                                            note.color === "#4E4C55"
                                                                                ? "1px solid white"
                                                                                : "1px solid rgba(0, 0, 0, 0.5)",
                                                                        backgroundColor: hasLiked
                                                                            ? darkenedStickyColor
                                                                            : "transparent",
                                                                    }}
                                                                    onClick={() => handleThumbUp(note.noteId)}
                                                                >
                                                                    <ThumbIcon
                                                                        src={
                                                                            note.color === "#4E4C55"
                                                                                ? thumbsUpwht
                                                                                : thumbsUpblk
                                                                        }
                                                                        alt="Thumbs Up"
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color:
                                                                                note.color === "#4E4C55"
                                                                                    ? "#FFFFFF"
                                                                                    : "rgba(0, 0, 0, 0.5)",
                                                                            marginLeft: "8px",
                                                                        }}
                                                                    >
                                                                        {note.likesCount}
                                                                    </p>
                                                                </Thumb>
                                                                <Thumb
                                                                    style={{
                                                                        border:
                                                                            note.color === "#4E4C55"
                                                                                ? "1px solid #FFFFFF"
                                                                                : "1px solid rgba(0, 0, 0, 0.5)",
                                                                        backgroundColor: hasDisliked
                                                                            ? darkenedStickyColor
                                                                            : "transparent",
                                                                    }}
                                                                    onClick={() => handleThumbDown(note.noteId)}
                                                                >
                                                                    <ThumbIcon
                                                                        src={
                                                                            note.color === "#4E4C55"
                                                                                ? thumbsDownwht
                                                                                : thumbsDownblk
                                                                        }
                                                                        alt="Thumbs Down"
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color:
                                                                                note.color === "#4E4C55"
                                                                                    ? "#FFFFFF"
                                                                                    : "rgba(0, 0, 0, 0.5)",
                                                                            marginLeft: "8px",
                                                                        }}
                                                                    >
                                                                        {note.dislikesCount}
                                                                    </p>
                                                                </Thumb>
                                                            </ThumbsWrapper>
                                                            <BottomFold
                                                                src={
                                                                    stickyCorners.find(
                                                                        (corner) =>
                                                                            corner.stickyColor?.toLowerCase() ===
                                                                            note.color?.toLowerCase()
                                                                    )?.stickyFoldImg || stickyFoldPurple
                                                                }
                                                                alt="bottom fold"
                                                            />
                                                        </StickyNote>
                                                    );
                                                })}
                                            </Column>
                                        ))
                                    )}
                                </StickyNoteGrid>

                                <PlusButton onClick={handleNoteCreation}>
                                    <img
                                        height={"100px"}
                                        width={"100px"}
                                        style={{ margin: "0 10px" }}
                                        alt="PlusIcon"
                                        src={PlusIcon}
                                    />
                                </PlusButton>
                                {showSuccessModal && (
                                    <NoteSubmitSuccessModal
                                        onClose={handleCloseModal}
                                        useEmailCreator={emailCreator}
                                        roomAdminName={roomAdminName}
                                    />
                                )}
                                {showEmailModal && (
                                    <EmailModal
                                        onClose={() => setShowEmailModal(false)}
                                        onSelectOption={handleOptionSelection}
                                    />
                                )}
                            </GuestbookContainer>
                        </>
                    )}
                </>
            )}
            {isCreatingNote && currentStep === 1 && (
                <NoteCreationStep1
                    setCurrentStep={setCurrentStep}
                    setIsCreatingNote={setIsCreatingNote}
                    onNextStep={handleNextStep}
                    useEmailCreator={emailCreator}
                    EmailArtist={EmailArtist}
                    initialMessage={message}
                    updateMessage={setMessage}
                    isAnonymous={isAnonymous}
                    setIsAnonymous={setIsAnonymous}
                    onCharacterCountChange={handleCharacterCountChange}
                    roomAdminName={roomAdminName}
                    closeButton={handleUnsavedChanges}
                />
            )}

            {isCreatingNote && currentStep === 2 && (
                <NoteCreationStep2
                    message={message}
                    selectedColor={selectedColor}
                    selectedSticker={selectedSticker}
                    selectedFont={selectedFont}
                    onSubmitNote={onSubmitNote}
                    // setSuccessNote={setSuccessNote}
                    setCurrentStep={setCurrentStep}
                    setIsCreatingNote={setIsCreatingNote}
                    useEmailCreator={emailCreator}
                    isAnonymous={isAnonymous}
                    roomId={roomSettings?.roomId}
                    isProUser={isProUser}
                    displayName={user?.user || "username"}
                />
            )}
            {exitIntraModal && (
                <ExitIntraverseModal
                    onClose={() => {
                        setExitIntraModal(false);
                    }}
                    roomId={roomSettings?.roomId}
                />
            )}
            {openUnsavedModal && (
                <UnsavedModal
                    onClose={() => {
                        setOpenUnsavedModal(false);
                        setCurrentStep(0);
                        setIsCreatingNote(false);
                        setMessage("");
                    }}
                    onCloseUnsavedModal={() => {
                        setOpenUnsavedModal(false);
                    }}
                />
            )}
        </div>
    );
};

export default Guestbook;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20rem;
`;

const GuestbookOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black layer */
    z-index: 999; /* Below modal but above content */
    display: ${({ show }) => (show ? "block" : "none")};
`;

const GuestbookContainer = styled.div`
    position: relative;
    padding: 20px;
    background: linear-gradient(73deg, #11113f 1.32%, #210d35 96.92%);
    height: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.85);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(190, 174, 255, 0.5);
        border-radius: 6px;
    }
`;

const Banner = styled.div`
    width: 100%;
    height: fit-content;
    background: url(${NotesBackground}), linear-gradient(73deg, #210d35 1.32%, #210d35 96.92%);
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 0rem 0rem 0rem 2rem;
`;

const Button = styled.button`
    padding: 1rem 2rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: white;
    border-left: 4px solid rgba(190, 174, 255, 0.2);

    @media (max-width: 700px) {
        padding: 0.5rem 1rem;
    }
`;
const StyledImage = styled.img`
    @media (max-width: 960px) {
        width: 30px;
        height: auto;
    }
`;

const StickyNote = styled.div`
    background-color: ${(props) => props.color || "#FFF3B1"};
    font-family: ${setFont.barlow};
    width: 100%;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    margin-bottom: 1.5rem;
`;

const NotesBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StickyNoteGrid = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 80%;
    /* z-index: 999; */
    ${(props) =>
        props.empty
            ? `width: 100%; position:fixed; top: 30%; left: auto;`
            : `display: grid; grid-template-columns: repeat(3, 1fr);
    gap: 40px;`}
    padding: 40px;

    @media (min-width: 577px) and (max-width: 790px) {
        max-width: 100%;
        ${(props) =>
            props.empty
                ? `width: 100%; position:fixed; top: 30%; left: auto;`
                : `display: grid; grid-template-columns: repeat(2, 1fr);
    gap: 40px;`}
    }

    @media (max-width: 576px) {
        max-width: 100%;
        ${(props) =>
            props.empty
                ? `width: 100%; position:fixed; top: 30%; left: auto;`
                : `display: grid; grid-template-columns: repeat(1, 1fr);
    gap: 40px;`}
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3rem;
    height: 100%;
`;

const PlusButton = styled.div`
    position: fixed;
    bottom: 30px;
    right: 50px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
`;
const UserNameAndPin = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ThumbsWrapper = styled.div`
    display: flex;
    justify-content: left;
    margin-top: 10px;
    padding: 0 15px;
    gap: 10px;
`;

const Thumb = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #333;
    cursor: pointer;

    border-radius: 100px;
    width: fit-content;
    padding: 10px 20px;
`;

const ThumbIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`;

const AllButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 40px;
    margin-right: 5px;
    border-radius: 52px;
    border: 2px solid ${({ isActive }) => (isActive ? "#beaeff" : "#2c2251")};
    background: ${({ isActive }) => (isActive ? "rgba(37, 24, 89, 0.5)" : "#2c2251")};
    color: ${({ isActive }) => (isActive ? "#beaeff" : "#fbfcff")};
    font-family: ${setFont.barlow};
    font-weight: 700;
    cursor: pointer;
    z-index: 5;
`;
const TopRated = styled.div`
    border-radius: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    margin-right: 5px;
    border: 2px solid ${({ isActive }) => (isActive ? "#beaeff" : "#2c2251")};
    background: ${({ isActive }) => (isActive ? "rgba(37, 24, 89, 0.5)" : "#2c2251")};
    color: ${({ isActive }) => (isActive ? "#beaeff" : "#fbfcff")};
    font-family: ${setFont.barlow};
    font-weight: 500;
    cursor: pointer;
    z-index: 5;
`;
const Gradient = styled.div`
    position: absolute;
    width: 100vw;
    height: 100px;
    top: 70px;
    left: 0;
    z-index: 1;
    background: linear-gradient(rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.2;
    @media (max-width: 700px) {
        top: 50px;
    }
`;
const OwnerBanner = styled.div`
    border-radius: 4px;
    border: 2px solid #3a2e5b;
    background: #23173e;
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    top: 60px;
    left: 2rem;
    width: 80%;
    height: 3.7rem;
    z-index: 1000;

    & p {
        color: white;
        font-size: 20px;
        font-family: ${setFont.barlow};
        padding: 1rem;
    }
    & span {
        color: #ffe664;
        font-weight: 700;
    }

    @media (max-width: 820px) {
        width: 100%;
        top: 57px;
        left: 0rem;
        padding: 0;

        & p {
            font-size: 12px;
        }
    }
`;

const BottomFold = styled.img`
    width: 100%;
    height: auto;
    margin-bottom: -44px;

    @media (min-width: 768px) and (max-width: 1175px) {
        margin-bottom: -28px;
    }

    @media (min-width: 577px) and (max-width: 768px) {
        margin-bottom: -35px;
    }

    @media (min-width: 360px) and (max-width: 576px) {
        margin-bottom: -45px;
    }

    @media (max-width: 360px) {
        margin-bottom: -34px;
    }

    @media (min-width: 1175px) {
        margin-bottom: -54px;
    }
`;
const Layer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Ensure it appears below the content but above the page */
    pointer-events: none; /* Make it non-interactive */
    display: ${({ show }) => (show ? "block" : "none")};
`;
const Layer1 = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 2000; /* Below the banners but above the main content */
    pointer-events: none; /* Allow interactions with the banners */
    display: ${({ show }) => (show ? "block" : "none")};
`;
