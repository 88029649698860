import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import DataTable from "../../../../../components/common/dataTable/DataTable";
import MerchantsDetails from "./MerchantsDetails";
import { ReactComponent as CheckmarkIcon } from "../../../../../assets/images/icons/Checkmark.svg";
import { ReactComponent as Cross } from "../../../../../assets/images/icons/Cross.svg";
import { ReactComponent as NullIcon } from "../../../../../assets/images/icons/System.svg";
import { setColor, setFont } from "../../../../../assets/styles/styles";

const MerchantsTable = ({ merchantsData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const ROWS_PER_PAGE = 5;
    const totalPages = Math.ceil(merchantsData.length / ROWS_PER_PAGE);
    const visibleRows = merchantsData.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE);

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
        navigate(`/console/merchants/${row.merchantId}`);
    };

    const renderRow = (merchant) => {
        let featureComponent;

        if (merchant.status === "active") {
            featureComponent = <StyledCheckmark />;
        } else if (merchant.status === "inactive") {
            featureComponent = <StyledCloseIcon />;
        } else {
            featureComponent = <StyledNullIcon />;
        }
        if (merchant.merchantAddress === "") {
            merchant.merchantAddress = "Not Available";
        }
        if (
            merchant.stripeClientId === "" ||
            merchant.stripeClientId === null ||
            merchant.stripeClientId === undefined
        ) {
            merchant.stripeClientId = "Not Available";
        }

        return [
            merchant.merchantId,
            merchant.businessType,
            merchant.merchantName,
            merchant.merchantEmail,
            merchant.merchantAddress,
            merchant.stripeClientId,
            merchant.createdDateTime,
            <>{featureComponent}</>,
        ];
    };

    return (
        <>
            <DataTable
                headers={["MERCHANT ID", "TYPE", "NAME", "EMAIL", "ADDRESS", "STRIPE CONNECT", "CREATED AT", "STATUS"]}
                data={visibleRows}
                renderRow={renderRow}
                openModal={openModal}
                isModalOpen={isModalOpen}
                selectedRow={selectedRow}
                detailsComponent={MerchantsDetails}
            />
            <Footer>
                <TableColumnLeft>Total Number of Merchants: {merchantsData.length}</TableColumnLeft>
                <TableColumnRight>
                    {visibleRows.length !== 0 && (
                        <Pagination>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <PaginationButton
                                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </PaginationButton>
                            <PaginationButton
                                onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </PaginationButton>
                        </Pagination>
                    )}
                </TableColumnRight>
            </Footer>
        </>
    );
};

export default MerchantsTable;

const StyledCheckmark = styled(CheckmarkIcon)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
    #Union {
        fill: ${setColor.successColor};
    }
`;

const StyledCloseIcon = styled(Cross)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
    #Union {
        fill: ${setColor.spanTextColor};
    }
`;

const StyledNullIcon = styled(NullIcon)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
`;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText};
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    // font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;
    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled};
    }
`;
