import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import moment from "moment";
import { setColor } from "../../../../../assets/styles/styles";
import Title from "../../../components/Title";
import Body from "../../../layout/Body";
import { DEFAULT_QUERY_OPTIONS } from "../../../../../constants";
import Typography from "../../../../../components/common/text/Typography";
import { default as Button } from "../../../../../components/common/button/Button";
import { ReactComponent as ChevronIcon } from "../../../../../assets/images/icons/Chevron.svg";
import { getRoomUsers, getRoomById } from "../api/roomController";
import DataTable from "../../../../../components/common/dataTable/DataTable";
import Modal from "../../../components/Modal";
import DeleteModal from "./DeleteModal";
import AddRoomUsers from "./AddUpdateRoomUsers";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/icons/deleteIcon.svg";
import Pagination from "../../../../../components/common/pagination/Pagination";

const RoomUsersPage = () => {
    const { id } = useParams();
    const ROWS_PER_PAGE = 5;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [openAddUserToRoomModal, setOpenAddUserToRoomModal] = useState(false);
    const [visibleUsers, setVisibleUsers] = useState([]);
    const [isRoomUsersDeleteModal, setIsRoomUsersDeleteModal] = useState(false);
    const [selectedRoomUser, setSelectedRoomUser] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const {
        data: roomInfo,
        error: roomByIdError,
        isLoading: roomByIdLoading,
        refetch,
    } = useQuery(["roomInfo", id], () => getRoomById(id), {
        enabled: !!id,
        ...DEFAULT_QUERY_OPTIONS(),
    });

    const {
        data: roomUsers,
        error: roomUsersError,
        isLoading: roomUsersLoading,
        refetch: refetchRoomUsers,
    } = useQuery(["allRoomUsers", id], () => getRoomUsers(id), {
        enabled: !!id,
        ...DEFAULT_QUERY_OPTIONS(),
    });

    const handleBack = () => {
        navigate(`/console/rooms/${id}`);
    };

    const addUserToRoomClick = async () => {
        setOpenAddUserToRoomModal(true);
        refetchRoomUsers();
    };

    const openRoomUsersDeleteModal = (roomUser) => {
        setIsRoomUsersDeleteModal(true);
        setSelectedRoomUser(roomUser);
    };

    const handleDeleteClick = (roomUser) => {
        openRoomUsersDeleteModal(roomUser);
    };

    const renderRow = (roomUser) => {
        let name = "Not Available";
        if (roomUser.firstName !== null && roomUser.lastName !== null) {
            name = `${roomUser.firstName} ${roomUser.lastName}`;
        }

        const buttonDel = <StyledDeleteIcon onClick={() => handleDeleteClick(roomUser)} />;

        if (roomUser.roleName !== "admin" && roomUser.roleName !== "superadmin") {
            return [roomUser.userId, name, roomUser.email, roomUser.roleName, <>{buttonDel}</>];
        }

        return [roomUser.userId, name, roomUser.displayName, roomUser.email, roomUser.roleName, <></>];
    };

    useEffect(() => {
        if (roomUsers) {
            setTotalPages(Math.ceil(roomUsers.length / ROWS_PER_PAGE));
            setVisibleUsers(roomUsers.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE));
            setIsLoading(false);
        }
    }, [roomUsers, currentPage]);

    return (
        <>
            <Title
                title={"ROOM USERS"}
                description={
                    "Manage all rooms relations in Intraverse. Changes made here are automatically pushed to Production."
                }
            />

            <UserConsoleText style={{ marginBottom: "1rem" }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>

            <Body isLoading={isLoading}>
                <>
                    <CardTable>
                        <TableRow>
                            <TableColumn>
                                <Typography variant="PinkText" margin={"5px"}>
                                    {roomInfo?.name ? `${roomInfo?.name}: USERS` : ""}
                                </Typography>
                            </TableColumn>

                            <TableColumnButton>
                                <Button
                                    variant={"SmallButton"}
                                    label="ADD USER TO ROOM"
                                    buttonWidth="auto"
                                    margin="0rem 1rem 0rem 0rem"
                                    onClick={addUserToRoomClick}
                                    border={`${setColor.accent4}`}
                                />
                            </TableColumnButton>
                        </TableRow>
                    </CardTable>

                    {roomUsers && (
                        <>
                            <DataTable
                                headers={["USER ID", "FULL NAME", "DISPLAY NAME", "EMAIL", "ROLE"]}
                                data={visibleUsers}
                                renderRow={renderRow}
                                isHover={false}
                            />
                            <Pagination
                                totalNumber={roomUsers.length}
                                visibleRows={visibleUsers}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                tag={"Room Users"}
                            />

                            {isRoomUsersDeleteModal && (
                                <Modal
                                    isOpen={isRoomUsersDeleteModal}
                                    setIsOpen={setIsRoomUsersDeleteModal}
                                    children={
                                        <DeleteModal
                                            id={id}
                                            requestType={"roomUsers"}
                                            selectedRoomUser={selectedRoomUser}
                                            closeModal={() => setIsRoomUsersDeleteModal(false)}
                                        />
                                    }
                                />
                            )}
                        </>
                    )}

                    {openAddUserToRoomModal && (
                        <Modal
                            isOpen={openAddUserToRoomModal}
                            setIsOpen={setOpenAddUserToRoomModal}
                            children={
                                <AddRoomUsers
                                    roomId={id}
                                    roomUsers={roomUsers}
                                    closeModal={() => setOpenAddUserToRoomModal(false)}
                                />
                            }
                        />
                    )}
                </>
            </Body>
        </>
    );
};

export default RoomUsersPage;

const UserConsoleText = styled.h1`
    font-family: "Barlow Condensed", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;
