import React from "react";
import styled from "styled-components";
import TopStars from "./images/TopStars.png";
import ModalCloseIcon from "./images/ModalCloseIcon.png";
import Button from "../../components/common/button/Button";
import Typography from "../../components/common/text/Typography";
import { setColor } from "../../assets/styles/styles";

const ExitIntraverseModal = ({ onClose, roomId }) => {
    const redirectToConsole = () => {
        console.log(roomId);
        const url = `/console/rooms/${roomId}/guest-book`;
        window.open(url, "_blank");
    };
    return (
        <>
            <Overlay onClick={onClose} />
            <ModalContainer>
                <ModalCloseIconBox onClick={onClose} src={ModalCloseIcon} alt="ModalCloseIcon" />
                <ModalContent>
                    <Typography
                        variant="guestbookP"
                        fontFamily="Signika Negative"
                        align="center"
                        FontWeight="700"
                        fontSize={"38px"}
                        color="#E4DEFF"
                    >
                        Exit Intraverse?
                    </Typography>
                    <p style={{ fontSize: "18px", color: "#CED1D9" }}>
                        Reviewing your notes will take you <YellowText> out </YellowText>of the Intraverse to your Web
                        Console. Are you sure you want to continue?
                    </p>

                    <ButtonContainer>
                        <Button
                            label="Never Mind"
                            borderRadius="0px"
                            backgroundColor="rgba(255, 255, 255, 0.05)"
                            variant="OutlinedButton"
                            onClick={onClose}
                            margin="0rem 1rem 0 0"
                            buttonWidth="40%"
                            lowercase
                            fontSize="20px"
                            padding="10px 20px"
                            border=" #E4DEFF"
                        />
                        <Button
                            label="Visit My Console"
                            borderRadius="0px"
                            backgroundColor="rgba(36.53, 23.80, 89.25, 0.50)"
                            variant="OutlinedButton"
                            onClick={redirectToConsole}
                            margin="0rem 1rem 0 0"
                            buttonWidth="40%"
                            lowercase
                            fontSize="20px"
                            padding="10px 20px"
                            border=" #BEAEFF"
                        />
                    </ButtonContainer>
                </ModalContent>
            </ModalContainer>
        </>
    );
};

export default ExitIntraverseModal;

const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: fit-content;
    background-color: #0e0517;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${TopStars});
    background-size: 100% 50%;
    background-position: top center;
    background-repeat: no-repeat;
    border: 4px solid #3d364f;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 2rem;
    z-index: 1000;

    @media (max-width: 768px) {
        width: 80%;
        padding: 0rem;
        font-size: 12px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const ModalContent = styled.div`
    color: white;
    border-radius: 10px;
    text-align: center;
    padding: 2rem;

    @media (max-width: 700px) {
        width: auto;
    }
`;
const ModalCloseIconBox = styled.img`
    position: absolute;
    top: -30px;
    right: -45px;
    height: 100px;
    width: 90px;
    cursor: pointer;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const YellowText = styled.span`
    color: ${setColor.yellowText};
    font-weight: 700;
`;
