import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import OrderDetailsModal from "./OrderDetailsModal";
import { ReactComponent as CheckMarkIcon } from "../../../../../assets/images/icons/checkmark-icon.svg";
import { ReactComponent as WarningIcon } from "../../../../../assets/images/icons/WarningRed.svg";
import Modal from "../../../components/Modal";
import { capitalizeFirstLetter } from "../../../../../utils/generalUtils";
import DataTable from "../../../../../components/common/dataTable/DataTable";
import { setColor, setFont } from "../../../../../assets/styles/styles";

const OrderHistoryTable = ({ ordersData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const ROWS_PER_PAGE = 5;

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };
    const totalPages = Math.ceil(ordersData.length / ROWS_PER_PAGE);
    const visibleRows = ordersData.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE);

    const renderRow = (order) => {
        let statusComponent;
        const statusText = order.status;

        if (order.status === "succeeded") {
            statusComponent = <StyledCheckMarkIcon />;
        } else {
            statusComponent = <StyledWarningIcon />;
        }

        return [
            order.orderId,
            order.item,
            order.vendor,
            order.displayOrderDate,
            order.totalAmount,
            <>
                {statusComponent}
                {capitalizeFirstLetter(statusText)}
            </>,
        ];
    };
    return (
        <TableContainer>
            <DataTable
                headers={["ORDER ID", "ITEM", "VENDOR", "ORDERED ON", "TOTAL AMOUNT", "STATUS"]}
                data={visibleRows}
                renderRow={renderRow}
                openModal={openModal}
                isModalOpen={isModalOpen}
                selectedRow={selectedRow}
                detailsComponent={OrderDetailsModal}
            />

            {visibleRows.length !== 0 && (
                <Pagination>
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                    <PaginationButton
                        onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Prev
                    </PaginationButton>
                    <PaginationButton
                        onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </PaginationButton>
                </Pagination>
            )}
            <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} children={<OrderDetailsModal row={selectedRow} />} />
        </TableContainer>
    );
};

export default OrderHistoryTable;

OrderHistoryTable.propTypes = {
    ordersData: PropTypes.arrayOf(
        PropTypes.shape({
            length: PropTypes.number,
            slice: PropTypes.func,
        })
    ).isRequired,
};

const TableContainer = styled.div`
    width: 100%;
    @media (max-width: 768px) {
        overflow-x: scroll;
    }
`;

const StyledCheckMarkIcon = styled(CheckMarkIcon)`
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-bottom: -2px;
`;

const StyledWarningIcon = styled(WarningIcon)`
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-bottom: -2px;
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText};

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled};
    }
`;
