import React from "react";
import styled from "styled-components";
import { setColor } from "../../../assets/styles/styles";

const ToggleSwitch = ({ value, onChange }) => (
    <SwitchContainer>
        <HiddenCheckbox checked={value} onChange={() => onChange(!value)} />
        <Slider isChecked={value} />
    </SwitchContainer>
);

export default ToggleSwitch;

const SwitchContainer = styled.label`
    position: relative;
    display: inline-block;
    width: 55px;
    height: 25px;
    cursor: pointer;
`;

const Slider = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => (props.isChecked ? setColor.aquaGreen : setColor.redLightWarning)};
    transition: background-color 0.4s ease;
    border-radius: 85px;

    &::before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: transform 0.4s ease;
        border-radius: 50%;
        transform: ${(props) => (props.isChecked ? "translateX(26px)" : "translateX(0)")};
    }
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    opacity: 0;
    width: 0;
    height: 0;
`;
